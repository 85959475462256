<template>
        <section>
            <div class="row portfolio-container" style="padding-bottom: 0 !important;">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="logo-flex">
                        <img src="/images/bebambi/logo.svg" alt="">
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <h1 class="main-heading">
                        from conception to chilldhood,
                        bebambi makes parenting easier.
                    </h1>
                    <div class="row" v-if="!isMobile">
                        <div class="col-sm-12 col-md-12 col-lg-9">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3">
                            <div class="main-img arrow">
                                <img src="/images/bebambi/arrow.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="main-flex">
                        <div v-for="(item, index) in mainHeading" :key="index">
                            <h4>{{ item.title }}</h4>
                            <span>{{ item.description }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="main-img">
                        <img src="/images/bebambi/main-img.png" alt="">
                    </div>
                </div>
           
            <div class="col-sm-12 col-md-12 col-lg-2"></div>
            <div class="col-sm-12 col-md-12 col-lg-12">
                <h2 class="sub-heading">features & functionalities</h2>
                <div class="feature-flex">
                    <div class="data-flex" 
                    v-for="(feature, index) in features" :key="index">
                            <img :src="feature.url" alt="">
                            <span>{{ feature.description }}</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-2"></div>
            <div class="col-sm-12 col-md-12 col-lg-12">
                <h2 class="sub-heading">major screens</h2>
                <div class="screen-img-grid">
                    <div class="data-flex" 
                    v-for="(screen, index) in screens" :key="index">
                    <div class="main-img">
                        <img :src="screen.url" alt="">
                    </div>
                    <span>{{ screen.description }}</span>
                </div>
                </div>
            </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12">
                <h2 class="sub-heading">other screens</h2>
            </div>
            <div class="main-img ">
            <img src="/images/bebambi/full-img.png" alt="" v-if="!isMobile">
            <img src="/images/bebambi/full-img-mobile.png" alt="" v-if="isMobile">
            </div>
            <div class="row portfolio-container">
                <h2 class="sub-heading">
                    website landing page
                </h2>
                <div class="main-img">
                    <img src="/images/bebambi/landing-page.png" 
                    alt="" v-if="!isMobile">
                    <img src="/images/bebambi/landing-page-mobile.png" 
                    alt="" v-if="!isMobile">
                </div>
            
            <div class="col-sm-12 col-md-12 col-lg-12">
                <h2 class="sub-heading">design process</h2>
                <div class="feature-flex">
                    <div class="data-flex" 
                    v-for="(design, index) in designs" :key="index">
                            <img :src="design.url" alt="">
                            <span>{{ design.description }}</span>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-12 col-md-12 col-lg-12 custom-border">
                <h2 class="sub-heading">problem statement</h2>
                <p class="paragraph">
                    Modern parents do not have enough time to look after their baby care products. 
                    They worry about their baby’s health and are frustrated about 
                    going from store to store and waiting in the queue. It bothers them a lot.
                </p>
            <span class="b-left"></span>
            <span class="b-right"></span>
            <span class="b-b-left"></span>
            <span class="b-b-right"></span>
            </div>
            
            <div class="col-sm-12 col-md-3 col-lg-3 " v-if="!isMobile">
                    <div class="main-img custom-setting">
                        <img src="/images/bebambi/objective-img.png" alt="">
                    </div>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3"></div>
            <div class="col-sm-12 col-md-6 col-lg-6" style="margin-bottom: 3em;">
                <h2 class="sub-heading text-left">
                    objectives & goals
                </h2>
                <ul>
                    <li class="paragraph text-left">
                        Our goals extend beyond quick commerce; we are adding value to parents' 
                        lives by acting as a dedicated partner in parenting and building an 
                        ecosystem that encompasses developmental charts, a 
                        comprehensive name database, vital health indicators, 
                        and enriching informational blogs. This ecosystem is 
                        designed to support not only the physical health of 
                        children but also their overall well-being. 
                    </li>
                    <li class="paragraph text-left">
                        We understand that parenting is a journey filled with joy, 
                        love, and challenges, and we are committed to providing 
                        support whether you are expecting parents, need help 
                        regarding the developmental guide, or vaccination 
                        schedule, or are facing the problem of a baby essentials shortage.
                    </li>

                </ul>
            </div> 
            </div>
            <div class="background">
                <div class="row portfolio-container" 
                style="padding-bottom: 0 !important; padding-top: 0;">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                <h2 class="sub-heading text-left">
                    user needs
                </h2>
                <ul>
                    <li class="paragraph text-left">
                        User feels that it would be better to have all 
                        brand onboarded on a same platform with different 
                        kinds of products.
                    </li>
                    <li class="paragraph text-left">
                        User needs real time tracking of their order so that 
                        they can keep eye on their after placing an order.
                    </li>
                    <li class="paragraph text-left">
                        User needs trusted and working online payment method so that 
                        they can save their time and the frustration of cash change;.
                    </li>
                </ul>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3"></div>
            <div class="col-sm-12 col-md-3 col-lg-3 mobile" v-if="!isMobile">
                    <div class="main-img">
                        <img src="/images/bebambi/user-need-img.png" alt="">
                    </div>
            </div>
                </div>
            </div>
        </section>
</template>

<script>
export default {
    data() {
        return {
            mainHeading: [
                {
                    title: 'platform',
                    description: 'Mobile Application'
                },
                {
                    title: 'deliverables',
                    description: 'Research, UI/UX, Design System, Development'
                },
            ],
            features: [
                {
                    url: '/images/bebambi/feature-1.svg',
                    description: 'Top brands with different products'
                },
                {
                    url: '/images/bebambi/feature-2.svg',
                    description: 'Two kinds of Delivery service'
                },
                {
                    url: '/images/bebambi/feature-3.svg',
                    description: 'Multiple payment methods'
                },
            ],
            screens: [
                {
                    url: '/images/bebambi/screen-1.png',
                    description: 'Splash'
                },
                {
                    url: '/images/bebambi/screen-2.png',
                    description: 'Home'
                },
                {
                    url: '/images/bebambi/screen-3.png',
                    description: 'Product'
                },
                {
                    url: '/images/bebambi/screen-4.png',
                    description: 'Location'
                },
            ],
            designs: [
                {
                    url: '/images/bebambi/design-1.svg',
                    description: 'Understand'
                },
                {
                    url: '/images/bebambi/design-2.svg',
                    description: 'Define'
                },
                {
                    url: '/images/bebambi/design-3.svg',
                    description: 'Ideate'
                },
                {
                    url: '/images/bebambi/design-4.svg',
                    description: 'Prototype'
                },
                {
                    url: '/images/bebambi/design-5.svg',
                    description: 'Test'
                },
                
            ]
        }
    },

    computed: {
        isMobile() {
            return window.matchMedia("('max-width: 480px')").matches
        }
    }

}
</script>

<style  scoped>
section {
    background: linear-gradient(180deg, #000000 -303.13%, rgba(138, 111, 162, 0.43) -88.8%, #020103 96.38%);

}
.background{ 
    background-color: #8A6FA2;
    margin: 0;
}
ul {
    padding-left: 1em;
}
ul li {
    margin-bottom: 1em;
}
.sub-heading.text-left{
    text-align: left;
}
.main-img.custom-setting{
    margin-top: 7em;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.custom-border{
    position: relative;
    margin: 2em auto;
    padding: 0 2em 2.5em;
    border: 2px solid #8A6FA2;
}
.custom-border .b-left {
    position: absolute;
    width: 2em;
    height: 3em;
    background-color: #fff;
    top: -1.5em;
    left: -1em;
}
.custom-border .b-right {
    position: absolute;
    width: 2em;
    height: 3em;
    background-color: #fff;
    top: -1.5em;
    right: -1em;
}
.custom-border .b-b-right {
    position: absolute;
    width: 2em;
    height: 3em;
    background-color: #fff;
    bottom: -1.5em;
    right: -1em;
}
.custom-border .b-b-left {
    position: absolute;
    width: 2em;
    height: 3em;
    background-color: #fff;
    bottom: -1.5em;
    left: -1em;
}
 .screen-img-grid{
    margin: 5em 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1em;
}
.feature-flex{
    display: flex;
    width: 80%;
    margin: 0 auto 1em;
    justify-content: space-between;
    align-items: center;
}
 .data-flex{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.data-flex img{
    margin-bottom: 2em;
    width: 10em;
    height: auto;
}
.data-flex span {
    font-size: 1.5em;
    font-weight: 500;
    color: #fff;
    text-align: center;
}

.logo-flex {
    display: flex;
    justify-content: space-between;
    width: 15%;
    align-items: center;
    margin-top: 2em;
}

.logo {
    font-size: 2em;
    font-weight: 700;
    color: #fff;
    margin-top: 2em;
    padding-left: 1em;
    margin-bottom: 1em;
    text-transform: capitalize;

}

.main-img {
    width: 100%;
}

.main-img img {
    width: 100%;
    height: auto;
}

.ui-img {
    width: 60%;
    margin: 2em auto;
}

.main-heading {
    font-size: 3.5em;
    font-weight: 800;
    letter-spacing: 0.3px;
    text-transform: capitalize;
    margin-bottom: 0;
}

.main-flex {
    margin-top: .5em;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.main-flex h4 {
    font-size: 1.75em;
    font-weight: 600;
    text-transform: capitalize;
}

.main-flex span {
    color: #fff !important;
    font-size: 1em;
    font-weight: 400;

}

.sub-heading {
    font-size: 2.5em;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    margin: 1.5em 0 1em 0;
}

.paragraph {
    font-size: 1.25em;
    font-weight: 400;
    letter-spacing: 0.3px;
    text-align: center;
    color: #fff;
}
.paragraph.text-left, .sub-heading.text-letf { text-align: left;}

@media (min-width:1440px) {
    .data-flex img{
    margin-bottom: 3em;
}
.data-flex span {
    font-size: 1.55em;
    font-weight: 500;
    color: #fff;
}
    .paragraph {
        font-size: 1.5em;
    }

    .sub-heading {
        font-size: 3em;
    }

    .main-heading {
        font-size: 5em;
        font-weight: 900;
    }

    .main-flex h4 {
        font-size: 2em;
        font-weight: 600;
        text-transform: capitalize;
    }

    .main-flex span {
        color: #fff !important;
        font-size: 1.5em;
        font-weight: 400;

    }
}

@media (max-width:768px) {
    .data-flex img{
    margin-bottom: 1.5em;
}
.data-flex span {
    font-size: 1em;
    font-weight: 400;
    color: #fff;
}
    .paragraph {
        font-size: .75em;
        text-align: center;
        line-height: 1.5em;
    }

    .ui-img {
        width: 80%;
        margin: 1em auto;
    }

    .sub-heading {
        font-size: 2em;
    }
    .feature-flex { width: 90%;}

    .main-heading {
        font-size: 3.5em;
        margin-bottom: 1em;
    }

    .main-flex {
        margin-bottom: 5em;
    }
    .data-flex img {
        width: 3em;
    }

    .main-flex h4 {
        font-size: 1.5em;
        font-weight: 500;
        text-transform: capitalize;
    }

    .main-flex span {
        color: #fff !important;
        font-size: 1em;
        font-weight: 400;

    }
}

@media (max-width:480px) {
    .ui-img {
        width: 100%;
        margin: 1em auto;
    }
    .logo-flex{
        width: 60%;
        margin-top: 4em;
    }
    .logo-flex img{
        width: 100%;
    }
    .paragraph {
        font-size: 1em;
        line-height: 1.5em;
        text-align: justify;
        padding: 0 !important;
    }

    .sub-heading {
        font-size: 1.5em;
    }
    .feature-flex { width: 100%;}

    .main-heading {
        font-size: 2.15em;
        line-height: 1.25em;
        letter-spacing: normal;
        font-weight: 900;
        margin-bottom: .5em;
    }

    .main-flex h4 {
        font-size: 1em;
        font-weight: 600;
        line-height: 0.5em;
        margin-bottom: 0;
        text-transform: capitalize;
    }

    .main-flex {
        margin-bottom: 2em;
    }

    .main-flex span {
        color: #fff !important;
        font-size: .65em;
        font-weight: 400;

    }
    .main-img.user, .main-img.arrow, .main-img.custom-setting{
        display: none;
    }
    .background{
        padding: 0 1em;
    }
    .data-flex img{
        width: 45px !important;
        height: auto;
    }
    .data-flex span{
        font-size: 10px;
        text-align: center;
    }
    .screen-img-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: .5em;
    }
    .screen-img-grid .data-flex {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .screen-img-grid .data-flex .main-img {
        width: 100%;
        height: auto;
    }
    .screen-img-grid .data-flex .main-img img{
       object-fit: contain;
       width: 100% !important;
    }
    .screen-img-grid .data-flex .span{
        font-size: 1em;
    }
    .custom-border .b-left {
    position: absolute;
    width: 10px;
    height: 12px;
    background-color: #fff;
    top: -6px;
    left: -5px;
}
.custom-border .b-right {
    position: absolute;
    width: 10px;
    height: 12px;
    background-color: #fff;
    top: -6px;
    right: -5px;
}
.custom-border .b-b-right {
    position: absolute;
    width: 10px;
    height: 12px;
    background-color: #fff;
    bottom: -6px;
    right: -5px;
}
.custom-border .b-b-left {
    position: absolute;
    width: 10px;
    height: 12px;
    background-color: #fff;
    bottom: -6px;
    left: -5px;
}
    .mobile { display: none;}
    ul li {
        text-align: center;
    }
    ul{ padding: 0.5em !important;}
}</style>