<template>
  <div>
    <AiCommonBanner
      styleHeading="Smart solution for a"
      heading="smarter tomorrow"
      paragraph="AI Solutions to Empower Your Business Potential."
    />
    <CommonClients :images="images" />

    <div class="ai-container">
      <h2
        class="common-banner-heading text-center"
        style="font-weight: bold !important; margin-bottom: 20px"
      >
        Empowering your business <br />
        with Advance AI
      </h2>

      <div class="custom-container">
        <div class="custom-col" v-for="(img, index) in aiProducts" :key="index">
          <div v-if="!img.isDescShow">
            <div class="custom-icon">
              <img
                :src="`/assets/img/ai/${img.icon}.svg`"
                alt="Product images"
              />
            </div>
            <h2 class="text-align">{{ img.text }}</h2>
            <i
              class="fa fa-arrow-right fix-icon"
              @click="img.isDescShow = true"
            ></i>
          </div>
          <div v-else>
            <div>
              <p class="p-desc">{{ img.desc }}</p>
              <i
                class="fa fa-arrow-left fix-icon"
                @click="img.isDescShow = false"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AiClients :aiImages="AiImages" />
    <div class="ai-container">
      <h2
        class="common-banner-heading text-center"
        style="font-weight: bold; margin-bottom: 50px !important"
      >
        Our AI integrations
      </h2>
      <div class="audio-to-text-container">
        <h2
          class="text-align"
          style="
            text-align: left !important;
            font-weight: 800;
            width: 100%;
            font-size: 40px;
          "
        >
          Audio to Text
        </h2>
        <img
          src="/assets/img/ai/audio-to-text.png"
          class=""
          alt="Audio To Text"
        />
      </div>
    </div>
    <div class="ai-container">
      <div class="ai-bot-grid">
        <div
          class="ai-bot-card"
          v-for="(item, index) in audioToText"
          :key="index"
        >
          <h2 class="align-item">{{ item.title }}</h2>
          <img :src="`/assets/img/ai/${item.image}`" alt="image" width="100%" />
        </div>
      </div>
    </div>

    <div class="col-container">
      <div class="col" v-for="(item, index) in aiIntegration" :key="index">
        <div class="icon">
          <img :src="`/assets/img/ai/${item.icon}`" alt="Integration image" />
        </div>
        <h2 class="align-item">
          {{ item.title }}
        </h2>
        <p class="p-desc">
          {{ item.desc }}
        </p>
      </div>
    </div>
    <h2
      class="common-banner-heading text-center"
      style="font-weight: bold; margin: 40px 0"
    >
      Portfolio
    </h2>
    <div class="ai-portfolio-container">
      <div class="ai-portfolio-grid" style="padding: 40px 0 !important">
        <div class="ai-portfolio-card-left">
          <div class="ai-portfolio-logo">
            <img src="/images/esgtree/esgtree-logo.png" alt="" width="100%" />
          </div>

          <h1 class="ai-portfolio-heading">
            Creating a <span style="color: #1ea969">Green</span> Scene
          </h1>
          <p class="custom-desc" style="text-align: left !important">
            ESGTree, is a cloud-based data collection platform for impact
            investors, venture capital firms, private equity firms, and other
            intermediaries seeking ESG (Environmental, Social, and Governance)
            monitoring services. As the technology partner, we created this
            salient software contributing towards the betterment of our world.
          </p>
          <button class="btn custom-btn" @click="navigateTo('/portfolio')">
            view more
          </button>
        </div>
        <div class="ai-portfolio-card-right">
          <img
            src="/assets/img/ai/esgtree-mokeup.png"
            alt="Moke up image"
            width="100%"
          />
        </div>
      </div>
    </div>
    <div class="ai-portfolio-container" style="background: black !important">
      <div class="ai-portfolio-grid" style="padding: 40px 0 !important">
        <div class="ai-portfolio-card-right">
          <img
            src="/assets/img/ai/capture-mokeup.png"
            alt="Moke up image"
            width="100%"
          />
        </div>
        <div class="ai-portfolio-card-left">
          <div class="ai-portfolio-logo">
            <img
              src="/assets/img/ai/capture-logo.png"
              alt=""
              width="100%"
              class="cap-img"
            />
          </div>

          <h1 class="ai-portfolio-heading">
            Artificial Intelligence in
            <span style="color: #008192">Healthcare</span>
          </h1>
          <p class="custom-desc" style="text-align: left !important">
            Upgrade your health system's offering with specific AI to detect and
            direct better patient care. Get complete patient visibility
            anywhere, anytime with the Smart Medical Camera™ billable technology
            in office appointments, PT appointments or for remote patient
            monitoring. This is the perfect telehealth companion. Sign up today
            and get paid for what you are already doing.
          </p>
          <button class="btn custom-btn" @click="navigateTo('/portfolio')">
            view more
          </button>
        </div>
      </div>
    </div>
    <div class="ai-portfolio-container">
      <div class="ai-portfolio-grid">
        <div class="ai-portfolio-card-left">
          <div class="ai-portfolio-logo">
            <img
              src="/assets/img/ai/listen-logo.png"
              alt=""
              width="auto"
              height="100%"
            />
          </div>

          <h1 class="ai-portfolio-heading" style="color: #9dbce8 !important">
            LLM + GPT Assistant
          </h1>
          <p class="custom-desc" style="text-align: left !important">
            Listen is an empathetic chatbot app designed to provide emotional
            support and practical assistance. Whether you're feeling down or
            need help with tasks like studying, Listen is here to understand and
            guide you, offering encouragement and resources tailored to your
            needs.
          </p>
          <button class="btn custom-btn" @click="navigateTo('/portfolio')">
            view more
          </button>
        </div>
        <div class="ai-portfolio-card-right">
          <img
            src="/assets/img/ai/listen-mokeup.png"
            alt="Moke up image"
            style="height: 360px; object-fit: contain"
            width="100%"
          />
        </div>
      </div>
    </div>
    <div class="ai-portfolio-container" style="background: black !important">
      <div class="ai-portfolio-grid" style="padding: 40px 0 !important">
        <div class="ai-portfolio-card-right">
          <img
            src="/assets/img/ai/smart-mokeup.png"
            alt="Moke up image"
            width="100%"
          />
        </div>
        <div class="ai-portfolio-card-left">
          <div class="ai-portfolio-logo">
            <img src="/assets/img/ai/smart-logo.png" alt="" width="100%" />
          </div>

          <h1 class="ai-portfolio-heading" style="color: #06bc6d !important">
            Ace your next , Leave Rejections behind
          </h1>
          <p class="custom-desc" style="text-align: left !important">
            We all know practice makes perfect, but here’s the secret: Smart
            practice gets you hired. The Smart Interview Coach identifies your
            strengths and weaknesses, creates a personalized action plan and
            then perfects delivery & performance through a tailored practice
            regime.
          </p>
          <button class="btn custom-btn" @click="navigateTo('/portfolio')">
            view more
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CommonBanner from "@/views/common/CommonBanner.vue";
import AiCommonBanner from "@/views/common/AiCommonBanner.vue";
import AiClients from "@/views/common/AiClients.vue";
import CommonClients from "@/views/common/CommonClients.vue";
export default {
  name: "ArtificialIntelligence",
  components: { AiClients, AiCommonBanner, CommonBanner, CommonClients },
  data() {
    return {
      AiImages: [
        "open-ai.svg",
        "bard.svg",
        "llma.svg",
        "chatgpt.svg",
        "open-ai.svg",
        "bard.svg",
        "llma.svg",
        "chatgpt.svg",
      ],
      images: [
        "smart-interview.png",
        "esgtree.png",
        "capture-proof.png",
        "listen.png",
        "smart-interview.png",
        "esgtree.png",
        "capture-proof.png",
        "listen.png",
      ],
      aiProducts: [
        {
          icon: "natural-language",
          text: "natural language processing (NLP)",
          desc:
            "Unlock the power of human language with NLP. " +
            "We train AI models to understand, interpret, " +
            "and generate human language. " +
            "This enables applications like chatbots, " +
            "sentiment analysis, and machine translation, " +
            "to transform how you interact with your customers and data.",

          isDescShow: false,
        },
        {
          icon: "computer-vision",
          text: "computer vision",
          desc:
            "See the world through the eyes of AI with computer vision. " +
            "We develop models that can extract meaningful information " +
            "from images and videos. This empowers tasks like object detection, " +
            "facial recognition, and image classification, leading to improved automation and data-driven insights.",

          isDescShow: false,
        },
        {
          icon: "deep-learning",
          text: "deep learning",
          desc:
            "Deep learning is a powerful subfield of machine " +
            "learning inspired by the human brain. " +
            "We leverage deep learning architectures " +
            "to tackle complex problems requiring high accuracy.",

          isDescShow: false,
        },
        {
          icon: "machine-learning",
          text: "machine learning (ML)",
          desc:
            "Machine learning allows computers to learn without explicit programming. " +
            "We build ML models that learn from data, identify patterns, " +
            "and make predictions. This unlocks a wide range of " +
            "applications, from product recommendations to fraud detection.",

          isDescShow: false,
        },
        {
          icon: "data-science",
          text: "data science",
          desc:
            "Data science is the art of extracting knowledge and insights from data." +
            "  Our data science teams clean, prepare, " +
            "and analyze data to fuel machine learning models and support informed decision-making.",

          isDescShow: false,
        },
        {
          icon: "large-language",
          text: "large language model (LLM)",
          desc:
            "LLMs are cutting-edge AI models trained on " +
            "massive amounts of text data. " +
            "We can help you harness the power of LLMs for tasks " +
            "like text generation, question answering, and code completion.",

          isDescShow: false,
        },
        {
          icon: "custom-models",
          text: "custom models",
          desc:
            "We don't believe in one-size-fits-all solutions. " +
            "Our team builds custom AI models tailored to " +
            "your specific needs and data, ensuring optimal performance for your unique challenges.",

          isDescShow: false,
        },
        {
          icon: "training-data",
          text: "training data management",
          desc:
            "High-quality training data is essential for effective AI models. " +
            "We provide training data management services to ensure your " +
            "data is clean, labeled accurately, and optimized for your chosen AI model.",

          isDescShow: false,
        },
      ],
      aiIntegration: [
        {
          icon: "ai-consulting.svg",
          title: "AI Consulting",
          desc:
            "Our AI consultants are experts in helping businesses leverage the power of AI. " +
            "We work with you to identify AI opportunities, develop strategies, and navigate the implementation process.",
        },
        {
          icon: "ai-product.svg",
          title: "AI Product Development",
          desc:
            "Looking to build an AI-powered product? We can help! From ideation to deployment, " +
            "our team guides you through every stage of the AI product development lifecycle..",
        },
        {
          icon: "ai-agile.svg",
          title: "AI Agile Teams",
          desc:
            "We believe in the power of agile methodologies for AI development. " +
            "Our dedicated AI Agile Teams foster collaboration and rapid iteration, " +
            "ensuring your project stays on track and delivers value quickly.",
        },
      ],
      audioToText: [
        {
          title: "Posture Detection",
          image: "posture-detection.png",
        },
        {
          title: "Face Mesh",
          image: "face-mesh.png",
        },
        {
          title: "Eye Contact",
          image: "eye-contact.png",
        },
        {
          title: "Chatbot",
          image: "chatbot.png",
        },
        {
          title: "Sentiment Analysis",
          image: "sentiment-analysis.png",
        },
        {
          title: "Smile Detection",
          image: "smile-detection.png",
        },
      ],
    };
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route).then(() => {
        window.scrollBy(0, 500);
      });
    },
  },
};
</script>
<style>
.custom-container {
  margin: 0 !important;
  width: 100%;
  display: grid;
  gap: 20px;
  padding: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.custom-col {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #fff;
  justify-content: start;
  height: 240px;
  border-radius: 8px;
}
.custom-col .custom-icon {
  width: 66px;
  height: 66px;
  margin-bottom: 15px;
}
.custom-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures the image covers the container while maintaining its aspect ratio */
}
.custom-col .text-align {
  text-align: left !important;
  font-weight: 700 !important;
  font-size: 20px;
  font-family: "Nunito";
  line-height: 28px;
  text-transform: uppercase;
}
.custom-col:hover {
  border: 1px solid #00b398;
}
.custom-col .fix-icon {
  position: absolute;
  left: 15px;
  bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  cursor: pointer;
}
.ai-portfolio-container {
  width: 100vw;
  padding: 20px 8% !important;
  background-color: #131313;
}
.ai-container {
  width: 84%;
  margin: 30px auto !important;
  padding: 20px 0 !important;
}
.ai-container .ai-grid {
  width: 100%;
  padding: 30px 0 !important;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 25px;
}
.ai-card {
  width: 100%;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid #626161;
}
.ai-card.custom-card {
  height: 270px !important;
}
.ai-card.custom-card .icon-image {
  margin-bottom: 90px !important;
}
.custom-desc {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 1rem;
}
.ai-card:hover {
  border: 1px solid #00b398;
}
.ai-card .icon-image {
  width: 40px;
  height: 40px;
  margin-bottom: 30px;
}
.icon-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ai-container .audio-to-text-container {
  width: 100%;
  padding: 20px 30px;
  border-radius: 12px;
  border: 1px solid #626161;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;
}
.audio-to-text-container img {
  width: 100%;
  height: auto;
}
.ai-container .integration-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}
.ai-portfolio-grid {
  width: 100%;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr;
  padding: 20px 0 !important;
}
.ai-portfolio-grid .ai-portfolio-card-left {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ai-portfolio-grid .ai-portfolio-card-right {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ai-portfolio-card-right img {
  width: 100%;
  height: auto;
}
.ai-portfolio-card-left .ai-portfolio-logo {
  width: 215px;
  height: 72px;
  object-fit: contain;
  margin-bottom: 20px !important;
}
.ai-portfolio-card-left .ai-portfolio-heading {
  font-family: "Nunito";
  font-size: 40px;
  line-height: 41px;
  font-weight: 800;
  color: #f2f2f2;
  letter-spacing: normal;
}

.custom-btn {
  width: 185px;
  background-color: #00b398;
  padding: 10px;
  margin-right: 0 !important;
  text-transform: capitalize;
  border-radius: 12px;
  font-size: 16px;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 1.2rem;
}
.ai-container .ai-bot-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}
.ai-bot-grid .ai-bot-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #626161;
  border-radius: 12px;
}
.ai-bot-card img {
  width: 100%;
  height: 100%;
}
.common-banner-heading {
  font-size: 40px !important;
  font-weight: 700 !important;
  line-height: 54px !important;
  letter-spacing: normal;
}
@media (min-width: 1500px) {
  .custom-desc {
    font-size: 22px !important;
    margin-bottom: 30px !important;
    line-height: 40px !important;
  }
}
@media (max-width: 768px) {
  .custom-container {
    margin: 0 !important;
    width: 100%;
    display: grid;
    gap: 20px;
    padding: 10px;
    grid-template-columns: 1fr 1fr;
  }
  .ai-container {
    width: 75%;
  }
  .ai-portfolio-container {
    padding: 20px 14% !important;
  }
  .ai-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .audio-to-text-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .audio-to-text-container .text-align {
    text-align: center !important;
  }
  .ai-bot-grid {
    grid-template-columns: repeat(1, 1fr) !important;
    width: 70% !important;
    margin: 30px auto !important;
  }
  .integration-grid {
    grid-template-columns: repeat(1, 1fr) !important;
    width: 70% !important;
    margin: 30px auto !important;
  }
  .ai-portfolio-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media (max-width: 480px) {
  .ai-container {
    width: 84%;
    margin: 10px auto !important;
  }
  .ai-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .ai-bot-grid {
    width: 100% !important;
    margin: 30px 0 !important;
  }
  .integration-grid {
    width: 100% !important;
  }
  .common-banner-heading {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 27px !important;
  }
  .custom-container {
    margin: 0 !important;
    width: 100%;
    display: grid;
    gap: 20px;
    padding: 10px;
    grid-template-columns: 1fr;
  }
  .cap-img {
    width: 120px !important;
    object-fit: contain;
  }
}
</style>
