import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import "vue-tel-input/dist/vue-tel-input.css";
import Vuelidate from "vuelidate";
import firebase from "./firebaseConfig";
import VueGtag from "vue-gtag";
Vue.use(loader);
Vue.use(
  VueGtag,
  {
    config: {
      id: "G-2NYLL9Y8S9",
      params: {
        send_page_view: true,
      },
    },
  },
  router
);
Vue.use(VueCookies);
Vue.prototype.$axios = axios.create({
  //baseURL: "http://localhost:8085/"
  baseURL: "https://ss-site-backend.septemsystems.com/",
});
Vue.use(VueToast);
Vue.use(VueGtag, {
  config: { id: process.env.GA_MEASUREMENT_ID | "G-7ZGR2YPS8W" },
}); // Google_analytics_configuration.
Vue.use(Vuelidate);

Vue.config.productionTip = false;
let app = null;
firebase.auth().onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      loader,
      render: function (h) {
        return h(App);
      },
    }).$mount("#app");
  }
  if (user) {
    let user = firebase.auth().currentUser;
    Vue.$cookies.set("user_uid", user.uid);
    store.commit("setIsLoggedState", true);
    store.commit("setUser", user);
  } else {
    Vue.$cookies.remove("user_uid");
    store.commit("setUser", null);
    store.commit("setIsLoggedState", false);
  }
});
