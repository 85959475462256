<template>
  <section class="portfolio-container">
    <div class="row gy-5">
      <div class="col-sm-12 col-md-12 col-lg-6">
        <img src="/images/bigscoot/bigscoots-logo.png" alt="" class="logo-img">
        <h1 class="main-heading">
          we play a role in giving website a soul.
        </h1>
        <h2 class="sub-heading">
          technologies
        </h2>
        <div class="tech-img">
          <div class="grid">
            <img src="/images/bigscoot/nuxt.png" alt="">
            <img src="/images/bigscoot/node.png" alt="">
            <img src="/images/bigscoot/firebase.png" alt="">
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6">
        <div class="flex">
          <img src="/images/bigscoot/xiaomi pad.png" alt="">
        </div>
      </div>
    </div>
    <div class="row gy-5">
      <div class="second-grid">
        <div>
          <div class="inrto-img">
            <img src="/images/bigscoot/longscroll.png" alt="">
          </div>
        </div>
        <div>
          <h1 class="main-heading">
            introduction
          </h1>
          <h2 class="sub-heading">
            Working in conjunction with BigScoots
          </h2>
          <p class="bigscoot-p">
            A business's online presence is extremely crucial to its
            growth thus adequate and substantial web hosting becomes
            an absolute necessity. Poor web hosting services can greatly
            stunt an online business's growth. BigScoots offers premium
            quality web hosting services that specialize in industry-leading
            support and management for shared, VPS, hybrid, and dedicated hosting.
            They bring forth fully controlled, concern-free hosting services that
            are used to power huge numbers of mission-critical
            websites and applications across the globe. BigScoots'
            shared hosting plans are not only feature-rich,
            but they also come with security, speed, and full management.
            BigScoots
            joined forces with Septem Systems to improve its website layout and features
          </p>
        </div>
      </div>
    </div>
    <div class="row gy-5">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">our integration</h1>
      </div>
    </div>
    <div class="row gy-5">
      <div class="col-sm-12 col-md-8 col-lg-8">
        <div class="integration-class">
          <p class="bigscoot-p">
            We rendered our expertise in website development,
            allowing our client’s users to have an unmatched
            and smooth website experience. We optimized their
            website’s back and frontend infrastructure.
            Enhancement of the feature set coupled with a
            complete user experience and interface revamping was performed.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="integration-img">
          <img src="/images/bigscoot/group1.png" alt="">
        </div>

      </div>
    </div>
    <div class="second-grid">
      <div>
        <div class="human">
          <img src="/images/bigscoot/human_to_human.png" alt="">
        </div>
      </div>
      <div>
        <p class="bigscoot-p">
          Continuous support even after sevice delivery has always been our
          greatest asset thus BigScoots didn't have to worry about any roadblocks
          from their website's perspective. the importance of receiveing support,
          regardless of the time, is quite critical. we created an intuitive live chat
          feature that is available 24/7 thats helps BigScoot's users to stay in contact
          anywhere and anytime.
        </p>
      </div>
    </div>
    <div class="row gy-5">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">outcome</h1>
      </div>
    </div>
    <div class="row gy-5">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="integration-class">
          <p class="bigscoot-p outcome-p">
            Our collaboration with BigScoots has enabled major
            and minor online businesses to carry out their processes
            and hosting of their websites without any hiccups.
            A high-quality web application development by Septem Systems
            exhibited sophistication and visual appeal, which has had
            a significant effect on our client’s consumer’s decision-making
            processes. A rapid and swift website load time helped Bigscoots’s
            users to make sure that their online presence stays unaffected.
            Simplifying the support feature has enabled Bigscoots to showcase
            a response time of fewer than 5 minutes. With a
            customer-centric approach, creating
            a sentiment of stressless operations has been our aim.
          </p>
          <img src="/images/bigscoot/hands.png" alt="" class="outcome-img">
        </div>
        
       
      </div>
    </div>
    <div class="row gy-5">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="full-img">
          <img src="/images/bigscoot/frame 4.png" alt="">
        </div>
      </div>
    </div>
    <div class="row gy-5">
      <div class="col-sm-12 col-md-4 col-lg-4 mt-5">
        <div class="g2-img">
          <img src="/images/bigscoot/group2.png" alt="">
        </div>
      </div>
      <div class="col-sm-12 col-md-2 col-lg-2 mt-5"></div>
      <div class="col-sm-12 col-md-6 col-lg-6 mt-5">
        <div class="testimonial">
          <div class="comment-flex">
            <div class="img">
              <img src="/images/bigscoot/ceo.png" alt="">
            </div>
            <div>
              <p>Scott Stapely</p>
              <span>CEO-Bigscoots</span>
            </div>
          </div>
          <p class="testimonial-p">
            “We are very impressed with Team Septem.
            They have been incredibly responsive even
            during off-hours and worked to resolve
            complex issues diligently. They built
            exactly what we were looking for.
            We will continue working with them
            and highly recommend Septem Systems
            to anyone seeking professional
            and high-quality development expertise."
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'BigscootsCaseStudy',

}
</script>

<style scoped>
.testimonial{
  position: relative;
  background-color: #151515;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  height: auto;
}
.testimonial .comment-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.testimonial .testimonial-p{
  font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 600;
  line-height: 1.5;
}
.bigscoot-p{
  text-align: left;
  font-size: 1.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.comment-flex .img {
  width: 50px;
  height: auto;
  border-radius: 50%;
  margin-left: 0px;
  padding: 0px 10px 0px 0px;
}

.comment-flex .img img {

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  border: 1px solid #00b398;
}

.comment-flex p {
 color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin: 0px !important; 
}

.comment-flex span {
  
   color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.g2-img {
  width: 100%;
  height: auto;
}

.g2-img img {
  width: 100%;
  height: 100%;
}

.full-img {
  width: 100%;
  height: auto;
  margin: 20px 0px;
  margin-bottom: 100px;
}

.full-img img {
  height: 100%;
  width: 100%;
}

.integration-class {
  position: relative;
  background-color: #151515;
  padding: 50px;
  border-radius: 5rem;
  width: 100%;
  height: auto;
}
.integration-class .outcome-img{
  position: absolute;
  width: 40rem;
  height: auto;
  bottom: 0;
  right: 0;
}

.hand-img {
  width: 140%;
  margin-left: -40%;
  margin-top: -15rem;
  height: 100%
}

.hand-img img {
  width: 100%;
  height: 100%;

}


.integration-img {
  width: 100%;
  height: auto;
}

.integration-img img {
  width: 100%;
  height: 100%;
}
.second-grid .integration{
  display: grid;
  grid-template-columns: 1fr 1.5fr;

}

.second-grid .human {
  width: 50%;
  height: auto;
  margin-left: 40%;
  
}

.second-grid .human img {
  width: 100%;
  height: 100%;
}

.second-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 20px;
  padding-top: 30px;
  align-items: center;
  margin: 70px 0 70px 0;
}

.logo-img {
  padding: 20% 0px 10% 0px;
  width: 130px;
  height: 100%;
}

.inrto-img {
  width: 100%;
  height: auto;
}

.inrto-img img {
  width: 100%;
  height: 100%;
}



.second-grid .main-heading {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.5px;
  padding-bottom: 5px;
  line-height: 45px;
  text-transform: capitalize;
}

.main-heading {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.5px;
  padding-bottom: 5px;
  line-height: 45px;
  text-transform: capitalize;
}

.second-grid.sub-heading {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.sub-heading {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.tech-img {
  width: 35%
}

.tech-img .grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  gap: 15px;
}

.grid img {
  width: 100%;
  height: 100%;
}

.flex {

  width: 100%;
  height: auto;
  padding-top: 20%;
}

.flex img {
  width: 100%;
  height: 100%;
}
.integration-class .outcome-p {
    padding-right: 35%;
  }

@media(max-width: 768px) {
  .integration-class .outcome-img {
    display: none;
  }

  .second-grid {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 10px 15px;
  }

  .second-grid div:first-child {
    order: 1;
  }

  .second-grid.main-heading {
    font-size: 30px;
    font-weight: 700;
  }

  .second-grid.sub-heading {
    font-size: 24px;
    font-weight: 700;
  }

  .logo-img {
    padding-top: 70px;
    width: 100px;
    height: 100%;
  }
}

@media(max-width: 480px) {
  .flex img{
    margin: 0;
  }
  .second-grid {
    margin-bottom: 20px;
  }

  .hand-img, .full-img{
    margin: 70px 0;
    padding: 0;
  }
  .second-grid .human{
    width: 70%;
  }
  .integration-class{
    padding: 10px 20px;
    border-radius: 20px;
  }
  .bigscoot-p {
    font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 500;
  line-height: 1.7;
  text-align: justify;
  }
  .testimonial-p{
    text-align: justify;
    font-weight: 400;
  }
  .integration-img{
    margin: 0;
  }
  .integration-class .bigscoot-p {
    font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 500;
  line-height: 1.7;
  text-align: justify;

  }
  .second-grid.main-heading {
    font-size: 20;
  }

  .second-grid.sub-heading {
    font-size: 16px;
  }
  .sub-heading {
    font-size: 20px;
  }
  .integration-img{
    margin-top:60px;
  }
  .g2-img{
    margin-bottom: 70px;
  }
  .integration-class .outcome-p {
    padding-right: 0 ;
  }
}
</style>
