<template>
  <lazy-component wrapper-tag="section" @intersected="dispatchOption">
    <div class="stats-container">
      <div class="stats-sub-container">
        <div class="stats-data-container">
          <div class="stats-data-text">
            <h1>{{ clientCount }}+</h1>
            <p>Completed Projects</p>
          </div>
          <div class="stats-data-text">
            <h1>{{ compeletedProjects }}%</h1>
            <p>Customer Satisfaction Rate</p>
          </div>
          <div class="stats-data-text">
            <h1>{{ transactionCount + "+" }}</h1>
            <p>Developers & Engineers</p>
          </div>
          <div class="stats-data-text">
            <h1>{{ customerCount }}+</h1>
            <p>Years of Experience</p>
          </div>
        </div>
      </div>
    </div>
  </lazy-component>
</template>
<script>
import LazyComponent from "v-lazy-component";
export default {
  name: "CompanyStats",
  data() {
    return {
      clientCount: 1,
      compeletedProjects: 1,
      customerCount: 0,
      transactionCount: 1,
    };
  },
  components: {
    LazyComponent,
  },
  methods: {
    countDownTimer() {
      if (this.clientCount < 120) {
        setTimeout(() => {
          this.clientCount++;
          this.countDownTimer();
        }, 20);
      }
    },
    projectsCount() {
      if (this.compeletedProjects < 97) {
        setTimeout(() => {
          this.compeletedProjects++;
          this.projectsCount();
        }, 20);
      }
    },
    customerCounts() {
      if (this.customerCount < 8) {
        setTimeout(() => {
          this.customerCount++;
          this.customerCounts();
        }, 200);
      }
    },
    transactionCounter() {
      if (this.transactionCount < 100) {
        setTimeout(() => {
          this.transactionCount++;
          this.transactionCounter();
        }, 20);
      }
    },

    dispatchOption() {
      this.countDownTimer();
      this.customerCounts();
      this.projectsCount();
      this.transactionCounter();
    },
  },
};
</script>
<style lang="css" scoped></style>
