import { render, staticRenderFns } from "./GtensorCaseStudy.vue?vue&type=template&id=15031c60&scoped=true"
import script from "./GtensorCaseStudy.vue?vue&type=script&lang=js"
export * from "./GtensorCaseStudy.vue?vue&type=script&lang=js"
import style0 from "./GtensorCaseStudy.vue?vue&type=style&index=0&id=15031c60&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15031c60",
  null
  
)

export default component.exports