<template>
  <div>
    <div class="row primary-footer-mobile">
      <div class="col-xs-4 col-sm-4 col-md-6 mobile-footer-image">
        <router-link to="/" class="m-bot-20 footer-logo">
          <img
            class="retina"
            width="80px"
            height="80px"
            src="assets/img/septemlogo.svg"
            alt=""
          />
        </router-link>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-6">
        <h2 class="service-heading">Quick Links</h2>
        <ul class="bottom-main-list">
          <li v-for="link in quickLinks1">
            <router-link :to="link.route">{{ link.routeName }}</router-link>
          </li>
        </ul>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-6" style="padding-top: 35px">
        <ul class="bottom-main-list">
          <li v-for="link in quickLinks2">
            <router-link :to="link.route">{{ link.routeName }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MobileFooter",
  data() {
    return {
      quickLinks1: [
        {
          route: "/",
          routeName: "Home",
        },
        {
          route: "/about",
          routeName: "About Us",
        },
        {
          route: "/portfolio",
          routeName: "Portfolio",
        },
      ],
      quickLinks2: [
        {
          route: "/?id=services",
          routeName: "Services",
        },
        {
          route: "/careers",
          routeName: "Careers",
        },
        {
          route: "/contact",
          routeName: "Contact",
        },
      ],
    };
  },
};
</script>
<style lang="css" scoped>
@media (max-width: 992px) {
  .primary-footer-mobile {
    display: flex !important;
    width: 100%;
    padding: 57px;
  }
  .primary-footer-mobile .mobile-footer-image {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .primary-footer-mobile .bottom-main-list {
    padding: 0px;
    list-style: none;
  }
  .primary-footer-mobile .bottom-main-list li a {
    color: white;
    font-weight: 700;
    font-size: 10px;
    line-height: 22px;
  }
  .service-heading {
    font-weight: 700;
    margin: 0px;
    letter-spacing: 0.1px;
    color: #00b398;
  }
  .service-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .primary-footer-mobile .service-container .service-list {
    list-style: none;
    padding: 0;
  }
  .primary-footer-mobile .service-container .service-list li a {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #ffffff;
  }
}
</style>
