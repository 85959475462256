import axios from "axios";
const resource_uri = "http://localhost:3000";
const INITIAL_STATE = {
  bannerDetail: {},
};

export const state = { ...INITIAL_STATE };

export const actions = {
  async fetchBannerData({ commit }) {
    const response = await axios.get(`${resource_uri}/banner`);
    commit("setTasks", response.data);
  },
};

export const mutations = {
  setTasks: (state, banner) => (state.bannerDetail = banner),
};

const getters = {
  bannerList(state) {
    return state.bannerDetail;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
