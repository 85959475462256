<template>
  <section>
    <div v-if="isLoading" class="loading">Loading&#8230;</div>

    <!--body content start-->

    <CareerBanner styleHeading="Career Fair" heading="2023"
                  paragraph="Everyone takes the limits of his own vision for the limits of the world."
                  auther=" ~Arthur Schopenhauer"/>

    <!--page title end-->

    <!--body content start-->

    <div class="row contact-form-container" v-if="!formSubmit">
      <div class="col-sm-12 input-heading">
        <h4 class="text-screen">We develop employees as leaders who can create, articulate and passionately own a vision eventually driving it to completion.</h4><br>
      </div>
      <div class="col-sm-6">
        <label>Name:</label>
        <div class="border">
          <div class="row">
            <div class="col-sm-1 sm-screen">
              <!--                <img class="name-icon" src="assets/name.png" />-->
              <i class="fa fa-user-o fa-icon"></i>
            </div>
            <div class="col-sm-11 sm-screen-11">
              <div class="col-sm-12">
                <input class="border input" v-model="$v.details.fullName.$model" type="textbox" placeholder="Name" />

                <!-- <input  class="border" type="text"  name="fname" value="John"> -->
              </div>
            </div>
          </div>
        </div>
        <p style="color: red" v-if="$v.details.fullName.$error">Name is required.</p>
      </div>

      <div class="col-sm-6">
        <label>Email:</label>
        <div class="border">
          <div class="row">
            <div class="col-sm-1 sm-screen">
              <!--                <img class="name-icon" src="assets/mail.png" />-->
              <i class="fa fa-envelope-o fa-icon"></i>
            </div>
            <div class="col-sm-11 sm-screen-11">
              <div class="col-sm-12">
                <input class="border input" v-model="$v.details.email.$model" type="email" placeholder="Email" />
              </div>
            </div>
          </div>
        </div>
        <p style="color: red" v-if="$v.details.email.$error">Email is required.</p>
      </div>

      <div class="col-sm-6">
        <label>Mobile No:</label>
        <div class="border">
          <div class="row">
            <div class="col-sm-1 sm-screen">
              <!--                <img class="name-icon" src="assets/contact.png" />-->
              <i class="fa fa-phone fa-icon"></i>
            </div>
            <div class="col-sm-11 sm-screen-11">
              <div class="col-sm-12">
                <input class="border input" v-model="$v.details.phone.$model" type="textbox"
                       placeholder="03xxxxxxxxx" />
              </div>
            </div>
          </div>
        </div>
        <p style="color: red" v-if="$v.details.phone.$error">Phone is required.</p>
      </div>
      <div class="col-sm-6">
        <label>Attach Your Resume/CV</label>
        <div class="border">
          <div class="row">
            <div class="col-sm-1 sm-screen">
              <!--                <img class="name-icon" src="assets/contact.png" />-->
              <i class="fa fa-clipboard fa-icon"></i>
            </div>
            <div class="col-sm-11 sm-screen-11">
              <div class="col-sm-12">
                <input class="file" ref="file" type="file" required @change="getFile
                " />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <label>Select Department</label>
        <div class="border">
          <div class="row">
            <div class="col-sm-1 sm-screen">
              <!--                <img class="name-icon" src="assets/website.png" />-->
              <i class="fa fa-building fa-icon"></i>
            </div>
            <div class="col-sm-11 sm-screen-11">
              <div class="col-sm-12">
                <select class="form-control option" v-model="$v.details.department.$model" type="textbox"
                        placeholder="Website">
                  <option value="" selected disabled hidden> Select Department</option>
                  <option v-for="element in category" :key="element.id">
                    {{ element }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <p style="color: red" v-if="$v.details.department.$error">Department is required.</p>
      </div>

      <div class="col-sm-12">
        <vue-recaptcha sitekey="6LcEwaYZAAAAAHg8-0T2vF5Ns-u4FB81-5E9OpE-" v-model="captcha" @verify="onVerifyReCaptcha"
                       @expired="onExpiredReCaptcha" />

      </div>
      <div class="col-sm-12"></div>
      <div class="col-sm-12">
        <div class="contact-btn">
          <div v-if="isLoading" class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <a @click="dataSubmit()" class="btn btn-medium btn-rounded btn-custom-solid sm-btn" style="font-size: 16px;">Submit
          </a>
        </div>
        <!-- <button class="send-button" type="button" @click="sendMail()">
            SEND MESSAGE
          </button> -->
      </div>
    </div>
    <div class="row contact-form-container text-center input-heading" v-if="formSubmit">
      <h2>Thank You</h2>
      <p class="form-text">Your application has been submitted successfully,
        A Relevant department will review
        it and get back to you within 10 days</p>
    </div>

    <div class="row contact-footer-container">
      <div class="col-sm-12 col-lg-4">
        <h1>Contact Information</h1>
        <div class="contact-left-container">
          <span class="contact-icon">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="23.3411" height="24.0318" transform="translate(0 0.5)" fill="#0A0A0A" />
              <path
                  d="M10.2647 6.74859L6.97458 2.83841C6.59529 2.38781 5.89992 2.38981 5.45935 2.84441L2.75373 5.63511C1.94846 6.46521 1.71796 7.69785 2.18381 8.68615C4.96687 14.619 9.61412 19.4105 15.3725 22.2842C16.3315 22.7638 17.5277 22.5265 18.333 21.6964L21.0639 18.8797C21.5064 18.4241 21.5074 17.7041 21.0658 17.3136L17.2534 13.9441C16.8547 13.5917 16.2352 13.6377 15.8355 14.0503L14.5089 15.4181C14.441 15.4914 14.3516 15.5397 14.2544 15.5556C14.1573 15.5715 14.0577 15.5542 13.9711 15.5062C11.8027 14.2206 10.0041 12.3663 8.75823 10.1321C8.71158 10.0427 8.69468 9.94004 8.71014 9.83984C8.7256 9.73964 8.77256 9.64742 8.84382 9.57734L10.1665 8.21453C10.5672 7.79998 10.6109 7.15913 10.2647 6.74759V6.74859Z"
                  stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <P>+92-42-38347751</P>
        </div>
        <div class="contact-left-container">
          <span class="contact-icon">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="23.3411" height="24.0318" transform="translate(0 0.560547)" fill="#0A0A0A" />
              <path
                  d="M13.859 18.5843H2.91781L2.91562 7.2488L11.2557 13.1937C11.3777 13.2806 11.5224 13.3271 11.6707 13.3271C11.819 13.3271 11.9638 13.2806 12.0858 13.1937L20.4237 7.2518V14.0783H21.8825V6.5684C21.8819 6.17023 21.728 5.78854 21.4546 5.50699C21.1811 5.22544 20.8104 5.067 20.4237 5.06641H2.91781C2.53102 5.0668 2.16019 5.22518 1.88669 5.50677C1.61319 5.78836 1.45937 6.17017 1.45898 6.5684V18.5843C1.45956 18.9825 1.61345 19.3642 1.8869 19.6457C2.16036 19.9273 2.53108 20.0857 2.91781 20.0863H13.859V18.5843ZM18.8182 6.5684L11.6707 11.6631L4.52324 6.5684H18.8182Z"
                  fill="white" />
              <path
                  d="M18.9645 21.588C20.5759 21.588 21.8822 20.2431 21.8822 18.5841C21.8822 16.925 20.5759 15.5801 18.9645 15.5801C17.3531 15.5801 16.0469 16.925 16.0469 18.5841C16.0469 20.2431 17.3531 21.588 18.9645 21.588Z"
                  fill="white" />
            </svg>
          </span>
          <P>info@septemsystems.com</P>
        </div>
        <div class="contact-left-container">
          <span class="contact-icon">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="23.3411" height="24.0318" transform="translate(0 0.620117)" fill="#0A0A0A" />
              <path
                  d="M11.6715 13.6378C13.2829 13.6378 14.5892 12.2929 14.5892 10.6339C14.5892 8.97481 13.2829 7.62988 11.6715 7.62988C10.0602 7.62988 8.75391 8.97481 8.75391 10.6339C8.75391 12.2929 10.0602 13.6378 11.6715 13.6378Z"
                  stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path
                  d="M11.671 2.62305C9.60752 2.62305 7.62855 3.46702 6.16945 4.9693C4.71034 6.47158 3.89063 8.50911 3.89062 10.6337C3.89063 12.5282 4.28159 13.7678 5.34945 15.1396L11.671 22.6496L17.9926 15.1396C19.0604 13.7678 19.4514 12.5282 19.4514 10.6337C19.4514 8.50911 18.6317 6.47158 17.1726 4.9693C15.7135 3.46702 13.7345 2.62305 11.671 2.62305V2.62305Z"
                  stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <P><a href="https://goo.gl/maps/QTSJ9AKSbEEPfBAR8" target="_blank">
            3rd Floor, Crown Complex , 31 Queens Road, Lahore, Pakistan</a></P>
        </div>
      </div>
      <div class="col-sm-12 col-lg-8 contact-right-container">
        <img src="../../public/assets/img/contact/map.png" alt="" width="100%" />
      </div>
    </div>

    <CompanyStats />
    <Testimonials />
  </section>
</template>

<script>
import firebase from "firebase/app"
import "firebase/storage"
import CommonBanner from "./common/CommonBanner.vue";
import CareerBanner from "./common/CareerBanner.vue";
import VueRecaptcha from "vue-recaptcha";
import { required } from "vuelidate/lib/validators";
import CompanyStats from "./common/CompanyStats.vue";
import Testimonials from "./Testimonials.vue";
const db = firebase.firestore()
export default {
  components: { VueRecaptcha, CareerBanner,CommonBanner, CompanyStats, Testimonials },
  data() {
    return {
      category: [
        "Web Application Development",
        "Mobile Application Development", "Software Quality Assurance",
        "UI / UX Design", "Cloud Development", "Digital Marketing","Business Development", "Humman Resource Management"],
      uploadValue: 0,
      fbResp: '',
      details: {
        fullName: "",
        email: "",
        phone: "",
        department: "",
        img: null,
      },
      captcha: false,
      formSubmit: false,
      file: null,
      isLoading: false,
      fileType: ["jpg", "jpeg", "pdf", "doc", "docx", "png"]
    }
  },
  validations: {
    details: {
      fullName: { required },
      email: { required },
      phone: { required },
      department: { required },
    },

  },


  methods: {
    onVerifyReCaptcha(response) {
      this.captcha = true
    },
    onExpiredReCaptcha() {
      console.log("Captcha Expire");
      this.captcha = false;
    },
    getFile(e) {
      this.uploadValue = 0
      this.file = e.target.files[0]
      const splitFileType = this.file.name.split('.')
      const selectedFileType = splitFileType[splitFileType.length - 1].toLowerCase()
      if (!this.fileType.includes(selectedFileType)) {

        this.$toast.error("This type of file is not allowed. Please select only allowed file type!")
        this.$refs.file.value = null

      }
    },
    async onUpload() {
      this.details.img = null;
      const storageRef = firebase.storage()
          .ref(`${this.details.department}/${this.file.name}`).put(this.file);
      storageRef.on(`state_changed`, snapshot => {
            this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          }, error => { console.log(error.message) },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              this.details.img = url;
              db.collection('job_users_umt').doc(this.fbResp.id).update(this.details)
              this.details.fullName = ''
              this.details.email = ''
              this.details.department = ''
              this.details.img = ''
              this.file = null
            });
          }
      );
      await this.addDetails()
    },
    handleClick() {

      this.isLoading = true
      if (!this.captcha) {
        this.$toast.error("Please solve captcha first!");
      } this.isLoading = false
    },
    async addDetails() {
      await db.collection('job_users_umt').add(this.details).then((resp) => {
        this.fbResp = resp
        this.formSubmit = true
        this.isLoading = false
      }).catch((e) => {
        this.$toast.error(e.message);
      })
    },
    async dataSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) {
        console.log('%cContact.vue line:259 "here"', "color: #007acc;", "here");
        return;
      }
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.details.email))) {
        this.$toast.error('Invalid email format!')
        return
      }
      if (!(/^[a-z A-Z a-z A-Z]+$/.test(this.details.fullName))) {
        this.$toast.error("Name field must be contain letters only")
        return
      }
      if (!(/^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/.test(this.details.phone))) {
        this.$toast.error("Please write a correct Mobile No.")
        return
      }
      if (!this.file) {
        this.$toast.error("Please Upload Your Resume!!");
        return;
      }
      if (!this.captcha) {
        this.$toast.error("Before you proceed, please solve the captcha!");
        return;
      }
      const resp = await db.collection('job_users_umt').where("email", '==', `${this.details.email}`).get()
      if (!resp.empty) {
        this.$toast.warning('You already have submitted your application!')
      } else {
        this.isLoading = true
        await this.onUpload()
      }


    },
  }
}
</script>

<style scoped>
label{
  color: #FFFFFF !important;
}
.form-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 156%;
  color: #ffffff;
}
.text-screen{
  font-size: 24px;
  font-family: century_gothicregular !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px black inset !important;
  -webkit-text-fill-color: #ffffff;
}

input[type="file"] {
  background-color: #000;
  color: #636c72 !important;
}

input {
  color: #fff !important;
}

.file::-webkit-file-upload-button {
  background-color: #000;
  color: #636c72;
  cursor: pointer;
}

.formSubmitNotification {
  width: 100%;
  position: absolute;
  top: 0;
  font-size: 2rem;
  font-weight: 700;
  color: fff;
  z-index: 10000;
  background-color: rgb(47, 54, 53);
  border-radius: 2rem;
  padding: 5rem 3rem;

}

.option {
  border: none;
  background-color: #000 !important;
  margin-top: 1rem;
  font-size: 1.6rem;
  margin-left: -1.7rem;
  font-weight: 600;

}

.input-heading {
  color: #00b197 !important;
  font-family: "Nunito";
  text-align: center;
}

.input-heading h2 {
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0.01em;
  color: #00b197 !important;
  font-family: "Nunito";
  text-align: center;

}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8),
  rgba(0, 0, 0, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
  rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
  rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
  rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
  rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
  rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
  rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
  rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
  rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
  rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
  rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
  rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
  rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
  rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
  rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.contact-footer-container {
  padding: 100px 85px;
}

.contact-footer-container h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 133%;
  color: #00b197;
}

.contact-left-container {
  display: flex;
  align-items: center;
}

.contact-left-container p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: #ffffff;
}

.contact-left-container a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: #ffffff;
}

.contact-left-container a:hover {
  color: #00b197;
}

.contact-right-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.contact-form-container {
  padding: 50px 85px;
}

.btn:hover {
  color: #fff;
  background: #007cb7;
}

@media (max-width: 480px) {
  .text-screen{
    font-size: 11px;
    font-family: century_gothicregular !important;
  }
}
@media (max-width: 1000px) {
  input[type="file"] {
    background-color: #000;
    height: 50px;
    color: #636c72 !important;
  }

  .sm-screen {
    height: 0px;
    padding-left: 0px;
  }

  .sm-screen-11 {
    height: 50px;
    padding-left: 28px;
  }

  .sm-btn {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 480px) {
  .contact-footer-container {
    padding: 0px 15px;
  }

  .contact-form-container {
    padding: 30px 15px;
  }

  .contact-footer-container h1 {
    font-size: 24px;
  }

  .contact-left-container a {
    font-size: 13px;
  }
}
</style>