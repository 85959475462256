<template>
  <section>
    <div class="container">
      <div class="row atmos-padding-top">
        <div class="col-sm-12">
          <div class="col-sm-2">
            <img class="atmos-image" src="assets/primero-logo-1.png">
          </div>
          <div class="col-sm-1"></div>

          <div class="col-sm-9">
            <div class="col-sm-12">
              <p class="Case-study-atmos-p">SUCCESS STORY</p>
            </div>
            <div class="col-sm-12">
              <h1 class="atmos-h-font">Protecting every child.</h1>
            </div>
            <div class="col-sm-12">
              <p class="Case-study-atmos-p1">With the era of digitization upon us, slow processes and manual systems will a hassle that only resulted in the slowing down and unnecessary delays. Child protection was earlier mapped onto such manual systems which were too slow to respond to children who have been exposed to various forms of abuse. Septem Systems created the Child Protection Case Management and Referral Models (CP-CMRM) to digitize the process while ensuring two important aspects; accountability, and documentation. </p>
            </div>
            <div class="col-sm-12 Atmos-btn">
              <a href="#/contact" class="btn btn-medium btn-custom-solid ">Lets talk about your project <i class="fa fa-arrow-right"></i></a>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="container">
      <div class="row padding-top">
        <div class="col-sm-4">
          <h2 class="center">INDUSTRY</h2>
          <p class="p-center">Child Protection</p>
        </div>

        <div class="col-sm-4">
          <h2 class="center">HEADQUARTERS</h2>
          <p class="p-center">Pakistan</p>
        </div>

        <div class="col-sm-4">
          <h2 class="center">PLATFORMS</h2>
          <p class="p-center"> ANDROID, WEB </p>
        </div>
      </div>
    </div>


    <div class="container">
      <div class="row padding-row">

        <div class="col-sm-1"></div>
        <div class="col-sm-2">
          <img class="co-founder-img" src="assets/Tpi-1.png">
        </div>
        <div class="col-sm-8">
          <div class="atmos-border-box">
            <div class="col-sm-12">
              <p class="co-founder-paragrafh"> “The team at Septem Systems did an impressive job configuring Primero in an aggressive time frame. The team worked tirelessly to get it done on time and are skilled problem solvers. They were the perfect balance of professionalism and diligence. But most importantly, they were able to deliver a solid product - one we can be proud of. Showcasing availability throughout the development process and even post-development, two words, excellent job!”</p>
            </div>
            <div class="col-sm-12">
              <h3 class="co-founder-name"> Doc. Fareed Zaffar</h3>
              <p class="co-founder-inc">TPI LUMS</p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="container">
      <div class="row esg-case-study-position">
        <div class="col-sm-6">
          <h4 class="introduction-heading">Introduction</h4>
          <h4 class="introduction-heading">Working in conjunction with TPI LUMS </h4>
          <p class="introduction-paragrafh"> United Nations Children Fund (UNICEF) Pakistan commissioned Oxford Policy Management (OPM) to provide technical support for the development of a program aimed at establishing mechanisms to respond to children (girls and boys) subjected to all forms of abuse, violence, exploitation and neglect through the establishment of Child Protection Case Management and Referral Models (CP-CMRM). OPM required a single electronic administration database that would facilitate swift case management, easy activity monitoring, and timely service provision to the children.
          </p>
        </div>

        <div class="col-sm-6 introduction-img-center">
          <img class="introduction-img" src="assets/primero-1.png">
        </div>

      </div>


      <div class="row integration-padding Atmos-sekind-row">
        <div class="col-sm-6 introduction-img-center-1">
          <img class="integration-img" src="assets/primero-2.png">
        </div>

        <div class="col-sm-6 introduction-img-center-2">
          <h4 class="atmos-introduction-heading">Our Integration</h4>
          <p class="introduction-paragrafh">We configured Primero to design a system that was efficient and rigid considering the remote areas and infrastructure conditions of the country. Primero is an information management system (IMS) which makes data handling easier for front-line protection and social welfare workers, freeing up their time for service provision and promoting improved quality of care. CP-CMRM fosters accountability, helping supervisors monitor and report on the work of their teams. Role-based access and granular security ensure that only those who need to see data will have access to it. It has built-in customizable reports and dashboards that will facilitate periodic reporting processes, and be used to analyze data for programmatic insights.</p>
        </div>
      </div>


      <div class="row Atmos-sekind-row-m">
        <div class="col-sm-6">
          <h4 class="atmos-introduction-heading">Our Integration</h4>
          <p class="introduction-paragrafh">We configured Primero to design a system that was efficient and rigid considering the remote areas and infrastructure conditions of the country. Primero is an information management system (IMS) which makes data handling easier for front-line protection and social welfare workers, freeing up their time for service provision and promoting improved quality of care. CP-CMRM fosters accountability, helping supervisors monitor and report on the work of their teams. Role-based access and granular security ensure that only those who need to see data will have access to it. It has built-in customizable reports and dashboards that will facilitate periodic reporting processes, and be used to analyze data for programmatic insights.</p>
        </div>

        <div class="col-sm-6">
          <img class="integration-img" src="assets/primero-2.png">
        </div>

      </div>






      <div class="row outcome-padding">
        <div class="col-sm-6">
          <h4 class="atmos-introduction-heading">The 0utcome</h4>
          <p class="introduction-paragrafh">Septem Systems used Primero to develop “Child Protection Case Management and Referral System”. CP-CMRM supported efficient and effective case management and service provision allowing users to delegate cases, prioritize work, flag issues to supervisors, refer and transfer cases to other users of the system, enter and store case data digitally, and develop case plans. It is a dependable system that mapped over the whole case management system and ensured minimal delays.</p>

        </div>

        <div class="col-sm-6 introduction-img-center">
          <img class="outcome-img" src="assets/primero-3.png">
        </div>

      </div>
    </div>

    <div class="row native-padding">
      <div class="col-sm-12">
        <h5 class="techno-heading">Technologies</h5>
      </div>
      <div class="col-sm-12">
        <p class="native-para">React.js, Ruby on Rails, Mongo DB</p>
      </div>
    </div>

    <div class="container">
      <div class="row android-padding">
        <!-- <div class="col-sm-2"></div> -->
        <div class="col-sm-3"></div>
        <div class="col-sm-2 align">
          <img class="java-img" src="assets/React.js.png">
        </div>

        <div class="col-sm-2 align">
          <img class="java-img" src="assets/Ruby on Rails.png">
        </div>

        <div class="col-sm-2 align">
          <img class="java-img" src="assets/Mongo DB.png">
        </div>
        <div class="col-sm-3"></div>
      </div>
    </div>





  </section>
</template>

<script>
export default {
  name: "PrimeroCaseStudy.vue"
}
</script>

<style scoped>
.integration-img{
  width: 100%;
  /*padding: 53px 40px 0px 45px;*/
}

.introduction-img{
  width: 100%;
  /*padding: 96px 0 0 70px;*/
}
</style>
