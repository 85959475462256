<template>
  <div
    class="ourClients"
    style="margin: 7% 0 0 0 !important; padding: 0 !important"
  >
    <div class="heading-title-alt border-short-bottom text-center">
      <div class="row aboutUs-slider-row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <VueSlickCarousel v-bind="settings" class="testimonials-container">
            <div v-for="(image, index) in images" :key="index" class="slide">
              <img
                :src="`/assets/img/clients/slider/${image}`"
                alt="Iamge"
                width="100%"
              />
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: "CommonClients",
  components: { VueSlickCarousel },
  props: ["images"],
  data() {
    return {
      settings: {
        infinite: true,
        arrows: false,
        dots: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        initialSlide: 0,
        autoplaySpeed: 5000,
        cssEase: "ease",
        responsive: [
          {},
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 0,
            },
          },
        ],
      },
    };
  },
};
</script>
<style scoped>
.slide {
  padding: 1.5rem 0 !important;
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
}
::v-deep .slick-list {
  padding: 0 !important;
  justify-content: unset;
}
::v-deep .slick-track {
  align-items: center;
}
::v-deep .slick-slide {
  justify-content: center;
  padding: 0 !important;
  width: 100%;
}
.slide .slider-image {
  width: auto !important;
  height: 100px !important;
  object-fit: contain;
  padding: 10px !important;
}
@media (max-width: 768px) {
  .slide .slider-image {
    width: 160px !important;
    height: 60px !important;
  }
}
@media (max-width: 768px) {
  .slide .slider-image {
    width: 120px !important;
    height: 40px !important;
    object-fit: contain;
  }
}
</style>
