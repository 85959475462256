<template>
    <div>
        <section>
            <CommonBanner style-heading="Empower Your Vision with" heading="Global Tech Talent"
                paragraph="Septem Systems offers rapid access to premier software developers, designers, and tech specialists, perfectly aligned with your project's scope and time zone" />
            <div class="container">
                <div class="flex">
                    <h1>process to assemble our team</h1>
                    <p>
                        Our <span>3-step</span> process takes the team from inception to finished product.
                        Septem ensures hiring a dedicated iOS development team within 24 hours.
                    </p>
                </div>

                <div class="step-boxes">
                    <div class="box">
                        <span>1</span>
                    </div>
                    <div class="arrow-content">
                        <p></p>
                        <span class="arrow right">

                        </span>
                    </div>
                    <div class="box"><span>2</span></div>
                    <div class="arrow-content">
                        <p></p>
                        <span class="arrow right">

                        </span>
                    </div>
                    <div class="box"><span>3</span></div>
                </div>
                <div class="three-step-content">
                    <div class="content">
                        <div class="box"><span>1</span></div>
                        <h2>
                            choose the expert resource
                        </h2>
                        <p>
                            Opt for an expert engineer,
                            developer and designer from a wide pool of <span class="base-color">1300+</span> resources.
                            We are problem solvers who value integrity.
                        </p>
                    </div>
                    <div class="content">
                        <div class="box"><span>2</span></div>
                        <h2>
                            begin with initial interview
                        </h2>
                        <p>
                            Every member at Septem is an expert in their domain.
                            Schedule your interview today to initiate your project with exceptional results.
                        </p>
                    </div>
                    <div class="content">
                        <div class="box"><span>3</span></div>
                        <h2>
                            hire a developer within a day
                        </h2>
                        <p>
                            We will match you with an expert
                            resource based on your project requirements
                            and make your engineering team within 24 hours.
                        </p>
                    </div>
                </div>
                <div class="flex-btn">
                    <button @click="filterDeveloper('all')" class="btn" :class=" { active: isCurrent === 'all' } ">
                        all
                    </button>
                    <button @click="filterDeveloper('full stack')" class="btn"
                        :class=" { active: isCurrent === 'full stack' } ">
                        full stack
                    </button>
                    <button @click="filterDeveloper('mobile')" class="btn" :class=" { active: isCurrent === 'mobile' } ">
                        mobile app
                    </button>
                    <button @click="filterDeveloper('ui')" class="btn" :class=" { active: isCurrent === 'ui' } ">
                        UI/UX
                    </button>
                    <button @click="filterDeveloper('qa')" class="btn" :class=" { active: isCurrent === 'qa' } ">
                        SQA
                    </button>

                </div>
                <div v-for="     developer      in      filteredDevelopers     " :key=" developer.name ">
                    <div class="heading-flex">
                        <h2>{{ developer.name }}</h2>
                        <span class="h-line"></span>
                    </div>
                    <div class="developer-grid" :class=" [filterDeveloper.length % 2 === 1 ? 'grid-items' : ''] ">
                        <div v-for="     developer      in      developer.data     " :key=" developer.id "
                            class="developer-content">
                            <div class="img">
                                <img :src=" developer.pic " alt="">
                            </div>
                            <h1 class="heading">{{ developer.name }}</h1>
                            <h2 class="sub-heading">{{ developer.designation }}</h2>
                            <p>{{ developer.description }}</p>
                            <div class="skills-flex">
                                <span v-for="(     skill, index     ) in      developer.skills     " :key=" index "
                                    class="pills">{{
                                        skill
                                    }}</span>
                            </div>
                            <button @click="handleDeveloper(developer)" class="btn-block">hire now</button>
                        </div>
                    </div>
                </div>
                <div class="heading-flex">
                    <h2>Frequently Asked Questions</h2>
                    <span class="h-line"></span>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-2 col-lg-2"></div>
                    <div class="col-sm-12 col-md-8 col-lg-8">
                        <div class="faq-flex" v-for="(     faq, index     ) in      faqs     " :key=" index ">
                            <div class="question">
                                <strong>
                                    {{ faq.question }}
                                </strong>
                                <i :class=" [faq.isClick ? 'fa fa-minus' : 'fa fa-plus'] "
                                    style="cursor: pointer; color: #00B398; font-size: 14px;"
                                    @click="faq.isClick = !faq.isClick"></i>
                            </div>
                            <p v-if=" faq.isClick " class="answer">
                                {{ faq.answer }}
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2"></div>
                </div>
            </div>

        </section>
    </div>
</template>

<script>
import CommonBanner from './common/CommonBanner.vue';
import ContactUs from './ContactUs.vue'
export default {
    name: 'OurTeam',
    data() {
        return {
            faqs: [
                {
                    question: 'What areas of expertise can I expect when hiring from Septem Systems?',
                    isClick: false,
                    answer: 'Our talent pool encompasses specialists in Enterprise Software Development, Website Development, Product Development, Mobile App Development, IoT Development, AI & Machine Learning Solutions, and UI/UX Designing.'
                },
                {
                    question: 'How quickly can I onboard a developer or tech specialist?',
                    isClick: false,
                    answer: 'We pride ourselves on rapid response. You can empower your project with our global tech talent, often within just 24 hours.'
                },
                {
                    question: 'Do you offer flexibility in terms of time zones and work models?',
                    isClick: false,
                    answer: 'Absolutely! We ensure that the tech specialists you hire through Septem Systems align perfectly with your project  scope and time zone requirements.'
                },
                {
                    question: 'What notable partnerships has Septem Systems had in the past?',
                    isClick: false,
                    answer: 'We have proudly collaborated with esteemed organizations like UNICEF, UNODC, SNG, TPI LUMS, and OPM, driving positive impact in business and societal sectors.'
                },
                {
                    question: 'What is Septem Systems approach towards service delivery?',
                    isClick: false,
                    answer: 'Our commitment to excellence is unwavering. We are dedicated to crafting robust business solutions, transforming aspirations into realities through precise execution and impactful digital transformation.'
                }
            ],
            isCurrent: 'all',
            isClick: false,
            developer: null,
            developers: [
                {

                    name: 'full stack developers',
                    tech: 'full stack',
                    data: [
                        {
                            id: 1,
                            name: 'shahbaz mahmood khan',
                            pic: '/images/our-team/shahbaz.jpg',
                            designation: 'principle software engineer',
                            category: 'full stack developer',
                            description: 'Shahbaz senior full stack engineer with 10 years expertise excels in AI and IoT-integrated web and mobile app solutions',
                            skills: ['React', 'Vue.Js', 'Node.Js', 'Laravel'],
                            comments: [
                                {
                                    id: 1,
                                    title: 'Alex Johnson',
                                    subTitle: 'Chief technology officer',
                                    comment: 'Shahbazs expertise as a Principal Software Engineer is unparalleled. His innovative solutions and forward-thinking approach significantly boosted our projects efficiency and success. A true asset to any team',
                                    stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                                },
                                {
                                    id: 2,
                                    title: 'emma smith',
                                    subTitle: 'VP of engineering',
                                    comment: 'Working with Shahbaz has been a game-changer. His deep understanding of software engineering principles and his ability to lead complex projects is remarkable. He is not just a tech expert; he is a visionary leader.',
                                    stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                                }
                            ]
                        },
                        {
                            id: 2,
                            name: 'khurram sajjad',
                            pic: '/images/our-team/khurram.jpg',
                            designation: 'principle software engineer',
                            category: 'full stack developer',
                            description: 'Khurram, Principal Software Engineer excels in technical project management, IoT, AI/ML, web, & mobile app development.',
                            skills: ['ES6', 'Vue.Js', 'Node.Js', 'MongoDB'],
                            comments: [{
                                id: 3,
                                title: 'sarah johnson',
                                subTitle: 'director of technology',
                                comment: 'Khurram expertise as a Principal Software Engineer is unmatched. His innovative solutions and technical leadership have significantly elevated our team performance. His dedication to excellence is inspiring.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 4,
                                title: 'david lee',
                                subTitle: 'senior project manager',
                                comment: 'Working with Khurram has been a game-changer. His exceptional skills in software engineering and his ability to tackle complex challenges have made a remarkable impact on our projects success. Truly a top-tier talent.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        },
                        {
                            id: 3,
                            name: 'mohsin kamran',
                            pic: '/images/our-team/mohsin.jpg',
                            designation: 'senior software engineer',
                            category: 'full stack developer',
                            description: 'Mohsin, a seasoned full-stack engineer with expertise in creating large-scale applications with end-to-end development.',
                            skills: ['PostgreSQL', 'Vue.Js', 'Node.Js', 'Bootstrap'],
                            comments: [{
                                id: 5,
                                title: 'emily turner',
                                subTitle: 'IT department head',
                                comment: ' Mohsin, as a Senior Software Engineer, consistently delivers high-quality work. His technical acumen and problem-solving skills are exceptional, greatly contributing to our projects success.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 6,
                                title: 'alex smith',
                                subTitle: 'chief technology officer',
                                comment: 'Mohsin contributions as a Senior Software Engineer have been invaluable. His innovative approaches and deep technical knowledge have driven our software development to new heights. An asset to any team.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        },
                        {
                            id: 4,
                            name: 'sara abbas',
                            pic: '/images/our-team/sara.jpg',
                            designation: 'software engineer',
                            category: 'full stack developer',
                            description: 'Sara, a proficient full-stack engineer with over 6 years of experience, excels in crafting successful web applications.',
                            skills: ['next.Js', 'Vue.Js', 'Node.Js', 'MySQL'],
                            comments: [{
                                id: 9,
                                title: 'james peterson',
                                subTitle: 'Director of Technology',
                                comment: 'Sara expertise in software engineering is unparalleled. Her innovative solutions have significantly improved our system efficiency, setting new benchmarks in our technical operations.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 10,
                                title: 'Angela Rodriguez',
                                subTitle: 'Chief Information Officer',
                                comment: 'Working with Sara has been a game-changer. Her strategic approach to complex software challenges has not only elevated our tech capabilities but also inspired our team to strive for excellence.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        },
                        {
                            id: 5,
                            name: 'rao hamza javaid',
                            pic: '/images/our-team/rao.jpg',
                            designation: 'senior software engineer',
                            category: 'full stack developer',
                            description: 'Rao, an accomplished full-stack engineer excelling in challenging web app projects, with end-to-end development skills.',
                            skills: ['React', 'Vue.Js', 'next.Js', 'Laravel'],
                            comments: [{
                                id: 7,
                                title: 'john doe',
                                subTitle: 'chief technology officer',
                                comment: 'Rao Hamza  expertise as a Senior Software Engineer is unparalleled. His innovative solutions and attention to detail have significantly enhanced our technical operations. His skill set is a rare find in the industry.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa- fa-star', 'fa fa-star'],
                            },
                            {
                                id: 8,
                                title: 'jane smith',
                                subTitle: 'Director of Product Development',
                                comment: 'Working with Rao Hamza has been a game-changer. His technical acumen and problem-solving abilities as a Senior Software Engineer have tremendously accelerated our product development cycle. He is an asset to any team.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa f-star'],
                            }]
                        },
                        {
                            id: 6,
                            name: 'mahnoor feroz',
                            pic: '/images/our-team/mahnoor.jpg',
                            designation: 'software engineer',
                            category: 'full stack developer',
                            description: 'Mahnoor Feroz is a dedicated software engineer and a problem solver. She has delivered multiple projects profoundly.',
                            skills: ['Rest APIs', 'Vue.Js', 'python', 'java'],
                            comments: [{
                                id: 11,
                                title: 'Michael Chen',
                                subTitle: 'Senior Product Manager',
                                comment: 'Mahnoor technical acumen and dedication have been instrumental in our product development. Her innovative mindset and problem-solving skills have made a lasting impact on our success.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 12,
                                title: 'Lisa Marie Johnson',
                                subTitle: 'Vice President of Engineering',
                                comment: 'Mahnoor is a standout software engineer whose contributions have been vital to our growth. Her ability to navigate and solve complex technical issues is truly remarkable and highly valued.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        },
                        {
                            id: 7,
                            name: 'hassan nadeem',
                            pic: '/images/our-team/hassan.jpg',
                            designation: 'senior full stack engineer',
                            category: 'full stack developer',
                            description: 'Hassan, an 8-year expert full stack engineer excelling in web app development, stays updated with industry trends.',
                            skills: ['React', 'Python', 'Node.Js', 'C++'],
                            comments: [{
                                id: 15,
                                title: 'Emily Brown',
                                subTitle: 'Head of IT',
                                comment: 'Hassan exceptional skill set in full stack engineering brought significant improvements to our systems. His ability to tackle complex challenges with ease and deliver scalable solutions is truly commendable.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 16,
                                title: 'David Wilson',
                                subTitle: 'VP of Engineering',
                                comment: 'Working with Hassan has been an enlightening experience. His mastery in integrating diverse technologies and creating seamless, efficient applications has greatly benefited our engineering department.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        },
                        {
                            id: 8,
                            name: 'abdullah asif',
                            pic: '/images/our-team/abdullah-asif.jpg',
                            designation: 'senior full stack engineer',
                            category: 'full stack developer',
                            description: 'Abdullah, an expert full stack engineer with 6+ years experience, excels in crafting web apps for medium to large clients.',
                            skills: ['Next.Js', 'Vue.Js', 'Node.Js', 'Laravel'],
                            comments: [{
                                id: 13,
                                title: 'James Smith',
                                subTitle: 'CTO',
                                comment: 'Abdullah expertise in full stack development revolutionized our project trajectory. His innovative solutions and remarkable coding efficiency exceeded our expectations, setting new standards in our tech endeavors.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 14,
                                title: 'Laura Johnson',
                                subTitle: 'Director of Product Development',
                                comment: 'Collaborating with Abdullah was a game-changer. His profound knowledge in both front-end and back-end technologies transformed our product, delivering exceptional user experiences and robust back-end functionalities.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        },
                        {
                            id: 9,
                            name: 'abdullah asim',
                            pic: '/images/our-team/abdullah-asim.jpg',
                            designation: 'full stack engineer',
                            category: 'full stack developer',
                            description: 'Abdullah, a full-stack JS engineer with 5+ years expertise in web frameworks, adds value to web app development.',
                            skills: ['Next.Js', 'Vue.Js', 'Node.Js', 'bootstrap'],
                            comments: [{
                                id: 17,
                                title: 'Sophia Martinez',
                                subTitle: 'Project Manager',
                                comment: 'Abdullah’s contributions as a Full Stack Engineer were vital to our project’s success. His innovative approach and attention to detail in both front-end and back-end tasks were impressive and highly effective.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 18,
                                title: 'Michael Davis',
                                subTitle: 'Lead Developer',
                                comment: 'Abdullah skills as a Full Stack Engineer are top-notch. His ability to navigate complex code and produce clean, efficient solutions made a significant impact on our development process and overall project quality.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        }
                    ]
                },
                {

                    name: 'mobile app developers',
                    tech: 'mobile',
                    data: [
                        {
                            id: 1,
                            name: 'rehan yousaf',
                            pic: '/images/our-team/rehan.jpg',
                            designation: 'senior mobile app developer',
                            category: 'mobile app',
                            description: 'Rehan excels in cross-platform Flutter app development, delivering seamless, high-performance mobile experiences.',
                            skills: ['flutter', 'java', 'swift', 'firebase'],
                            comments: [{
                                id: 19,
                                title: 'Alexandra Thompson',
                                subTitle: 'Director of IT',
                                comment: 'Rehan expertise in app development is unparalleled. His innovative approach and technical prowess significantly enhanced our mobile application performance and user experience.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 20,
                                title: 'Michael Richards',
                                subTitle: 'Chief Technology Officer',
                                comment: 'Rehan exceptional skill set in mobile app development brought our ideas to life. His dedication to quality and efficiency is evident in the seamless functionality of our app.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        },
                        {
                            id: 2,
                            name: 'sami shehzad',
                            pic: '/images/our-team/sami.jpg',
                            designation: 'senior mobile app developer',
                            category: 'mobile app',
                            description: 'Sami excels in crafting efficient cross-platform mobile apps with hands-on expertise and a proven track record of success.',
                            skills: ['flutter', 'kotlin', 'swift', 'AI'],
                            comments: [{
                                id: 21,
                                title: 'Clara Johnson',
                                subTitle: 'Head of Digital Innovation',
                                comment: 'Sami proficiency in mobile app development is impressive. His ability to translate complex requirements into user-friendly applications is exceptional and invaluable to our team.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 22,
                                title: 'David Lee',
                                subTitle: 'VP of Product Development',
                                comment: 'Working with Sami has been transformative. His strategic approach to app development has not only improved our product but also enhanced our overall market presence.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        },
                        {
                            id: 3,
                            name: 'musab bin abid',
                            pic: '/images/our-team/musab.jpg',
                            designation: 'mobile app developer',
                            category: 'mobile app',
                            description: 'Musab, a proficient Flutter mobile app developer, specializes in cross-platform apps, known for innovative, efficient solutions.',
                            skills: ['flutter', 'dart', 'firebase', 'swift'],
                            comments: [{
                                id: 23,
                                title: 'Emily Wang',
                                subTitle: 'Product Manager',
                                comment: 'Musab talent in mobile app development is remarkable. His creative solutions and attention to detail have greatly improved our app functionality and user engagement.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 24,
                                title: 'Richard Kim',
                                subTitle: 'Director of Engineering',
                                comment: 'Musab is a standout in mobile app development. His innovative ideas and commitment to excellence have been crucial in elevating our application performance and user satisfaction.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        },

                    ]
                },
                {

                    name: 'UI/UX developers',
                    tech: 'ui',
                    data: [
                        {
                            id: 1,
                            name: 'sandeed sheikh',
                            pic: '/images/our-team/sandeed.jpg',
                            designation: 'lead UI/UX designer',
                            category: 'UI',
                            description: 'Sandeed, a skilled professional, crafts user-friendly, visually appealing UI/UX for large-scale web & mobile apps.',
                            skills: ['figma', 'afterEffects', 'adobe XD', 'UCD'],
                            comments: [{
                                id: 25,
                                title: 'Emily Thompson',
                                subTitle: 'CTO',
                                comment: 'Sandeed expertise in UI/UX design is unmatched. His innovative approach and meticulous attention to detail have significantly enhanced our product user experience.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 26,
                                title: 'David Wilson',
                                subTitle: 'Product Manager',
                                comment: 'Working with Sandeed was a game-changer. His skills in UI/UX engineering brought a fresh perspective and greatly improved our interface design.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        },
                        {
                            id: 2,
                            name: 'haseeb ur rehman',
                            pic: '/images/our-team/haseeb.jpg',
                            designation: 'senior UI/UX designer',
                            category: 'UI',
                            description: 'Haseeb enhances UX by blending aesthetics and functionality, a valuable asset for complex, scope-intensive projects.',
                            skills: ['figma', 'inDesign', 'illustrator', 'XD'],
                            comments: [{
                                id: 29,
                                title: 'Linda Smith',
                                subTitle: 'VP of Engineering',
                                comment: 'Haseeb talent in UI/UX design is exceptional. He consistently delivers intuitive and engaging user experiences, driving our product success.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 30,
                                title: 'Richard Brown',
                                subTitle: 'Chief Strategy Officer',
                                comment: 'Haseeb’s approach to UI/UX is both innovative and practical. His designs have greatly improved our user engagement and satisfaction.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        },
                        {
                            id: 3,
                            name: 'ali gohar',
                            pic: '/images/our-team/ali.jpg',
                            designation: 'senior UI/UX designer',
                            category: 'UI',
                            description: 'Ali excels in designing engaging, intuitive user interfaces for complex web & mobile apps, ensuring user experiences.',
                            skills: ['figma', 'miro', 'design systems', 'research'],
                            comments: [{
                                id: 27,
                                title: 'Sarah Johnson',
                                subTitle: 'Head of Development',
                                comment: 'Ali contributions to our UI/UX team were phenomenal. His creative solutions and user-centric designs have been pivotal in our project success.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 28,
                                title: 'Mark Lee',
                                subTitle: 'Head of Development',
                                comment: 'Ali proficiency in UI/UX engineering is impressive. His designs are not only visually stunning but also incredibly user-friendly.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        },

                    ]
                },
                {

                    name: 'software quality assurance',
                    tech: 'qa',
                    data: [
                        {
                            id: 1,
                            name: 'hamza siddiqui',
                            pic: '/images/our-team/hamza.jpg',
                            designation: 'senior SQA engineer',
                            category: 'qa',
                            description: 'Hamza Siddiqui is a dedicated Senior SQA Engineer with a diverse experience in full-cycle testing services.',
                            skills: ['testing', 'manual', 'automation', 'usability'],
                            comments: [{
                                id: 31,
                                title: 'Sarah Thompson',
                                subTitle: 'CTO',
                                comment: 'Hamza expertise in software quality assurance is unparalleled. His innovative testing strategies have significantly improved our product reliability. A true asset to any tech team.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 32,
                                title: 'Mark Richards',
                                subTitle: 'Director of Engineering',
                                comment: 'Working with Hamza transformed our QA process. His deep understanding of complex systems and proactive approach to testing drove remarkable improvements in our software quality.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        },
                        {
                            id: 2,
                            name: 'ammad sohail',
                            pic: '/images/our-team/ammad.jpg',
                            designation: 'SQA engineer',
                            category: 'qa',
                            description: 'Ammad, a versatile QA expert skilled in manual and automation testing, specializing in web & mobile app assessment.',
                            skills: ['manual', 'automation', 'testing', 'SDLC'],
                            comments: [{
                                id: 33,
                                title: 'Emily Clarke',
                                subTitle: 'Project Manager',
                                comment: 'Ammad attention to detail and commitment to excellence in software quality assurance has been vital to our project success. His skill set is a rare find in the industry.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 34,
                                title: 'David Lee',
                                subTitle: 'Product Manager',
                                comment: 'Ammad contributions to our QA team have been outstanding. His technical skills and thorough testing methodologies have consistently ensured high-quality software delivery.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        },
                        {
                            id: 3,
                            name: 'umair raza',
                            pic: '/images/our-team/umair.jpg',
                            designation: 'SQA engineer',
                            category: 'UI',
                            description: 'Umairs extensive experience guarantees rigorous testing & top-notch quality, a valuable asset to any team.',
                            skills: ['testing', 'manual', 'auotmation', 'scrum'],
                            comments: [{
                                id: 35,
                                title: 'Laura Smith',
                                subTitle: 'Head of IT',
                                comment: 'Umair role as an SQA Engineer has been crucial in elevating our software standards. His meticulous approach to testing and quality control has set new benchmarks for our team.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            },
                            {
                                id: 36,
                                title: 'Ryan Gomez',
                                subTitle: 'Senior Developer',
                                comment: 'Umair expertise in software quality assurance is impressive. His innovative testing techniques and attention to detail have significantly enhanced the reliability of our applications.',
                                stars: ['fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star', 'fa fa-star'],
                            }]
                        },

                    ]
                }
            ],
        }
    },
    methods: {
        filterDeveloper(by) {
            this.isCurrent = by;
        },
        handleDeveloper(data) {
            this.$store.dispatch("setDeveloper", data);
            this.$router.push('/hire-talent')
        }
    },
    computed: {
        filteredDevelopers() {
            if (this.isCurrent === 'full stack') {
                return this.developers.filter(developer => developer.tech === 'full stack')
            }
            if (this.isCurrent === 'mobile') {
                return this.developers.filter(developer => developer.tech === 'mobile')
            }
            if (this.isCurrent === 'ui') {
                return this.developers.filter(developer => developer.tech === 'ui')
            }
            if (this.isCurrent === 'qa') {
                return this.developers.filter(developer => developer.tech === 'qa')
            }
            return this.developers
        }
    },
    components: { CommonBanner, ContactUs }
}
</script>

<style  scoped>
.container {
    width: 93%;
    margin: 0px auto;
}

.faq-flex {
    width: 100%;
    display: block;
}

.faq-flex .question {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question strong {
    font-size: 28px;
    line-height: 38px;
    font-weight: 500;
    color: #fff;
    margin-right: 30px;
}

.faq-flex .answer {
    font-size: 18px !important;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
    margin-right: 150px;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    padding: 10px 100px;
    flex-direction: column;
}

.flex h1 {
    font-size: 40px;
    text-transform: capitalize;
    font-weight: 700;
    line-height: 65px;
}

.flex p {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
}

p span {
    color: #00b398;
}



.step-boxes {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.step-boxes .arrow-content {
    display: flex;
    justify-content: start;
    align-items: center;
}

.step-boxes .box {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00b398;
    border-radius: 50%;
    color: #fff;
    font-weight: 700;
    font-size: 32px;
}

.step-boxes .arrow {
    border: solid #fff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
}



.step-boxes .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.step-boxes p {

    width: 230px;
    height: 2px;
    background-color: #fff;
    margin-top: 10px;
    display: inline-block;
}

.three-step-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.three-step-content .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    border: 1px solid black;
    border-radius: 15px;
    transition: all .3s ease-in-out;
}

.content .box {
    display: none;
}

.content h2 {
    margin: 10px 0;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    line-height: 28.5px;
    text-transform: capitalize;
}

.content p {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    text-align: center;
}

.base-color {
    color: #00b398;
}

.flex-btn {
    width: 90%;
    margin: 30px auto;
    padding: 5px 5px;
    border-radius: 12px;
    background-color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list-move {
    transition: all 1s ease;
}

.flex-btn .btn {
    margin: 0 !important;
    display: inline-block;
    width: 220px;
    outline: none;
    border: none;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    color: #fff;
    border-radius: 12px;
    background-color: transparent;
    padding: 15px 0;
    transition: all .5s ease-in;
}

.btn.active {
    background-color: #00b398 !important;
}

.heading-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.heading-flex h2 {
    font-size: 40px;
    text-transform: capitalize;
    font-weight: 500;
}

.heading-flex .h-line {
    width: 350px;
    height: 4px;
    background-color: #00b398;
}

.developer-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    margin: 20px 0;
    justify-content: center;
    gap: 30px;
}



.developer-grid .developer-content {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.developer-content .img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    padding: 5px;
    border-radius: 50%;
    border: 3px solid #00b398;
    margin-bottom: 15px;
}

.img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.developer-content .heading {
    margin: 0 !important;
    font-size: 24px;
    font-family: sans-serif;
    line-height: 24px;
    text-transform: capitalize;
    font-weight: 700;
    letter-spacing: normal;
    padding: 0 !important;
    color: #00b398;
}

.developer-content .sub-heading {
    font-size: 18px;
    margin: 2px 0 !important;
    padding: 0 !important;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: normal;
    color: #fff;
}

.developer-content p {
    letter-spacing: normal;
    text-align: center;
    font-weight: 400;
    line-height: 21px;
    font-size: 14px;
}

.developer-content .skills-flex {
    display: inline-block;
    text-transform: capitalize !important;
}

.skills-flex .pills {
    padding: 8px 12px;
    text-transform: capitalize;
    border-radius: 3px;
    background-color: #006b5b;
    margin-right: 10px;
    text-align: center;
    color: #fff;
    font-size: 12px;

}

.developer-content .btn-block {
    outline: none;
    border: none;
    background-color: #00b398;
    border-radius: 7px;
    padding: 11px 0;
    width: 100%;
    color: #fff;
    text-transform: capitalize;
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin: 20px 0;
}

@media (max-width: 768px) {

    .question strong {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        color: #fff;
        margin-right: 15px;
    }

    .faq-flex .answer {
        font-size: 12px !important;
        font-weight: 400;
        line-height: 19px;
        color: #fff;
        margin-right: 10px;
    }

    .flex {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        padding: 10px;
        flex-direction: column;
    }

    .flex h1 {
        font-size: 32px !important;
        text-transform: capitalize;
        font-weight: 700;
        line-height: 43px;
    }

    .flex p {
        font-size: 14px !important;
        line-height: 21px;
        text-align: center;
        font-weight: 400;
    }

    p span {
        color: #00b398;
    }

    .step-boxes {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .step-boxes {
        display: none;
    }

    .three-step-content {
        width: 100%;
        padding: 10px;
        flex-direction: column;
    }

    .content .box {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00b398;
        border-radius: 50%;
        color: #fff;
        font-weight: 700;
        font-size: 32px;

    }

    .three-step-content .content h2 {
        font-size: 20px !important;
        line-height: 36px;
    }

    .content p {
        font-size: 12px !important;
        line-height: 22px;
        font-weight: 400;
    }

    .flex-btn {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    .flex-btn .btn {
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        padding: 12px 0 !important;
        margin-bottom: 0 !important;
    }

    .developer-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        place-items: center !important;

    }

    .developer-content:last-child {
        margin-left: 100%;

    }

    .flex h1 {
        font-weight: 700;
        font-size: 20px !important;
        line-height: 30px;
        text-align: center;
    }

    .developer-content .heading {
        font-size: 20px !important;
        font-weight: 700;
        line-height: 21px;
    }

    .developer-content .sub-heading {
        font-size: 16px !important;
        font-weight: 500;
        line-height: 20px;
    }

    .developer-content p {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
    }

    .heading-flex h2 {
        font-size: 32px;
        line-height: 42px;
    }

    .skills-flex .pills {
        font-size: 9px;
    }
}

@media (max-width: 480px) {

    .question strong {
        font-size: 14px;
        line-height: 19px;
        font-weight: 500;
        color: #fff;
        margin-right: 5px;
    }

    .faq-flex .answer {
        font-size: 12px !important;
        font-weight: 400;
        line-height: 16px;
        color: #fff;
        margin-right: 5px;
    }

    .flex {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        padding: 10px;
        flex-direction: column;
    }

    .flex h1 {
        font-size: 18px;
        text-transform: capitalize;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
    }

    .flex p {
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        font-weight: 400;
    }

    p span {
        color: #00b398;
    }

    .step-boxes {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .step-boxes {
        display: none;
    }

    .three-step-content {
        width: 100%;
        padding: 10px;
        flex-direction: column;
    }

    .content .box {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00b398;
        border-radius: 50%;
        color: #fff;
        font-weight: 700;
        font-size: 24px;

    }

    .developer-content:last-child {
        margin-left: 0px !important;
    }

    .three-step-content .content h2 {
        font-size: 20px;
        line-height: 26px;
    }

    .content p {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
    }

    .heading-flex {
        width: 100%;
    }

    .heading-flex h2 {
        font-size: 20px !important;
        text-align: center;
        line-height: 22px;
    }

    .heading-flex .h-line {
        width: 250px;
    }

    .flex-btn {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        border-radius: 8px;
    }

    .flex-btn .btn {
        width: 120px;
        font-size: 10px;
        font-weight: 300;
        padding: 5px 10px;
        border-radius: 8px;
        margin-bottom: 0 !important;
    }

    .developer-grid {
        width: 100%;
        gap: 10px;
        grid-template-columns: repeat(1, 1fr);
    }

    .developer-content .heading {
        margin-top: 10px !important;
        font-size: 20px;
        font-weight: 700;
        line-height: 21px;
    }

    .developer-content .sub-heading {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
    }

    .developer-content p {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
    }

    .heading-flex h2 {
        font-size: 32px;
        line-height: 42px;
    }

    .skills-flex .pills {
        font-size: 9px;

        text-align: center;
        text-transform: capitalize !important;
    }

    @media (min-width: 1500px) {
        .skills-flex .pills {
            padding: 10px 20px !important;
        }
    }
}
</style>