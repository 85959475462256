<template>
    <section>
        <div v-if=" isLoading " class="loading">Loading&#8230;</div>
        <div class="portfolio-container">
            <div class="row my">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="left-flex">
                        <div class="img">
                            <img :src=" getDeveloper.pic " alt="">
                        </div>
                        <h1 class="heading"><span class="style">hire</span> {{ getDeveloper.name }}</h1>
                        <p>
                            {{ getDeveloper.description }}
                        </p>
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-lg-12">
                                <VueSlickCarousel v-bind=" settings " class=" testimonial-container slik-slider">
                                    <div v-for="                                                                 review                                                                  in                                                                  getDeveloper.comments                                                                 "
                                        :key=" review.id ">
                                        <div class="review-flex">
                                            <div class="header">
                                                <h3 class="title">
                                                    {{ review.title }}
                                                </h3>
                                                <span class="vertical-line"> | </span>
                                                <h4 class="sub-title">
                                                    {{ review.subTitle }}
                                                </h4>
                                                <span class="stars"
                                                    v-for="(                                                                 star, index                                                                 ) in                                                                  review.stars                                                                 "
                                                    :key=" index ">
                                                    <i :class=" star "></i>
                                                </span>
                                                <span class="length">{{ review.stars.length }}.0</span>
                                            </div>
                                            <span class="description">
                                                {{ review.comment }}
                                            </span>
                                        </div>
                                    </div>
                                </VueSlickCarousel>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="right-flex">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <label>first name</label>
                                <input :class=" { error_input: $v.firstName.$error } " type="text" v-model=" firstName "
                                    @blur="$v.firstName.$touch()">
                                <p v-if=" $v.firstName.$error " class="error">first name is required</p>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label>last name</label>
                                <input type="text" :class=" { error_input: $v.lastName.$error } "
                                    @blur="$v.lastName.$touch()" v-model=" lastName ">
                                <p v-if=" $v.lastName.$error " class="error">last name is required</p>
                            </div>
                            <div class="col-md-12">
                                <label> email address</label>
                                <input type="email" @blur="$v.email.$touch()" :class=" { error_input: $v.email.$error } "
                                    v-model=" email ">
                                <p v-if=" $v.email.$error " class="error">email is required</p>
                            </div>
                            <div class="col-md-12">
                                <label> website <span>(optional)</span></label>
                                <input type="text" v-model=" website ">
                            </div>
                            <div class="col-md-12">
                                <label> mobile number</label>
                                <vue-tel-input type="text" @blur="$v.mobileNumber.$touch()"
                                    :class=" { error_input: $v.mobileNumber.$error } " v-model=" mobileNumber ">
                                </vue-tel-input>
                                <p v-if=" $v.mobileNumber.$error " class="error">Mobile number is required</p>
                            </div>
                            <div class="col-md-12">
                                <label> project description</label>
                                <textarea v-model=" description " @blur="$v.description.$touch"
                                    :class=" { error_input: $v.description.$error } "></textarea>
                                <p v-if=" $v.description.$error " class="error">project description is required</p>
                            </div>
                            <div class="col-md-12">
                                <button type="submit" @click=" sendEmail()" class="btn">submit</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row contact-footer-container">
                <div class="col-sm-12 col-lg-4">
                    <h1>Contact Information</h1>
                    <div class="contact-left-container">
                        <span class="contact-icon">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="23.3411" height="24.0318" transform="translate(0 0.5)" fill="#0A0A0A" />
                                <path
                                    d="M10.2647 6.74859L6.97458 2.83841C6.59529 2.38781 5.89992 2.38981 5.45935 2.84441L2.75373 5.63511C1.94846 6.46521 1.71796 7.69785 2.18381 8.68615C4.96687 14.619 9.61412 19.4105 15.3725 22.2842C16.3315 22.7638 17.5277 22.5265 18.333 21.6964L21.0639 18.8797C21.5064 18.4241 21.5074 17.7041 21.0658 17.3136L17.2534 13.9441C16.8547 13.5917 16.2352 13.6377 15.8355 14.0503L14.5089 15.4181C14.441 15.4914 14.3516 15.5397 14.2544 15.5556C14.1573 15.5715 14.0577 15.5542 13.9711 15.5062C11.8027 14.2206 10.0041 12.3663 8.75823 10.1321C8.71158 10.0427 8.69468 9.94004 8.71014 9.83984C8.7256 9.73964 8.77256 9.64742 8.84382 9.57734L10.1665 8.21453C10.5672 7.79998 10.6109 7.15913 10.2647 6.74759V6.74859Z"
                                    stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                        <a href="tel: +92-42-38347751" target="_blank">+92-42-38347751</a>
                    </div>
                    <div class="contact-left-container">
                        <span class="contact-icon">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="23.3411" height="24.0318" transform="translate(0 0.560547)" fill="#0A0A0A" />
                                <path
                                    d="M13.859 18.5843H2.91781L2.91562 7.2488L11.2557 13.1937C11.3777 13.2806 11.5224 13.3271 11.6707 13.3271C11.819 13.3271 11.9638 13.2806 12.0858 13.1937L20.4237 7.2518V14.0783H21.8825V6.5684C21.8819 6.17023 21.728 5.78854 21.4546 5.50699C21.1811 5.22544 20.8104 5.067 20.4237 5.06641H2.91781C2.53102 5.0668 2.16019 5.22518 1.88669 5.50677C1.61319 5.78836 1.45937 6.17017 1.45898 6.5684V18.5843C1.45956 18.9825 1.61345 19.3642 1.8869 19.6457C2.16036 19.9273 2.53108 20.0857 2.91781 20.0863H13.859V18.5843ZM18.8182 6.5684L11.6707 11.6631L4.52324 6.5684H18.8182Z"
                                    fill="white" />
                                <path
                                    d="M18.9645 21.588C20.5759 21.588 21.8822 20.2431 21.8822 18.5841C21.8822 16.925 20.5759 15.5801 18.9645 15.5801C17.3531 15.5801 16.0469 16.925 16.0469 18.5841C16.0469 20.2431 17.3531 21.588 18.9645 21.588Z"
                                    fill="white" />
                            </svg>
                        </span>
                        <a href="mailto:info@septemsystems.com">info@septemsystems.com</a>
                    </div>
                    <div class="contact-left-container">
                        <span class="contact-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <rect width="23.3411" height="24.0318" transform="translate(0.269531 0.298828)"
                                    fill="#0A0A0A" />

                                <path d="M11.9411 13.3146C13.5524 13.3146 14.8587
   11.9697 14.8587 10.3106C14.8587 8.65157 13.5524
    7.30664 11.9411 7.30664C10.3297 7.30664 9.02344
     8.65157 9.02344 10.3106C9.02344 11.9697 10.3297
      13.3146 11.9411 13.3146Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.9405 2.30078C9.87705
   2.30078 7.89808 3.14475 6.43898 4.64704C4.97987
    6.14932 4.16016 8.18685 4.16016 10.3114C4.16016
     12.2059 4.55112 13.4455 5.61898 14.8174L11.9405
      22.3273L18.2621 14.8174C19.3299 13.4455 19.7209
       12.2059 19.7209 10.3114C19.7209 8.18685 18.9012
        6.14932 17.4421 4.64704C15.983 3.14475 14.004
         2.30078 11.9405 2.30078V2.30078Z" stroke="white" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </span>
                        <P><a href="https://goo.gl/maps/QTSJ9AKSbEEPfBAR8" target="_blank">
                                3rd Floor, Crown Complex , 31 Queens Road, Lahore, Pakistan</a></P>
                    </div>
                    <hr class="divider">
                    <div class="contact-left-container">
                        <span class="contact-icon">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="23.3411" height="24.0318" transform="translate(0 0.5)" fill="#0A0A0A" />
                                <path
                                    d="M10.2647 6.74859L6.97458 2.83841C6.59529 2.38781 5.89992 2.38981 5.45935 2.84441L2.75373 5.63511C1.94846 6.46521 1.71796 7.69785 2.18381 8.68615C4.96687 14.619 9.61412 19.4105 15.3725 22.2842C16.3315 22.7638 17.5277 22.5265 18.333 21.6964L21.0639 18.8797C21.5064 18.4241 21.5074 17.7041 21.0658 17.3136L17.2534 13.9441C16.8547 13.5917 16.2352 13.6377 15.8355 14.0503L14.5089 15.4181C14.441 15.4914 14.3516 15.5397 14.2544 15.5556C14.1573 15.5715 14.0577 15.5542 13.9711 15.5062C11.8027 14.2206 10.0041 12.3663 8.75823 10.1321C8.71158 10.0427 8.69468 9.94004 8.71014 9.83984C8.7256 9.73964 8.77256 9.64742 8.84382 9.57734L10.1665 8.21453C10.5672 7.79998 10.6109 7.15913 10.2647 6.74759V6.74859Z"
                                    stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                        <a href="tel: +1(512)394-8699" target="_blank">+1 (512) 394-8699</a>

                    </div>
                    <div class="contact-left-container">
                        <span class="contact-icon">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="23.3411" height="24.0318" transform="translate(0 0.560547)" fill="#0A0A0A" />
                                <path
                                    d="M13.859 18.5843H2.91781L2.91562 7.2488L11.2557 13.1937C11.3777 13.2806 11.5224 13.3271 11.6707 13.3271C11.819 13.3271 11.9638 13.2806 12.0858 13.1937L20.4237 7.2518V14.0783H21.8825V6.5684C21.8819 6.17023 21.728 5.78854 21.4546 5.50699C21.1811 5.22544 20.8104 5.067 20.4237 5.06641H2.91781C2.53102 5.0668 2.16019 5.22518 1.88669 5.50677C1.61319 5.78836 1.45937 6.17017 1.45898 6.5684V18.5843C1.45956 18.9825 1.61345 19.3642 1.8869 19.6457C2.16036 19.9273 2.53108 20.0857 2.91781 20.0863H13.859V18.5843ZM18.8182 6.5684L11.6707 11.6631L4.52324 6.5684H18.8182Z"
                                    fill="white" />
                                <path
                                    d="M18.9645 21.588C20.5759 21.588 21.8822 20.2431 21.8822 18.5841C21.8822 16.925 20.5759 15.5801 18.9645 15.5801C17.3531 15.5801 16.0469 16.925 16.0469 18.5841C16.0469 20.2431 17.3531 21.588 18.9645 21.588Z"
                                    fill="white" />
                            </svg>
                        </span>
                        <a href="mailto:info@septemsystems.com">info@septemsystems.com</a>
                    </div>
                    <div class="contact-left-container">
                        <span class="contact-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <rect width="23.3411" height="24.0318" transform="translate(0.269531 0.298828)"
                                    fill="#0A0A0A" />

                                <path d="M11.9411 13.3146C13.5524 13.3146 14.8587
   11.9697 14.8587 10.3106C14.8587 8.65157 13.5524
    7.30664 11.9411 7.30664C10.3297 7.30664 9.02344
     8.65157 9.02344 10.3106C9.02344 11.9697 10.3297
      13.3146 11.9411 13.3146Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.9405 2.30078C9.87705
   2.30078 7.89808 3.14475 6.43898 4.64704C4.97987
    6.14932 4.16016 8.18685 4.16016 10.3114C4.16016
     12.2059 4.55112 13.4455 5.61898 14.8174L11.9405
      22.3273L18.2621 14.8174C19.3299 13.4455 19.7209
       12.2059 19.7209 10.3114C19.7209 8.18685 18.9012
        6.14932 17.4421 4.64704C15.983 3.14475 14.004
         2.30078 11.9405 2.30078V2.30078Z" stroke="white" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </span>
                        <P><a href="https://goo.gl/maps/qCbBQSJ4T26CEbW69" target="_blank">
                                6001 W Parmer Lane Ste 370 #977,
                                Austin, TX, USA 78727</a></P>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-8 contact-right-container">
                    <img src="/contact/contact.svg" alt="" width="100%" />
                </div>
            </div>

        </div>
        <CompanyStats />

    </section>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import CompanyStats from "./common/CompanyStats.vue";
import VueSlickCarousel from "vue-slick-carousel";
import { required } from "vuelidate/lib/validators";
export default {
    name: 'Contact',
    computed: {

        getDeveloper() {
            return this.$store.getters.getDeveloper;
        }

    },
    data() {

        return {
            isLoading: false,
            firstName: '',
            lastName: '',
            email: '',
            website: '',
            mobileNumber: '',
            description: '',
            settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 3000,
                cssEase: "linear",
                responsive: [

                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            initialSlide: 0,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            initialSlide: 0,
                        },
                    },
                ],
            },
        }
    },
    validations: {
        firstName: { required },
        lastName: { required },
        email: { required },
        mobileNumber: { required },
        description: { required }
    },
    methods: {
        async sendEmail() {
            this.$v.$touch();

            if (this.$v.$pending || this.$v.$error) {
                console.log('%cContactUs.vue line:43 "here"', "color: #007acc;", "here");
                return;
            }
            if (!(/^[a-z A-Z a-z A-Z]+$/.test(this.firstName))) {
                this.$toast.error("First Name field must be contain letters only")
                return
            }
            if (!(/^[a-z A-Z a-z A-Z]+$/.test(this.lastName))) {
                this.$toast.error("Last Name field must be contain letters only")
                return
            }
            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))) {
                this.$toast.error('Invalid email format!')
                return
            }
            this.isLoading = true
            let data = {
                name: this.firstName + " " + this.lastName,
                email: this.email,
                websiteLink: this.website,
                mobileNumber: this.mobileNumber,
                projectDes: this.description,
                developer: this.$store.getters.getDeveloper.name

            }

            try {
                await this.$axios.post(`hire-talent`, data);
                this.$toast.success("Your message has been sent successfully!");
                this.firstName = "";
                this.lastName = "";
                this.email = "";
                this.website = "";
                this.mobileNumber = "";
                this.description = "";
                await this.$router.push("/");

            }
            catch (error) {
                console.log(error.message);
                this.$toast.error("Something went wrong.");
            }

            this.isLoading = false;

        }
    },
    components: {
        VueSlickCarousel,
        VueTelInput,
        CompanyStats,
    },
}
</script>

<style scoped>
.contact-footer-container {
    padding: 100px 85px;
}

.contact-footer-container h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 133%;
    color: #00b197;
}

.contact-left-container {
    display: flex;
    align-items: center;
}

.contact-left-container p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 156%;
    color: #ffffff;
}

.contact-left-container a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 156%;
    color: #ffffff;
}

.contact-left-container a:hover {
    color: #00b197;
}

.contact-right-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-form-container {
    padding: 50px 85px;
}

.btn:hover {
    color: #fff;
    background: #007cb7;
}

.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.loading:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

    background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8),
            rgba(0, 0, 0, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.loading:not(:required):after {
    content: "";
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 150ms infinite linear;
    -moz-animation: spinner 150ms infinite linear;
    -ms-animation: spinner 150ms infinite linear;
    -o-animation: spinner 150ms infinite linear;
    animation: spinner 150ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
        rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
        rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
        rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
        rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
        rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
        rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
        rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
        rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
        rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
        rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
        rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
        rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
        rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
        rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.col-sm-12 .col-md-12 .col-lg-5 {
    padding: 0 !important;
}

.description {
    color: #fff !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
    font-family: Nunito !important;
}

.my {
    margin: 50px 0 !important;
}

.left-flex {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start !important;
}

.left-flex .img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    padding: 5px;
    border: 3px solid #00B398;
}

.img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.left-flex .heading {
    font-family: Nunito !important;
    text-transform: capitalize;
    color: #fff;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 40px;
    font-weight: 700;
    line-height: 64px;
}

.heading .style {
    color: #00B398;
}

.left-flex p {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 24px;
}

.right-flex {
    width: 100%;
    padding: 25px;
    border-radius: 10px;
    background-color: #131313;
}

.right-flex label {
    display: block;
    font-size: 18px;
    line-height: 23px;
    font-weight: 600;
    color: #fff;
    text-transform: capitalize;
    margin: 10px 0;

}

.right-flex input {
    display: block;
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    forced-color-adjust: none;
    border-bottom: 1px solid #454546;
    padding: 10px;
    background-color: transparent;
}

.right-flex textarea {
    display: block;
    width: 100%;
    height: 250px;
    outline: none;
    forced-color-adjust: none;
    border: 1px solid #454546;
    padding: 10px;
    border-radius: 10px;
    background-color: transparent;
}

.btn {
    display: block;
    width: 100%;
    padding: 12px 0;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    background-color: #00B398;
    border-radius: 10px;
    border: none;
    outline: none;
    text-transform: capitalize;
    margin: 20px 0;
}

label span {
    color: #454546;
    font-size: 10px;
}

.slik-slider {
    overflow: hidden;
}

::v-deep .slick-slide:nth-child(odd) {
    display: flex;
    justify-content: flex-end;
    padding-right: 0px !important;
}

::v-deep .slick-slide:nth-child(even) {
    display: flex;
    justify-content: flex-start;
    padding-left: 0px !important;
}

::v-deep .vti__input {
    background-color: transparent;
}

.review-flex {
    margin: 0 !important;
    width: 100% !important;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #131313;
    padding: 30px 20px;
    border-radius: 10px;
    color: #fff;
}

.review-flex .header {
    display: flex;
    width: 100%;
    font-family: Nunito;
    justify-content: flex-start;
    align-items: center;
}

.header .title {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    text-transform: capitalize;
    color: #fff;
    letter-spacing: normal;
    margin: 0 !important;
}

.header .sub-title {
    padding: 0 5px;
    font-size: 14px;
    letter-spacing: normal;
    font-weight: 300;
    text-transform: capitalize;
}

.header .vertical-line {
    padding-left: 5px !important;
    color: #454546;
    font-weight: 400;

}

.header .stars i {
    margin: 0 5px !important;
    color: #F3CD03 !important;
}

.header .description {
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: normal;
    color: #fff !important;
}

.header .length {
    color: #F3CD03;
    font-weight: 200;
}

.contact-footer-container h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 133%;
    color: #00b197;
}

.error_input {
    border: 1px solid #ff014f !important;
}

.error {
    color: #ff014f;
    font-size: 12px;
    text-transform: uppercase;
    margin: 10px 0;
}

@media (min-width: 1024px) and (max-width: 1390px) {
    .header .title {
        font-size: 14px;
    }

    .header .sub-title {
        font-size: 12px;
    }

    .header .description {
        font-size: 10;
    }


}

@media (max-width: 768px) {
    .left-flex .img {
        align-self: center !important;
    }

    .left-flex .heading {
        text-align: center;
        font-size: 32px;
        line-height: 43px;
    }

    .left-flex p {
        text-align: center;
        font-size: 14px;
        line-height: 22px;
    }

    .right-flex textarea {
        height: 180px !important;
    }

    .left-flex .img {
        width: 174px;
        height: 174px;
    }

}

@media (max-width: 480px) {
    .left-flex .heading {
        font-size: 24px;
        line-height: 32px;
    }

    .left-flex .img {
        width: 144px;
        height: 144px;
    }

    .header .title {
        font-size: 12px;
        line-height: 15px;
    }

    .header .sub-title {
        font-size: 11px;
        display: none;
    }

    .header.description {
        font-size: 10px;
        line-height: 12px;
        text-align: left !important;
    }

    .right-flex label {
        font-size: 12px;
        line-height: 15px;
    }

    .right-flex textarea {
        height: 150px !important;
    }

}
</style>