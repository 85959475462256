<template>
  <section>
    <div v-if="isLoading" class="loading">Loading&#8230;</div>

    <!--body content start-->

    <CommonBanner
      styleHeading="We are here to"
      heading="Help!"
      paragraph="Describe your project – like scope, timeframes, or business challenges you’d like to solve. We can then connect to figure out the next move together."
    />

    <!--page title end-->

    <!--body content start-->

    <div class="row contact-form-container">
      <!-- <div class="col-sm-12 input-heading">
        <h3>LEAVE US A MESSAGE</h3>
      </div> -->
      <div class="col-sm-6">
        <div class="border">
          <div class="row">
            <div class="col-sm-1">
              <!--                <img class="name-icon" src="assets/name.png" />-->
              <i class="fa fa-user-o fa-icon"></i>
            </div>
            <div class="col-sm-11">
              <div class="col-sm-12">
                <input
                  class="border input"
                  v-model="$v.name.$model"
                  type="textbox"
                  placeholder="Name"
                />

                <!-- <input  class="border" type="text"  name="fname" value="John"> -->
              </div>
            </div>
          </div>
        </div>
        <p style="color: red" v-if="$v.name.$error">Name is required.</p>
      </div>

      <div class="col-sm-6">
        <div class="border">
          <div class="row">
            <div class="col-sm-1">
              <!--                <img class="name-icon" src="assets/mail.png" />-->
              <i class="fa fa-envelope-o fa-icon"></i>
            </div>
            <div class="col-sm-11">
              <div class="col-sm-12">
                <input
                  class="border input"
                  v-model="$v.email.$model"
                  type="textbox"
                  placeholder="Email"
                />
              </div>
            </div>
          </div>
        </div>
        <p style="color: red" v-if="$v.email.$error">Email is required.</p>
      </div>

      <div class="col-sm-6">
        <div class="border">
          <div class="row">
            <div class="col-sm-1">
              <!--                <img class="name-icon" src="assets/contact.png" />-->
              <i class="fa fa-phone fa-icon"></i>
            </div>
            <div class="col-sm-11">
              <div class="col-sm-12">
                <input
                  class="border input"
                  v-model="phone"
                  type="textbox"
                  placeholder="Phone"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="border">
          <div class="row">
            <div class="col-sm-1">
              <!--                <img class="name-icon" src="assets/website.png" />-->
              <i class="fa fa-globe fa-icon"></i>
            </div>
            <div class="col-sm-11">
              <div class="col-sm-12">
                <input
                  class="input"
                  v-model="website"
                  type="textbox"
                  placeholder="Website"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12">
        <textarea
          class="border input border-padding"
          v-model="$v.message.$model"
          type="textbox"
          placeholder="Enter Your Message"
          rows="10"
        >
        </textarea>
        <p style="color: red" v-if="$v.message.$error">Message is required.</p>
      </div>
      <div class="col-sm-12">
        <vue-recaptcha
          sitekey="6Lcs-UAkAAAAACcjg8siFFbJem9-1PCp43Gm0oTO"
          ref="recaptcha"
          @verify="onVerifyReCaptcha"
          @expired="onExpiredReCaptcha"
        ></vue-recaptcha>
      </div>
      <div class="col-sm-12"></div>
      <div class="col-sm-12">
        <div class="contact-btn">
          <div v-if="isLoading" class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <a
            @click="sendMail()"
            class="btn btn-medium btn-rounded btn-custom-solid"
            >Submit
          </a>
        </div>
        <!-- <button class="send-button" type="button" @click="sendMail()">
            SEND MESSAGE
          </button> -->
      </div>
    </div>

    <div class="row contact-footer-container">
      <div class="col-sm-12 col-lg-4">
        <h1>Contact Information</h1>
        <div class="contact-left-container">
          <span class="contact-icon">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="23.3411"
                height="24.0318"
                transform="translate(0 0.5)"
                fill="#0A0A0A"
              />
              <path
                d="M10.2647 6.74859L6.97458 2.83841C6.59529 2.38781 5.89992 2.38981 5.45935 2.84441L2.75373 5.63511C1.94846 6.46521 1.71796 7.69785 2.18381 8.68615C4.96687 14.619 9.61412 19.4105 15.3725 22.2842C16.3315 22.7638 17.5277 22.5265 18.333 21.6964L21.0639 18.8797C21.5064 18.4241 21.5074 17.7041 21.0658 17.3136L17.2534 13.9441C16.8547 13.5917 16.2352 13.6377 15.8355 14.0503L14.5089 15.4181C14.441 15.4914 14.3516 15.5397 14.2544 15.5556C14.1573 15.5715 14.0577 15.5542 13.9711 15.5062C11.8027 14.2206 10.0041 12.3663 8.75823 10.1321C8.71158 10.0427 8.69468 9.94004 8.71014 9.83984C8.7256 9.73964 8.77256 9.64742 8.84382 9.57734L10.1665 8.21453C10.5672 7.79998 10.6109 7.15913 10.2647 6.74759V6.74859Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <a href="tel: +92-42-38347751" target="_blank">+92-42-38347751</a>
        </div>
        <div class="contact-left-container">
          <span class="contact-icon">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="23.3411"
                height="24.0318"
                transform="translate(0 0.560547)"
                fill="#0A0A0A"
              />
              <path
                d="M13.859 18.5843H2.91781L2.91562 7.2488L11.2557 13.1937C11.3777 13.2806 11.5224 13.3271 11.6707 13.3271C11.819 13.3271 11.9638 13.2806 12.0858 13.1937L20.4237 7.2518V14.0783H21.8825V6.5684C21.8819 6.17023 21.728 5.78854 21.4546 5.50699C21.1811 5.22544 20.8104 5.067 20.4237 5.06641H2.91781C2.53102 5.0668 2.16019 5.22518 1.88669 5.50677C1.61319 5.78836 1.45937 6.17017 1.45898 6.5684V18.5843C1.45956 18.9825 1.61345 19.3642 1.8869 19.6457C2.16036 19.9273 2.53108 20.0857 2.91781 20.0863H13.859V18.5843ZM18.8182 6.5684L11.6707 11.6631L4.52324 6.5684H18.8182Z"
                fill="white"
              />
              <path
                d="M18.9645 21.588C20.5759 21.588 21.8822 20.2431 21.8822 18.5841C21.8822 16.925 20.5759 15.5801 18.9645 15.5801C17.3531 15.5801 16.0469 16.925 16.0469 18.5841C16.0469 20.2431 17.3531 21.588 18.9645 21.588Z"
                fill="white"
              />
            </svg>
          </span>
          <a href="mailto:info@septemsystems.com">info@septemsystems.com</a>
        </div>
        <div class="contact-left-container">
          <span class="contact-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <rect
                width="23.3411"
                height="24.0318"
                transform="translate(0.269531 0.298828)"
                fill="#0A0A0A"
              />

              <path
                d="M11.9411 13.3146C13.5524 13.3146 14.8587
   11.9697 14.8587 10.3106C14.8587 8.65157 13.5524
    7.30664 11.9411 7.30664C10.3297 7.30664 9.02344
     8.65157 9.02344 10.3106C9.02344 11.9697 10.3297
      13.3146 11.9411 13.3146Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.9405 2.30078C9.87705
   2.30078 7.89808 3.14475 6.43898 4.64704C4.97987
    6.14932 4.16016 8.18685 4.16016 10.3114C4.16016
     12.2059 4.55112 13.4455 5.61898 14.8174L11.9405
      22.3273L18.2621 14.8174C19.3299 13.4455 19.7209
       12.2059 19.7209 10.3114C19.7209 8.18685 18.9012
        6.14932 17.4421 4.64704C15.983 3.14475 14.004
         2.30078 11.9405 2.30078V2.30078Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <P
            ><a href="https://goo.gl/maps/QTSJ9AKSbEEPfBAR8" target="_blank">
              3rd Floor, Crown Complex , 31 Queens Road, Lahore, Pakistan</a
            ></P
          >
        </div>
        <hr class="divider" />
        <div class="contact-left-container">
          <span class="contact-icon">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="23.3411"
                height="24.0318"
                transform="translate(0 0.5)"
                fill="#0A0A0A"
              />
              <path
                d="M10.2647 6.74859L6.97458 2.83841C6.59529 2.38781 5.89992 2.38981 5.45935 2.84441L2.75373 5.63511C1.94846 6.46521 1.71796 7.69785 2.18381 8.68615C4.96687 14.619 9.61412 19.4105 15.3725 22.2842C16.3315 22.7638 17.5277 22.5265 18.333 21.6964L21.0639 18.8797C21.5064 18.4241 21.5074 17.7041 21.0658 17.3136L17.2534 13.9441C16.8547 13.5917 16.2352 13.6377 15.8355 14.0503L14.5089 15.4181C14.441 15.4914 14.3516 15.5397 14.2544 15.5556C14.1573 15.5715 14.0577 15.5542 13.9711 15.5062C11.8027 14.2206 10.0041 12.3663 8.75823 10.1321C8.71158 10.0427 8.69468 9.94004 8.71014 9.83984C8.7256 9.73964 8.77256 9.64742 8.84382 9.57734L10.1665 8.21453C10.5672 7.79998 10.6109 7.15913 10.2647 6.74759V6.74859Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <a href="tel: +1(512)394-8699" target="_blank">+1 (512) 394-8699</a>
        </div>
        <div class="contact-left-container">
          <span class="contact-icon">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="23.3411"
                height="24.0318"
                transform="translate(0 0.560547)"
                fill="#0A0A0A"
              />
              <path
                d="M13.859 18.5843H2.91781L2.91562 7.2488L11.2557 13.1937C11.3777 13.2806 11.5224 13.3271 11.6707 13.3271C11.819 13.3271 11.9638 13.2806 12.0858 13.1937L20.4237 7.2518V14.0783H21.8825V6.5684C21.8819 6.17023 21.728 5.78854 21.4546 5.50699C21.1811 5.22544 20.8104 5.067 20.4237 5.06641H2.91781C2.53102 5.0668 2.16019 5.22518 1.88669 5.50677C1.61319 5.78836 1.45937 6.17017 1.45898 6.5684V18.5843C1.45956 18.9825 1.61345 19.3642 1.8869 19.6457C2.16036 19.9273 2.53108 20.0857 2.91781 20.0863H13.859V18.5843ZM18.8182 6.5684L11.6707 11.6631L4.52324 6.5684H18.8182Z"
                fill="white"
              />
              <path
                d="M18.9645 21.588C20.5759 21.588 21.8822 20.2431 21.8822 18.5841C21.8822 16.925 20.5759 15.5801 18.9645 15.5801C17.3531 15.5801 16.0469 16.925 16.0469 18.5841C16.0469 20.2431 17.3531 21.588 18.9645 21.588Z"
                fill="white"
              />
            </svg>
          </span>
          <a href="mailto:info@septemsystems.com">info@septemsystems.com</a>
        </div>
        <div class="contact-left-container">
          <span class="contact-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <rect
                width="23.3411"
                height="24.0318"
                transform="translate(0.269531 0.298828)"
                fill="#0A0A0A"
              />

              <path
                d="M11.9411 13.3146C13.5524 13.3146 14.8587
   11.9697 14.8587 10.3106C14.8587 8.65157 13.5524
    7.30664 11.9411 7.30664C10.3297 7.30664 9.02344
     8.65157 9.02344 10.3106C9.02344 11.9697 10.3297
      13.3146 11.9411 13.3146Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.9405 2.30078C9.87705
   2.30078 7.89808 3.14475 6.43898 4.64704C4.97987
    6.14932 4.16016 8.18685 4.16016 10.3114C4.16016
     12.2059 4.55112 13.4455 5.61898 14.8174L11.9405
      22.3273L18.2621 14.8174C19.3299 13.4455 19.7209
       12.2059 19.7209 10.3114C19.7209 8.18685 18.9012
        6.14932 17.4421 4.64704C15.983 3.14475 14.004
         2.30078 11.9405 2.30078V2.30078Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <P
            ><a href="https://goo.gl/maps/qCbBQSJ4T26CEbW69" target="_blank">
              6001 W Parmer Lane Ste 370 #977, Austin, TX, USA 78727</a
            ></P
          >
        </div>
      </div>
      <div class="col-sm-12 col-lg-8 contact-right-container">
        <img src="/contact/contact.svg" alt="" width="100%" />
      </div>
    </div>

    <CompanyStats />
    <Testimonials />
  </section>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { required } from "vuelidate/lib/validators";
import CommonBanner from "./common/CommonBanner.vue";
import CompanyStats from "./common/CompanyStats.vue";
import Testimonials from "./Testimonials.vue";

export default {
  name: "Contact",
  components: { VueRecaptcha, CommonBanner, CompanyStats, Testimonials },
  mounted() {
    this.getQuote();
  },
  data() {
    return {
      isLoading: false,
      fullPage: false,
      loader: "bars",
      name: "",
      email: "",
      phone: "",
      website: "",
      message: "",
      captchaToken: "",
      captchaVerified: false,
      feedback: "",
    };
  },
  validations: {
    email: { required },
    name: { required },
    message: { required },
  },
  computed: {
    quoteVal() {
      return this.$store.getters.getQuote;
    },
  },
  destroyed() {
    this.$store.commit("RESET_QUOTE", false);
  },
  methods: {
    onVerifyReCaptcha(response) {
      console.log("Verified");
      this.captchaToken = response;
      this.captchaVerified = true;
      // this.sendMail();
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },
    onExpiredReCaptcha() {
      console.log("Captcha Expire");
      this.captchaVerified = false;
    },
    solveReCaptcha() {
      this.feedback = null;
      this.$refs.recaptcha.execute();
    },
    async sendMail() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) {
        console.log('%cContact.vue line:259 "here"', "color: #007acc;", "here");
        return;
      }
      if (!this.captchaVerified) {
        this.$toast.error("Before you proceed, please solve the captcha!");
        return;
      }
      this.isLoading = true;
      const data = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        website: this.website,
        message: this.message,
        recaptcha: this.captchaToken,
      };
      try {
        await this.$axios.post(`contact`, data);
        this.$toast.success("Your message has been sent successfully!");
        this.$refs.recaptcha.reset();
        await this.$router.push("/");
      } catch (e) {
        this.$toast.error("Something went wrong.");
      }
      this.isLoading = false;
    },
    getQuote() {
      if (this.quoteVal) {
        window.scrollTo({
          top: 350,
          behavior: "smooth",
        });
      } else {
        window.scroll(0, 0);
      }
    },
  },
};
</script>

<style scoped>
.input-heading {
  font-size: 24px;
  font-family: "Nunito";
  text-align: center;
}
.input-heading h3 {
  font-weight: 600;
  margin: 0px;
  padding-bottom: 30px;
  color: #00b197;
}
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.contact-footer-container {
  padding: 100px 85px;
}
.contact-footer-container h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 133%;
  color: #00b197;
}
.contact-left-container {
  display: flex;
  align-items: center;
}
.contact-left-container p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: #ffffff;
}
.contact-left-container a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: #ffffff;
}
.contact-left-container a:hover {
  color: #00b197;
}
.contact-right-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-form-container {
  padding: 50px 85px;
}
.btn:hover {
  color: #fff;
  background: #007cb7;
}
@media only screen and (max-width: 768px) {
  .contact-right-container {
    margin: 100px 0 10px 0;
  }
}
@media only screen and (max-width: 480px) {
  .contact-footer-container {
    padding: 0px 15px;
  }
  .contact-form-container {
    padding: 0px 15px;
  }
  .contact-footer-container h1 {
    font-size: 24px;
  }
  .contact-left-container a {
    font-size: 13px;
  }
  .contact-right-container {
    margin: 50px 0;
  }
}
</style>
