const INITIAL_STATE = {
  user: null,
  isLogged: false,
};

export const state = { ...INITIAL_STATE };

export const actions = {};

export const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setIsLoggedState(state, value) {
    state.isLogged = value;
  },
};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
