<template>
  <section>
    <CommonBanner
      styleHeading="Mobile App"
      heading="Development"
      paragraph="The future of mobile is the future of everything - Matt
              Galligan"
    />
    <!--page title end-->
    <!--body content start-->
    <div class="col-container">
      <div class="col">
        <div class="icon">
          <i class="icon-lightbulb icon-color"></i>
        </div>
        <h2 class="align-item">NATIVE ANDROID</h2>
        <p class="p-desc">
          Native Android Application development with Java optimized in
          performance and different screen sizes.
        </p>
      </div>
      <div class="col">
        <div class="icon">
          <i class="icon-browser2 icon-color"></i>
        </div>
        <h2 class="align-item">REACT NATIVE</h2>
        <p class="p-desc">
          Build phenomenal applications with an exceptionally rich hybrid
          framework that provides optimal performance.
        </p>
      </div>

      <div class="col">
        <div class="icon">
          <i class="icon-laptop2 icon-color"></i>
        </div>
        <h2 class="align-item">NATIVE SCRIPT</h2>
        <p class="p-desc">
          Create cross-platform apps quickly with reduced costs on development
          leveraging Angular & Vue.js
        </p>
      </div>
    </div>
    <div class="col-container">
      <div class="col">
        <div class="icon">
          <i class="icon-basic_gear icon-color"></i>
        </div>
        <h2 class="align-item">NATIVE IOS</h2>
        <p class="p-desc">
          Native iOS Application Development with Swift, RxSwift, and Objc
          optimized for iPad and Mac OS.
        </p>
      </div>

      <div class="col">
        <div class="icon">
          <i class="icon-basic_pencil_ruler icon-color"></i>
        </div>
        <h2 class="align-item">IONIC</h2>
        <p class="p-desc">
          Highly interactive, cross-platform mobile apps with Angular & Vue.js
          for Mid-sized Applications.
        </p>
      </div>

      <div class="col">
        <div class="icon">
          <i class="icon-basic_todolist_pencil icon-color"></i>
        </div>
        <h2 class="align-item">XAMARIN</h2>
        <p class="p-desc">
          Cross-platform native app development with .Net which is
          time-efficient and comes with good performance.
        </p>
      </div>
    </div>
    <section class="body-content">
      <div class="page-content">
        <!--post style 5 start-->
        <div class="post-list-aside">
          <div class="container">
            <div class="row post-single e-position">
              <div class="col-md-6 ss-vertical-align">
                <div class="post-img">
                  <img src="assets/img/post/mobile-engineering.png" alt="" />
                </div>
              </div>
              <div class="col-md-6 pull-right">
                <div class="custom-desc">
                  <h1>
                    Let the pixels resonate with your brand, from screen to
                    screen.
                  </h1>

                  <p>
                    We stay true to our philosophy of "effective app and mobile
                    solutions made with great design and rock-solid technology".
                  </p>

                  <p>
                    We make sure that whatever we do, we build mobile solutions
                    that invigorate our client’s business practices, integrate
                    easily into their overall business models, and exceed their
                    business goals. We can help transform your business with the
                    power of mobility.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--post style 5 end-->
      </div>
    </section>
  </section>
</template>

<script>
import CommonBanner from "./common/CommonBanner.vue";

export default {
  name: "Mobile",
  mounted() {
    window.scrollTo(0, 0);
  },
  components: { CommonBanner },
};
</script>

<style scoped></style>
