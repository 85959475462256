<template>
  <section class="body-content services-component" id="services">
    <div class="page-content services-web">
      <div class="row service-main-container">
        <div class="heading-title-alt border-short-bottom text-center">
          <h3 class="text-uppercase">What we do?</h3>
        </div>
        <div
          class="col-md-3 col-lg-3 col-xs-12 col-sm-12 c-padding"
          v-for="service in services1"
          :key="service.id"
          @click="passData1(service.id)"
        >
          <!-- @mouseover="passData1(service.id)" -->
          <div
            class="service-card-container"
            :class="
              activeTab === service.id
                ? 'service-card-container-active'
                : 'service-card-container-inactive'
            "
          >
            <img
              class="service-card-svg"
              :src="
                require(`../../public/assets/img/services-icons/${service.avatar}`)
              "
              alt="Avatar"
            />
            <span class="services-headings">{{ service.heading }}</span>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-6 col-sm-12 col-xs-12 service-sub-component"
        >
          <ServicesDescription :propObject="propObject" />
        </div>
      </div>
      <div class="row service-main-container">
        <div
          class="col-md-3 col-lg-3 col-xs-12 col-sm-12 c-padding"
          v-for="service in services2"
          :key="service.id"
          @click="passData2(service.id)"
        >
          <!-- @mouseover="passData2(service.id)" -->
          <div
            class="service-card-container"
            :class="
              activeTab === service.id
                ? 'service-card-container-active'
                : 'service-card-container-inactive'
            "
          >
            <img
              class="service-card-svg"
              :src="
                require(`../../public/assets/img/services-icons/${service.avatar}`)
              "
              alt="Avatar"
            />
            <span class="services-headings">{{ service.heading }}</span>
          </div>
        </div>
      </div>
    </div>
    <ServicesMobile :services="services" />
  </section>
</template>
<script>
import ServicesDescription from "./ServicesDescription.vue";
import ServicesMobile from "./ServicesMobile.vue";
export default {
  name: "Services",
  components: {
    ServicesDescription,
    ServicesMobile,
  },
  data() {
    return {
      services1: [
        {
          id: 1,
          path: "/website",
          avatar: "website.svg",
          heading: "Website Development",
          description:
            "Scalable web product development at any stage and ongoing support after launch.",
        },
        {
          id: 2,
          path: "/product",
          avatar: "product.svg",
          heading: "Product Development",
          description:
            "Conceptualization to design, development and marketing newly created products.",
        },
      ],
      services2: [
        {
          id: 3,
          path: "/cloud",
          avatar: "cloud.svg",
          heading: "Cloud Development",
          description:
            "Our skilled professionals deliver solutions that cater to the diverse requiremnets of our clients.",
        },
        {
          id: 4,
          path: "/mobile",
          avatar: "mobile.svg",
          heading: "Mobile Development",
          description:
            "Development of apps that look amazing and function well on both iOS and Android.",
        },
        {
          id: 5,
          path: "/uiux",
          avatar: "uiux.svg",
          heading: "UI UX Design",
          description:
            "We engineer a custom and intuitive user experience wrapped in an equally creative design.",
        },
        {
          id: 6,
          path: "/digital",
          avatar: "digital.svg",
          heading: "Digital Marketing",
          description:
            "Strategically reach, engage and convert your traffic to loyal customers in every possible way.",
        },
      ],
      services: [
        {
          id: 1,
          path: "/website",
          avatar: "website.svg",
          heading: "Website Development",
          description:
            "Scalable web product development at any stage and ongoing support after launch.",
        },
        {
          id: 2,
          path: "/product",
          avatar: "product.svg",
          heading: "Product Development",
          description:
            "Conceptualization to design, development and marketing newly created products.",
        },
        {
          id: 3,
          path: "/cloud",
          avatar: "cloud.svg",
          heading: "Cloud Development",
          description:
            "Our skilled professionals deliver solutions that cater to the diverse requiremnets of our clients.",
        },
        {
          id: 4,
          path: "/mobile",
          avatar: "mobile.svg",
          heading: "Mobile Development",
          description:
            "Development of apps that look amazing and function well on both iOS and Android.",
        },
        {
          id: 5,
          path: "/uiux",
          avatar: "uiux.svg",
          heading: "UI/UX Design",
          description:
            "We engineer a custom and intuitive user experience wrapped in an equally creative design.",
        },
        {
          id: 6,
          path: "/digital",
          avatar: "digital.svg",
          heading: "Digital Marketing",
          description:
            "Whether it’s a creative digital video or photography, we share a love of putting on a great show.",
        },
      ],
      propObject: null,
      activeTab: "",
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
  },
  watch: {
    id: {
      //immediate: true,
      handler(value) {
        this.goToId(value);
      },
    },
  },
  mounted() {
    this.goToId(this.id);
    this.propObject = this.services1[0];
    this.activeTab = this.services1[0].id;
  },
  methods: {
    async passData1(id) {
      this.propObject = this.services1.find((obj) => obj.id === id);
      this.activeTab = id;
    },
    async passData2(id) {
      this.propObject = this.services2.find((obj) => obj.id === id);
      this.activeTab = id;
    },
    goToId(value) {
      if (value) {
        $("html, body").animate(
          {
            scrollTop: $("#" + value).offset().top,
          },
          1000
        );
      } else {
        window.scrollTo({ top: 0 });
      }
    },
  },
  metaInfo() {
    return {
      title: this.pageTitle,
      meta: [
        {
          name: "description",
          content: this.pageDesc,
        },
      ],
    };
  },
};
</script>
