<template>
  <section style="margin-bottom: 50px">
    <!--page title start-->
    <CommonBanner
      styleHeading="Invent. Inspire. Innovate"
      paragraph="Hello! We’re Septem Systems. Good to see you here."
    />
    <!--page title end-->
    <section class="body-content">
      <div class="page-content">
        <!--post style 5 start-->
        <div class="post-list-aside">
          <div class="container">
            <div class="post-single">
              <!--<div class="col-md-7">
                                <div class="post-img">
                                    <img src="assets/img/post/backend.jpg" alt="">
                                </div>
                            </div>-->
              <div class="col-md-12">
                <div class="custom-desc text-center">
                  <div class="col-sm-12 text-align" style="font: caption">
                    <div
                      class="heading-title-alt border-short-bottom text-center career-value-heading"
                    >
                      <h3 class="text-uppercase">Our Story</h3>
                      <p class="text-align">
                        Septem Systems was brought into existence in 2016 when
                        the two co-founders realized they revel in the same
                        passion for digital design, art, and development. So,
                        <b>Talal Anwar</b> & <b>Salman Yousaf</b> merged their
                        creative mindsets to provide inventive and intelligent
                        solutions. Now, you might be asking yourself, how it was
                        executed? Well, below is a road map of how these
                        brilliant minds built SEPTEM SYSTEMS to this date.
                      </p>
                    </div>
                  </div>
                  <!-- <h1>Hello! We’re Septem Systems. Good to see you here.</h1> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="section-padding">
      <div class="container" style="position: relative">
        <div class="row">
          <div class="col-sm-6">
            <img
              class="timeline-img"
              src="assets/img/timeline/timeline-img.png"
            />
          </div>

          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <img class="timeline-year" src="assets/img/timeline/2016.png" />
                <img class="timeline-icon" src="assets/img/timeline/icon.svg" />
                <img
                  class="timeline-icon-1"
                  src="assets/img/timeline/Artboard –2.png"
                />
              </div>

              <div class="col-sm-12">
                <h1 class="timeline-heading">YEAR OF BIRTH</h1>
              </div>

              <div class="col-sm-12">
                <p class="timeline-p">
                  Three passion-driven technology<br />
                  enthusiasts laid the foundation of<br />
                  Septem Systems.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <img
            class="timeline-curve-img"
            src="assets/img/timeline/2016-2017.svg "
          />
        </div>

        <div class="row T-padding">
          <div class="col-sm-6 top-padding">
            <div class="row">
              <div class="col-sm-12">
                <img class="timeline-year" src="assets/img/timeline/2017.png" />
                <img
                  class="timeline-icon"
                  src="assets/img/timeline/icon-1.svg"
                />
                <img
                  class="timeline-icon-1"
                  src="assets/img/timeline/Artboard –6 (1).png"
                />
              </div>

              <div class="col-sm-12">
                <h1 class="timeline-heading">YEAR OF LEARNING</h1>
              </div>

              <div class="col-sm-12">
                <p class="timeline-p">
                  We struggled, we failed, we learned,<br />
                  we fought, and we succeeded.
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <img
              class="timeline-img-1"
              src="assets/img/timeline/timeline-img-1.png"
            />
          </div>
        </div>

        <!-- <div class="row second-row" style="padding-top:15px;">
            <div class="col-sm-6">
                    <img class="timeline-img-1" src="assets/img/timeline/timeline-img-1.svg">
                </div>

                <div class="col-sm-6 top-padding">
                    <div class="row">
                        <div class="col-sm-12">
                            <img class="timeline-icon" src="assets/img/timeline/icon-1.svg">
                        </div>

                        <div class="col-sm-12">
                            <h1 class="timeline-heading">YEAR OF BIRTH</h1>
                        </div>

                        <div class="col-sm-12">
                            <p  class="timeline-p">Three passion driven technology<br> enthusiasts brought forward<br> Septem Systems.</p>
                        </div>
                     </div>
                  </div>


            </div> -->
      </div>
    </div>

    <div class="section1-padding">
      <div class="container" style="position: relative">
        <div class="row">
          <div class="col-sm-6 position-img">
            <img
              class="timeline-img"
              src="assets/img/timeline/timeline-img-2.png"
            />
          </div>

          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <img class="timeline-year" src="assets/img/timeline/2018.png" />
                <img
                  class="timeline-icon"
                  src="assets/img/timeline/icon-2.svg"
                />
                <img
                  class="timeline-icon-1"
                  src="assets/img/timeline/Artboard –5.png"
                />
              </div>

              <div class="col-sm-12">
                <h1 class="timeline-heading">YEAR OF GROWTH</h1>
              </div>

              <div class="col-sm-12">
                <p class="timeline-p">
                  Started from break-even, now into profits.<br />Septem Systems
                  grew from a team of 3 to a <br />whopping 22.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <img
            class="timeline-curve-img"
            src="assets/img/timeline/2018-2019.svg "
          />
        </div>

        <div class="row T-padding">
          <div class="col-sm-6 top-padding">
            <div class="row">
              <div class="col-sm-12">
                <img class="timeline-year" src="assets/img/timeline/2019.png" />
                <img
                  class="timeline-icon"
                  src="assets/img/timeline/icon-3.svg"
                />
                <img
                  class="timeline-icon-1"
                  src="assets/img/timeline/Artboard –4.png"
                />
              </div>

              <div class="col-sm-12">
                <h1 class="timeline-heading">YEAR OF STRENGTH</h1>
              </div>

              <div class="col-sm-12">
                <p class="timeline-p">
                  Our family grows stronger, our<br />
                  clientele bigger and better.
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <img
              class="timeline-img-1"
              src="assets/img/timeline/timeline-img-3.png"
            />
          </div>
        </div>

        <!-- <div class="row second-row" style="padding-top:15px;">
            <div class="col-sm-6">
                    <img class="timeline-img-1" src="assets/img/timeline/timeline-img-3.svg">
                </div>

                <div class="col-sm-6 top-padding">
                    <div class="row">
                        <div class="col-sm-12">
                            <img class="timeline-icon" src="assets/img/timeline/icon-3.svg">
                        </div>

                        <div class="col-sm-12">
                            <h1 class="timeline-heading">YEAR OF BIRTH</h1>
                        </div>

                        <div class="col-sm-12">
                            <p  class="timeline-p">Three passion driven technology<br> enthusiasts brought forward<br> Septem Systems.</p>
                        </div>
                     </div>
                  </div>


            </div> -->
      </div>
    </div>

    <div class="section1-padding">
      <div class="container" style="position: relative">
        <div class="row">
          <div class="col-sm-6">
            <img
              class="timeline-img"
              src="assets/img/timeline/timeline-img-4.png"
            />
          </div>

          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <img class="timeline-year" src="assets/img/timeline/2020.png" />
                <img
                  class="timeline-icon"
                  src="assets/img/timeline/icon-4.svg"
                />
                <img
                  class="timeline-icon-1"
                  src="assets/img/timeline/Artboard –3.png"
                />
              </div>
              <div class="col-sm-12">
                <h1 class="timeline-heading">
                  SAILED VICTORIOUSLY, THROUGH<br />
                  COVID-19 WAVES
                </h1>
              </div>
              <div class="col-sm-12">
                <p class="timeline-p">
                  Despite the pandemic, adequate plans, and processes<br />
                  were laid out that led to enhanced scalability <br />and
                  structure of our organization.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img
            class="timeline-curve-img"
            src="assets/img/timeline/2020-2021.svg "
          />
        </div>
        <div class="row T-padding">
          <div class="col-sm-6 top-padding-1">
            <div class="row">
              <div class="col-sm-12">
                <img class="timeline-year" src="assets/img/timeline/2021.png" />
                <img
                  class="timeline-icon"
                  src="assets/img/timeline/icon-5.svg"
                />
                <img
                  class="timeline-icon-1"
                  src="assets/img/timeline/Artboard –1.png"
                />
              </div>

              <div class="col-sm-12">
                <h1 class="timeline-heading">ADJUSTING TO THE NEW NORMS</h1>
              </div>

              <div class="col-sm-12">
                <p class="timeline-p">
                  Our goal is to sustain, incorporate and improvise further<br />
                  scalability into organizational composition and see
                  <br />through this year with positive vibes only.
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <img
              class="timeline-img-1"
              src="assets/img/timeline/timeline-img-5.png"
            />
          </div>
        </div>

        <!-- <div class="row second-row" style="padding-top:15px;">
            <div class="col-sm-6">
                    <img class="timeline-img-1" src="assets/img/timeline/timeline-img-5.svg">
                </div>

                <div class="col-sm-6 top-padding">
                    <div class="row">
                        <div class="col-sm-12">
                            <img class="timeline-icon" src="assets/img/timeline/icon-5.svg">
                        </div>

                        <div class="col-sm-12">
                            <h1 class="timeline-heading">YEAR OF BIRTH</h1>
                        </div>

                        <div class="col-sm-12">
                            <p  class="timeline-p">Three passion driven technology<br> enthusiasts brought forward<br> Septem Systems.</p>
                        </div>
                     </div>
                  </div>


            </div> -->
      </div>
    </div>
    <div class="section1-padding" style="padding-bottom: 100px">
      <div class="container" style="position: relative">
        <div class="row">
          <div class="col-sm-6">
            <img
              class="timeline-img"
              src="assets/img/timeline/culture-160.png"
            />
          </div>

          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <img class="timeline-year" src="assets/img/timeline/2022.png" />
                <img
                  class="timeline-icon"
                  src="assets/img/timeline/culture-small.png"
                />
                <img
                  class="timeline-icon-1"
                  src="assets/img/timeline/culture-smallll.png"
                />
              </div>
              <div class="col-sm-12">
                <h1 class="timeline-heading">YEAR OF CULTURE<br /></h1>
              </div>
              <div class="col-sm-12">
                <p class="timeline-p">
                  We evolved our culture and emerged stronger.<br />
                  Our policies improved. Our numbers doubled.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img
            class="timeline-curve-img"
            src="assets/img/timeline/2022-2023.svg "
          />
        </div>
        <div class="row T-padding">
          <div class="col-sm-6 top-padding-1">
            <div class="row">
              <div class="col-sm-12">
                <img class="timeline-year" src="assets/img/timeline/2023.png" />
                <img
                  class="timeline-icon"
                  src="assets/img/timeline/question-mark.png"
                />
                <img
                  class="timeline-icon-1"
                  src="assets/img/timeline/question-markk.png"
                />
              </div>

              <div class="col-sm-12">
                <h1 class="timeline-heading">WE'RE JUST AS CURIOUS</h1>
              </div>

              <div class="col-sm-12">
                <p class="timeline-p">
                  Our hustle is constant, and our hopes are high.
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-6" style="padding-bottom: 60px">
            <img class="timeline-img-1" src="assets/img/timeline/box-160.png" />
          </div>
        </div>

        <!-- <div class="row second-row" style="padding-top:15px;">
              <div class="col-sm-6">
                      <img class="timeline-img-1" src="assets/img/timeline/timeline-img-5.svg">
                  </div>

                  <div class="col-sm-6 top-padding">
                      <div class="row">
                          <div class="col-sm-12">
                              <img class="timeline-icon" src="assets/img/timeline/icon-5.svg">
                          </div>

                          <div class="col-sm-12">
                              <h1 class="timeline-heading">YEAR OF BIRTH</h1>
                          </div>

                          <div class="col-sm-12">
                              <p  class="timeline-p">Three passion driven technology<br> enthusiasts brought forward<br> Septem Systems.</p>
                          </div>
                       </div>
                    </div>


              </div> -->
      </div>
    </div>
    <CompanyStats />
    <Testimonials />
    <!-- <section>

            <div class="page-content timeline-parallax">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <!-time line dark -->
    <!-- <div class="timeline dark">
                                <article class="timeline-item alt">
                                    <div class="timeline-desk">
                                        <div class="panel">
                                            <div class="panel-body">
                                                <span class="timeline-icon">
                                                    <i class="fa fa-baby"></i>
                                                </span>
                                                <!-<span class="timeline-date">08:25 am</span>-->
    <!-- <h1 class="text-uppercase">2016 - Year of Birth</h1>
                                                <p>Three passion driven technology enthusiasts brought forward Septem Systems.</p> -->
    <!-- </div>
                                        </div>
                                    </div>
                                </article>
                                <article class="timeline-item ">
                                    <div class="timeline-desk">
                                        <div class="panel">
                                            <div class="panel-body">
                                                <span class="timeline-icon">
                                                    <i class="icon-lightbulb"></i>
                                                </span>

                                                <h1 class="text-uppercase">2017 - Year of Learning</h1>
                                                <p>We struggled, we failed, we learned, we fought, we succeeded.</p>
                                            </div>
                                        </div>
                                    </div>
                                </article> -->
    <!-- <article class="timeline-item alt">
                                    <div class="timeline-desk">
                                        <div class="panel">
                                            <div class="panel-body">
                                                <span class="timeline-icon">
                                                    <i class="icon-strategy"></i>
                                                </span>

                                                <h1 class="text-uppercase">2018 - Year of Growth</h1>
                                                <p>Started from break-even, now into profits. Septem Systems grew from 3 to a whopping 22! </p>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <article class="timeline-item">
                                    <div class="timeline-desk">
                                        <div class="panel">
                                            <div class="panel-body">
                                                <span class="timeline-icon">
                                                    <i class="icon-telescope"></i>
                                                </span>

                                                <h1 class="text-uppercase">2019 - Year of Strength</h1>
                                                <p>Our family grows stronger, our clientele bigger and better. </p>
                                                <!-<p>
                                                    <i class=" fa fa-exclamation-sign"></i> New task added for <span><a href="#" >Denial Collins</a></span>
                                                </p>-->
    <!-- </div>
                                        </div>
                                    </div>
                                </article>
                                <article class="timeline-item alt">
                                    <div class="timeline-desk">
                                        <div class="panel">
                                            <div class="panel-body">
                                                <span class="timeline-icon">
                                                    <i class="fa fa-line-chart"></i>
                                                </span>

                                                <h1 class="text-uppercase">2020 - Year of Scalability</h1>
                                                <p>The goal is to set new processes to scale the business and team</p>
                                            </div>
                                        </div>
                                    </div>
                                </article>

                            </div>
                            <!-time line dark
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

    <!-- <div class="container our-team-padding">
      <div class="col-sm-12">
        <div class="heading-title-alt border-short-bottom text-center">
          <h3 class="text-uppercase">Our Team</h3>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="team-img">
          <img class="our-img" src="assets/img/team/ff.png" />
          <img class="our-img" src="assets/img/team/6.png" />
        </div>
        <h1 class="our-team-h">Salman Yousaf</h1>
        <p class="our-team-p">Co-Founder &amp; CTO</p>
        <p class="our-team-p1">
          Salman is the company’s best tech advisor. Highly skilled with
          development and architecture, he can code everything and solve any
          problem with his wit, skill, and determination.
        </p>
      </div>

      <div class="col-sm-4 team-p">
        <div class="team-img">
          <img class="our-img" src="assets/img/team/3.png" />
          <img class="our-img" src="assets/img/team/4.png" />
        </div>
        <h1 class="our-team-h">Talal Anwar</h1>
        <p class="our-team-p">Co-Founder &amp; CEO</p>
        <p class="our-team-p1">
          Talal is the main strategist and leader of Septem Systems. Having a
          Master's degree in software development paired with a research &amp;
          business mindset, he advises, consults, and directs the team on all
          important decisions.
        </p>
      </div>

      <div class="col-sm-4 team-p">
        <div class="team-img">
          <img class="our-img" src="assets/img/team/1.png" />
          <img class="our-img" src="assets/img/team/5.png" />
        </div>
        <h1 class="our-team-h">Hamza Wajid</h1>
        <p class="our-team-p">Co-Founder &amp; COO</p>
        <p class="our-team-p1">
          Hamza is the brains behind every app the company develops.
          Goal-oriented and driven by a strong passion for development, Hamza’s
          team-leading, briefing, and management skills are on-point.
        </p>
      </div>
    </div> -->

    <!-- <section class="body-content">

            <div class="page-content">
                <--post style 5 start-->
    <!-- <div class="post-list-aside">
                    <div class="container">
                        <div class="post-single">
                            <div class="col-md-6">
                                <div class="post-img">
                                    <img src="assets/img/team/talal1.jpg" alt="">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="custom-desc">
                                    <h1 >
                                        Co-Founder &amp; CEO

                                    </h1>

                                    <p class="custom-about-para">
                                        Talal is the main strategist and leader of Septem Systems. Having a Masters degree in software development paired with research &amp; business mindset, he advises, consults and directs the team on all important decisions.
                                    </p>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  -->

    <!-- <section class="body-content">

            <div class="page-content">
                <!-post style 5 start-->
    <!-- <div class="post-list-aside">
                    <div class="container">
                        <div class="post-single">

                            <div class="col-md-6">
                                <div class="custom-desc">
                                    <h1 >
                                        Co-Founder &amp; CTO
                                    </h1>

                                    <p class="custom-about-para">
                                        Salman is the company’s best tech advisor. Highly skilled with development and architecture, he can code everything and solve any problem with his wit, skill and determination.
                                    </p>

                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="post-img">
                                    <img src="assets/img/team/salman.jpg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

    <!-- <section class="body-content">

            <div class="page-content">
                <!-post style 5 start-->
    <!-- <div class="post-list-aside">
                    <div class="container">
                        <div class="post-single">
                            <div class="col-md-6">
                                <div class="post-img">
                                    <img src="assets/img/team/hamza.jpg" alt="">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="custom-desc">
                                    <h1 >
                                        Co-Founder &amp; COO
                                    </h1>

                                    <p class="custom-about-para">
                                        Hamza is the brain behind every app the company develops. Goal-oriented and driven by a strong passion for development, Hamza’s team leading, briefing and management skills are on-point.
                                    </p>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  -->
  </section>
</template>

<script>
import Testimonials from "./Testimonials.vue";
import CompanyStats from "./common/CompanyStats.vue";
import CommonBanner from "./common/CommonBanner.vue";
export default {
  name: "about",
  components: {
    CompanyStats,
    Testimonials,
    CommonBanner,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
