<template>
  <div>
    <BannerSection />
    <Portfolio title="Our recent work" :path="path" />
    <CompanyStats />
    <Testimonials />
  </div>
</template>
<script>
import Portfolio from "./Portfolio.vue";
import CompanyStats from "./common/CompanyStats.vue";
import Testimonials from "./Testimonials.vue";
import BannerSection from "./BannerSection.vue";
export default {
  name: "Projects",
  computed: {
    path() {
      return this.$route.name;
    },
  },
  components: { CompanyStats, Portfolio, BannerSection, Testimonials },
};
</script>
<style scoped>
.fontt p {
  margin: 0px;
}
</style>
