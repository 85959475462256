<template>
  <section>
    <div class="row portfolio-container">
      <div class="col-sm-12 col-md-12 col-lg-6">
        <img src="/images/axxios/axxios-logo.png" alt="" class="logo-img">
        <h1 class="main-heading">
          easier and exciting lives through home automation
        </h1>
        <p class="atom-p">
          An Award-winning technology, 
          Axxios home is a privacy-focused, 
          voice-activated smart home control 
          system, entitled winner of the Residential 
          Systems Award at the Consumer Electronics Show, 
          and featured in renowned online publications like 
          ABC, CNET, Digital Trends, TechRadar. As the technology 
          partner, we enabled the users to control their homes with 
          significant ease, comfort, and security
        </p>
        <h2 class="sub-heading">
          technologies
        </h2>
        <div class="tech-img">
          <div class="grid">
            <img src="/images/axxios/android.png" alt="">
            <img src="/images/axxios/firebase.png" alt="">
            <img src="/images/axxios/java.png" alt="">
            <img src="/images/axxios/my-sql.png" alt="">
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6">
        <div class="flex">
          <img src="/images/Axxios/main.png" alt="">
        </div>
      </div>
      <div class="second-grid">
        <div>
          <div class="inrto-img">
            <img src="/images/axxios/axxios-router.png" alt="">
          </div>
        </div>
        <div>
          <h1 class="main-heading">
            introduction
          </h1>
          <h2 class="sub-heading">
            Working in conjunction with Axxios
          </h2>
          <p class="atom-p">
            In today’s age of tech advancements, 
            smart home automation has made its mark 
            globally but not all systems have been 
            reached the expected point of success. 
            Axxios Home, a smart home automation 
            organization, is on a mission to simplify 
            the smart home environment. A smart home 
            is one where virtually everything is connected 
            to a remotely controllable network. It gets 
            complex since every smart appliance has its own 
            application and different devices follow different protocols. 
            Axxios Home took the initiative to amend this problem by 
            creating a product named ‘Axxios Control’, a private 
            voice-activated smart home control system that integrates 
            voice, touch, mobile, and gesture controls
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">our integration</h1>
      </div>
      <div class="col-sm-12 col-md-8 col-lg-8">
        <div class="integration-class">
          <p class="atom-p">
            For Axxios Home’s hardware, Axxios Control, 
            a highly robust and intuitive application was 
            required to assist its users to control all 
            their smart home processes from a single 
            source rather than switching or adding multiple applications. 
            Septem Systems created an application that functioned 
            through an android operating system.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="integration-img">
          <img src="/images/axxios/group 1.png" alt="">
        </div>

      </div>
      <div class="second-grid">
        <div>
          <div class="img">
            <img src="/images/axxios/screen.png" alt="">
          </div>
        </div>
        <div>
          <p class="atom-p">
            We made sure that door locks, smart lights, 
            switches, music, sensors, doorbells, 
            climate control and cameras, all of these utilities, 
            and many others can be controlled via a sole application. 
            We used the respective smart product’s APIs to create a 
            communication channel to the hardware and followed relevant 
            protocols to make sure that the application we developed had a 
            different panache to it.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">outcome</h1>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="integration-class">
          <p class="atom-p outcome-p">
            With our application, we want to contribute to the 
            positive impact that Axxios Home aims to create. 
            One of the reasons why people invest in smart home 
            automation is safety and security. Axxios Control 
            allows immediate switching from your security camera 
            display to locking your door rather than quitting and 
            opening different applications during an unfortunate 
            home invasion where timing is the absolute key. Apart from that, 
            smart cooling and heating options can save around 50% of energy regularly. 
            All in all, Axxios Home and Septem Systems collaborated 
            to bring about a change in smart home automation 
            and allow the users to control their homes with pure ease and comfort.
          </p>
          <img src="/images/axxios/remot.png" alt="" class="outcome-img">
        </div>
      </div>
      <div class="col-sm-12 col-md-9 col-lg-9">
        <div class="full-img">
          <img src="/images/axxios/final-1.png" alt="">
        </div>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-3">
        <div class="small-img">
          <img src="/images/axxios/final-2.png" alt="">
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">client testimonials </h1>
      </div>
      <div class="col-sm-12 col-md-7 col-lg-7 mt-5">
        <div class="testimonial">
          <div class="comment-flex">
            <div class="img">
              <img src="/images/axxios/ceo.png" alt="">
            </div>
            <div>
              <p>Mark Lyle</p>
              <span>CEO & Founder</span>
            </div>
          </div>
          <p class="testimonial-p">
            “Working with Septem Systems has been a fabulous experience, 
            despite my initial apprehensions about building an 
            association with an entity sitting at a remote distance. 
            They developed a robust application for Axxios Control, 
            our smart home controller device. What they stand for 
            is defined by their attitude towards work and commitment 
            to excellence. There were no loopholes in their contract, 
            eliminating any room for misunderstanding. Septem Systems, 
            for sure, is my recommendation for anyone looking for 
            high-quality application development.”
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-2 col-lg-2 mt-5"></div>
      <div class="col-sm-12 col-md-3 col-lg-3 mt-5">
        <div class="img-last">
            <img src="/images/axxios/group 2.png" alt="">
          </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: 'AxxiosCaseStudy',

}
</script>

<style scoped>

section{
  margin-top: -5%;
}
.img-last{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}
.img-last img{
  width: 100%;
  height: 100%;
}

.second-grid .img {
  width: 100%;
  height: auto;
}

.second-grid .img img {
  width: 100%;
  height: 100%;
}

.testimonial{
  position: relative;
  background-color: #151515;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  height: auto;
}
.testimonial .comment-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.testimonial .testimonial-p{
  font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 600;
  line-height: 1.5;
}
.atom-p{
  text-align: left;
  font-size: 1.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}


.comment-flex .img {
  width: 50px;
  height: auto;
  border-radius: 50%;
  margin-left: 0px;
  padding: 0px 10px 0px 0px;
}

.comment-flex .img img {

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  border: 1px solid #00b398;
}

.comment-flex p {
 color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin: 0px !important; 
}

.comment-flex span {
  
   color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.g2-img {
  width: 100%;
  height: auto;
}

.g2-img img {
  width: 100%;
  height: 100%;
}

.full-img {
  width: 100%;
  height: auto;
  margin: 10% auto;
}
.small-img{
  width: 100%;
  margin:  35% 0;
}
.small-img img{
  width: 100%;
  height: 100%;
}

.full-img img {
  height: 100%;
  width: 100%;
}
.integration-class {
  position: relative;
  background-color: #151515;
  padding: 50px;
  border-radius: 5rem;
  width: 100%;
  height: auto;
}
.integration-class .outcome-img{
  position: absolute;
  width: 40rem;
  height: auto;
  bottom: 0;
  right: 0;
}

.integration-class .outcome-p {
  padding-right: 30%;
}

.integration-img {
  width: 100%;
  height: auto;
}

.integration-img img {
  width: 100%;
  height: 100%;
}

.second-grid .sub-heading span {
  color: rgb(46, 179, 127);
}

.second-grid {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  width: 100%;
  column-gap: 20px;
  padding: 10% 0;
  align-items: center;
}

.logo-img {
  padding: 20% 0px 10% 0px;
  width: 130px;
  height: 100%;
}

.inrto-img {
  width: 100%;
  height: auto;
}

.inrto-img img {
  width: 100%;
  height: 100%;
}


.second-grid .main-heading {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.5px;
  padding-bottom: 5px;
  line-height: 0.5;
  text-transform: capitalize;
}

.main-heading {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.5px;
  padding-bottom: 5px;
  line-height: 45px;
  text-transform: capitalize;
}

.second-grid.sub-heading {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.sub-heading {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.tech-img {
  width: 60%
}

.tech-img .grid {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr;
  gap: 2rem;
}

.grid img {
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 40%;
}

.flex img {
  width: 100%;
  height: 100%;
}

@media(max-width: 768px) {

  .integration-class .outcome-img {
    display: none;
  }

  .integration-class .outcome-p {
    padding: 10px;
  }
  .second-grid.section-2 div:first-child{
    order: 0;
  }

  .second-grid {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 10px 15px;
  }

  .second-grid div:first-child {
    order: 1;
  }
  .inrto-img {
    width: 100%;
    height: auto;
    padding: 5px 0px;
  }

  .second-grid.main-heading {
    font-size: 30px;
    font-weight: 700;
  }

  .second-grid.sub-heading {
    font-size: 24px;
    font-weight: 700;
  }

  .logo-img {
    padding-top: 70px;
    width: 100px;
    height: 100%;
  }

}

@media(max-width: 480px) {
  .flex img{
    margin: 0 0 50px 0;
  }
  .second-grid .img  {
    margin: 50px 0 30px 0;
  }
  .inrto-img, .integration-img, .img-last{
    margin: 50px auto;
  }
  .integration-class{
    padding: 10px 20px;
    border-radius: 20px;
  }
  .atom-p {
    font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 500;
  line-height: 1.7;
  text-align: justify;
  }
  .integration-class .bigscoot-p {
    font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 500;
  line-height: 1.7;
  text-align: justify;

  }
  .integration-class .outcome-p {
    padding-right: 0 ;
  }
  .testimonial-p{
    text-align: justify;
    font-weight: 400;
  }
  .second-grid.main-heading {
    font-size: 20;
  }

  .second-grid.sub-heading {
    font-size: 16px;
  }
  .full-img img{
    display: none;
  }
}</style>
