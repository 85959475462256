<template>
  <section>
    <!--page title start-->
    <CommonBanner
      styleHeading="Website"
      heading="Development"
      paragraph=" The engineer has been, and is,  a maker of history. - James Kip Finch"
    />
    <!--page title end-->

    <!--body content start-->
    <section class="body-content">
      <div class="page-content">
        <!--post style 5 start-->
        <div class="post-list-aside">
          <div class="container">
            <div class="row post-single e-position">
              <div class="col-md-7 ss-vertical-align">
                <div class="post-img">
                  <img src="assets/img/post/X DCXX.png" alt="" />
                </div>
              </div>
              <div class="col-md-5 pull-right">
                <div class="custom-desc">
                  <h1>Back-End Engineering</h1>

                  <p>
                    Just as you shouldn’t judge a book by its cover, you
                    shouldn’t judge an app by its design and front store only!
                    Looks are important, but it’s the brain that makes your
                    product irresistibly attractive. Do you want an intelligent
                    kid who also wins all the beauty pageants? That’s the type
                    of product we code at Septem Systems.
                  </p>

                  <p>
                    We offer a set of services that span the development of
                    custom back-end layers, optimization of existing backend
                    systems, and migration to the cloud, empowering their
                    extensibility and performance.
                  </p>
                </div>
                <div class="m-bot-30">
                  <router-link
                    to="/backend"
                    class="btn btn-small btn-rounded btn-custom-solid"
                  >
                    Read More
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="post-list-aside sekind-row p-top-80">
          <div class="container">
            <div class="row post-single e-position">
              <div class="col-md-5">
                <div class="custom-desc">
                  <h1>Front-End Engineering</h1>

                  <p>
                    Looks great, loads fast, and works without a teeny-tiny
                    hiccup – here’s how your final product will look like.
                  </p>

                  <p>
                    We create interfaces users can’t help, but love. Our
                    front-end ninjas ensure that each product is made with great
                    care to make it look equally stunning on large desktop
                    screens and mobile devices. Smooth, fast, and intuitive
                    navigation comes as a guaranteed part of our front-end
                    programming services.
                  </p>
                </div>
                <div class="m-bot-30">
                  <router-link
                    to="/frontend"
                    class="btn btn-small btn-rounded btn-custom-solid"
                  >
                    Read More
                  </router-link>
                </div>
              </div>
              <div class="col-md-7 ss-vertical-align-1">
                <div class="post-img">
                  <img src="assets/img/post/AXrtboar d – 1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="post-list-aside sekind-row-m p-top-80">
          <div class="container">
            <div class="row post-single e-position">
              <div class="col-md-7 ss-vertical-align-1">
                <div class="post-img">
                  <img src="assets/img/post/AXrtboar d – 1.png" alt="" />
                </div>
              </div>

              <div class="col-md-5">
                <div class="custom-desc">
                  <h1>Web Front-End Engineering</h1>

                  <p>
                    Looks great, loads fast, and works without a teeny-tiny
                    hiccup – here’s how your final product will look like.
                  </p>

                  <p>
                    We create interfaces users can’t help, but love. Our
                    front-end ninjas ensure that each product is made with great
                    care to make it look equally stunning on large desktop
                    screens and mobile devices. Smooth, fast, and intuitive
                    navigation comes as a guaranteed part of our front-end
                    programming services.
                  </p>
                </div>
                <div class="m-bot-30">
                  <router-link
                    to="/frontend"
                    class="btn btn-small btn-rounded btn-custom-solid"
                  >
                    Read More
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--post style 5 end-->
      </div>
    </section>
    <!--body content end-->
  </section>
</template>

<script>
import CommonBanner from "./common/CommonBanner.vue";

export default {
  name: "Website",
  mounted() {
    window.scrollTo(0, 0);
  },
  components: { CommonBanner },
};
</script>

<style scoped>
.services-h span {
  font-family: "rubiklight" !important;
}
</style>
