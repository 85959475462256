<template>
<section>
        <CommonBanner style-heading="Our" heading="Subsidiaries" paragraph="Empowering Industries with Next-Generation 
        Smart Solutions for a Better Future">
        </CommonBanner>
        <main class=" portfolio-container">

        <div class="row portfolio-card-container">
        <div class="col-md-6 portfolio-card-image-container">
        <div class="card-1 portfolio-image-container">
        <img class="portfolio-card-body-image" src="/images/bayked.png" alt="">
        <div class="overlay">
            <a class="case-study-button" href="https://bayked.co/" target="_blank">
            <p>Read More</p>
            <span>
            <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
            d="M12.7534 22.8678C18.303 22.8678 22.8018 18.369 22.8018 12.8194C22.8018 7.26982 18.303 2.771 12.7534 2.771C7.20384 2.771 2.70502 7.26982 2.70502 12.8194C2.70502 18.369 7.20384 22.8678 12.7534 22.8678Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M12.7534 16.8388L16.7728 12.8194L12.7534 8.80005"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M8.73407 12.8195H16.7728"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            </svg>
            </span>

            </a>

            </div>

            </div>
            <div class="card-text-1">
            <span class="card-heading">Bayked</span>
            <p class="card-desc">Bayked partners with pizzerias to promote local food,
            with easy ordering and tracking features </p>
            </div>
            </div>
            <div class="col-md-6 portfolio-card-image-container">
            <div class="card-2 portfolio-image-container">
            <img class="portfolio-card-body-image" src="/images/contra.jpeg" alt="">
            <div class="overlay">

            <a class="case-study-button" href="https://contracoffee.pk/" target="_blank">
            <p>Read More</p>
            <span>
            <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
            d="M12.7534 22.8678C18.303 22.8678 22.8018 18.369 22.8018 12.8194C22.8018 7.26982 18.303 2.771 12.7534 2.771C7.20384 2.771 2.70502 7.26982 2.70502 12.8194C2.70502 18.369 7.20384 22.8678 12.7534 22.8678Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M12.7534 16.8388L16.7728 12.8194L12.7534 8.80005"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M8.73407 12.8195H16.7728"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            </svg>
            </span>

            </a>

            </div>

            </div>
            <div class="card-text-2">
            <h1 class="card-heading">Contra Coffee</h1>
            <p class="card-desc">Contra Coffee: Elite products, premium ingredients, 
            calming atmosphere </p>
            </div>
            </div>
            </div>
            <div class="row portfolio-card-container">
            <div class="col-md-6 portfolio-card-image-container">
            <div class="card-3 portfolio-image-container">
            <img class="portfolio-card-body-image" src="/images/bebambi.jpg" alt="">
            <div class="overlay">

            <a class="case-study-button" href="https://bebambi.com/ " target="_blank">
            <p>Read More</p>
            <span>
            <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
            d="M12.7534 22.8678C18.303 22.8678 22.8018 18.369 22.8018 12.8194C22.8018 7.26982 18.303 2.771 12.7534 2.771C7.20384 2.771 2.70502 7.26982 2.70502 12.8194C2.70502 18.369 7.20384 22.8678 12.7534 22.8678Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M12.7534 16.8388L16.7728 12.8194L12.7534 8.80005"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M8.73407 12.8195H16.7728"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            </svg>
            </span>

            </a>

            </div>

            </div>
            <div class="card-text-3">
            <h1 class="card-heading">Bebambi</h1>
            <p class="card-desc">Guiding you through every milestone of parenthood, 
                from conception to childhood.</p>
            </div>
            </div>
            <div class="col-md-6 portfolio-card-image-container">
            <div class="card-4 portfolio-image-container">
            <img class="portfolio-card-body-image" src="/images/deliverymonster.png" alt="">
            <div class="overlay">

            <a class="case-study-button" href="https://deliverymonsters.com" target="_blank">
        <p>Read More</p>
        <span>
        <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
        d="M12.7534 22.8678C18.303 22.8678 22.8018 18.369 22.8018 12.8194C22.8018 7.26982 18.303 2.771 12.7534 2.771C7.20384 2.771 2.70502 7.26982 2.70502 12.8194C2.70502 18.369 7.20384 22.8678 12.7534 22.8678Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        />
        <path
        d="M12.7534 16.8388L16.7728 12.8194L12.7534 8.80005"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        />
        <path
        d="M8.73407 12.8195H16.7728"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        />
        </svg>
        </span>

        </a>

        </div>

        </div>
        <div class="card-text-4">
        <h1 class="card-heading">Delivery Monster</h1>
        <p class="card-desc">Delivery Monsters:
        Integrated restaurant order, rider,
        complaint, and analytics solution. </p>
        </div>
        </div>
        </div>
        </main>
        </section>
</template>

<script>
import CommonBanner from "./common/CommonBanner.vue";
export default {
    name: 'Subsidiaries',
    components: { CommonBanner },

}
</script>
<style scoped>
.portfolio-image-container:hover .overlay {
opacity: 1;
border-radius: 13px;
}
.card-text-1{ width: 95%;  margin-right: 5%; margin-top: 5rem;}
.card-text-2{ width: 95%;  margin-left: 5%;margin-top: 5rem;}
.card-text-3{ width: 95%;  margin-right: 5%;margin-top: 5rem;}
.card-text-4{ width: 95%;  margin-left: 5%;margin-top: 5rem;}
.card-1 {

width: 95%;
height: auto;
margin-right: 5%;
}
.card-2 {

width: 95%;
height: auto;
margin-left: 5%;
}
.card-3 {

width: 95%;
height: auto;
margin-right: 5%;
}
.card-4 {

width: 95%;
height: auto;
margin-left: 5%;
}

.card-1 img {
width: 100%;
height: 100%;
border-radius: 15.2365px;
}
.card-2 img {
width: 100%;
height: 100%;
border-radius: 15.2365px;
}
.card-3 img {
width: 100%;
height: 100%;
border-radius: 15.2365px;
}
.card-4 img {
width: 100%;
height: 100%;
border-radius: 15.2365px;
}
.card-heading{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    line-height: 118.9%;
    letter-spacing: 0.01em;
    color: #01b398;
    margin-top: 20px;
}
.card-desc{

    font-weight: 400;
  font-size: 16px;
  line-height: 135.9%;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
  margin: 5px 0px 20px 0px;
}
@media (max-width: 992px) {
.card-text-1{ width: 100%; margin-top: 2rem;}
.card-text-2{ width: 100%;margin-left: 0;  margin-top: 2rem;}
.card-text-3{ width: 100%; margin-top: 2rem;}
.card-text-4{ width: 100%;margin-left: 0;  margin-top: 2rem;}
.card-1 {

width: 100%;

}
.card-2 {

width: 100%;
margin-left: 0;


}
.card-3 {

width: 100%;


}
.card-4 {

width: 100%;
margin-left: 0;

}
.card-heading{
font-style: normal;
font-weight: 500;
font-size: 24.5675px;
line-height: 100.9%;
/* or 43px */

letter-spacing: 0.01em;
margin-top: 20px;
color: #01B398;
}
.card-desc{

font-style: normal;
font-weight: 300;
font-size: 18.3784px;
line-height: 120.9%;
/* or 33px */

letter-spacing: 0.01em;

color: rgba(255, 255, 255, 0.8);

opacity: 0.85;
margin-bottom: 20px;
}
}

@media (max-width: 768px) {
.card-text-1{ width: 100%; margin-top: 2rem;}
.card-text-2{ width: 100%;margin-left: 0;  margin-top: 2rem;}
.card-text-3{ width: 100%; margin-top: 2rem;}
.card-text-4{ width: 100%;margin-left: 0;  margin-top: 2rem;}
.card-1 {

width: 100%;

}
.card-2 {

width: 100%;
margin-left: 0;


}
.card-3 {

width: 100%;


}
.card-4 {

width: 100%;
margin-left: 0;

}
.card-heading{
font-style: normal;
font-weight: 500;
font-size: 24.5675px;
line-height: 100.9%;
/* or 43px */

letter-spacing: 0.01em;
margin-top: 20px;
color: #01B398;
}
.card-desc{

font-style: normal;
font-weight: 300;
font-size: 18.3784px;
line-height: 120.9%;
/* or 33px */

letter-spacing: 0.01em;

color: rgba(255, 255, 255, 0.8);

opacity: 0.85;
margin-bottom: 20px;
}
}
@media (max-width: 480px) {
    .card-1 {

width: 100%;

}
.card-2 {

width: 100%;
margin-left: 0;



}
.card-3 {

width: 100%;


}
.card-4 {

width: 100%;
margin-left: 0;

}
.card-heading{
    font-size: 18px;
    font-weight: 500;

}
.card-desc{
    font-size: 14px;
}
}

</style>

