<template>
  <section>
    <!--page title start-->
    <section class="page-title background-title dark banner-contact">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3 class="text-uppercase">Front-end Engineering</h3>
            <span
              >Design is not just what it looks like &amp; feels like.<br />Design
              is how it works. - Steve Jobs</span
            >
          </div>
        </div>
      </div>
    </section>
    <!--page title end-->
    <!--body content start-->
    <div class="col-container">
      <div class="col">
        <div class="icon">
          <i class="icon-lightbulb icon-color"></i>
        </div>
        <h2 class="align-item">REACT.JS</h2>
        <p class="p-desc">
          JS framework by Facebook to create large-scale and complex web
          applications with powerful plugins and libraries.
        </p>
      </div>
      <div class="col">
        <div class="icon">
          <i class="icon-browser2 icon-color"></i>
        </div>
        <h2 class="align-item">ANGULAR</h2>
        <p class="p-desc">
          JS framework by Google to create simple, progressive web apps that is
          efficient in coding and development time.
        </p>
      </div>

      <div class="col">
        <div class="icon">
          <i class="icon-laptop2 icon-color"></i>
        </div>
        <h2 class="align-item">VUE.JS</h2>
        <p class="p-desc">
          Vue.js is a progressive JavaScript framework, which is used to build
          UIs and SPAs. It’s famous for its fast-paced learning curve.
        </p>
      </div>
    </div>
    <div class="col-container">
      <div class="col">
        <div class="icon">
          <i class="icon-basic_gear icon-color"></i>
        </div>
        <h2 class="align-item">JAVASCRIPT</h2>
        <p class="p-desc">
          Lightweight, interpreted, or just-in-time compiled programming
          language with first-class functions.
        </p>
      </div>

      <div class="col">
        <div class="icon">
          <i class="icon-basic_pencil_ruler icon-color"></i>
        </div>
        <h2 class="align-item">SASS & SCSS</h2>
        <p class="p-desc">
          Syntactically Awesome or Sassy Cascading, amazing frameworks to style
          your web applications.
        </p>
      </div>

      <div class="col">
        <div class="icon">
          <i class="icon-basic_todolist_pencil icon-color"></i>
        </div>
        <h2 class="align-item">TYPESCRIPT</h2>
        <p class="p-desc">
          An excellent option for the development of large applications that
          transpiles to JavaScript.
        </p>
      </div>
    </div>
    <section class="body-content">
      <div class="page-content">
        <!--post style 5 start-->
        <div class="post-list-aside">
          <div class="container">
            <div class="row post-single e-position">
              <div class="col-md-6 ss-vertical-align">
                <div class="post-img">
                  <img src="assets/img/post/frontend-2.png" alt="" />
                </div>
              </div>
              <div class="col-md-6 pull-right">
                <div class="custom-desc">
                  <h1>
                    We create products that looks stunning on all screens.
                  </h1>

                  <p>
                    Looks great, loads fast, and works without a teeny-tiny
                    hiccup – here’s how your final product will look like.
                  </p>

                  <p>
                    We create interfaces users can’t help, but love. Our
                    front-end ninjas ensure that each product is made with great
                    care to make it look equally stunning on large desktop
                    screens and mobile devices. Smooth, fast, and intuitive
                    navigation comes as a guaranteed part of our front-end
                    programming services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--post style 5 end-->
      </div>
    </section>
    <!--body content end-->
  </section>
</template>

<script>
export default {
  name: "Frontent",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped></style>
