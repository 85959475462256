<template>
  <section>
    <CommonBanner
      styleHeading="Product"
      heading="Development"
      paragraph=" At the heart of every product person, there’s a desire to
              make someone’s life easier. - Francis Brown"
    />

    <!-- <div class="row">
            <div class="heading-title text-center">
                 <h3 class="text-uppercase">A brand is a voice </h3>
            </div>
        </div> -->
    <div class="col-container">
      <div class="col">
        <div class="icon">
          <i class="icon-lightbulb icon-color"></i>
        </div>
        <h2 class="align-item">Requirement Gathering</h2>
        <p class="p-desc">
          Your insight on the product enables us to strategize, plan, develop
          and execute the concepts.
        </p>
      </div>

      <div class="col">
        <div class="icon">
          <i class="icon-browser2 icon-color"></i>
        </div>
        <h2 class="align-item">Wireframing</h2>
        <p class="p-desc">
          Develop a basic structure for product layout through mock-ups, to
          clarify the product functionality.
        </p>
      </div>

      <div class="col">
        <div class="icon">
          <i class="icon-laptop2 icon-color"></i>
        </div>
        <h2 class="align-item">Design</h2>
        <p class="p-desc">
          Add design aesthetics to produce a real-time look and feel of the
          product with color pallete, font styles & more.
        </p>
      </div>
    </div>

    <div class="col-container">
      <div class="col">
        <div class="icon">
          <i class="icon-basic_gear icon-color"></i>
        </div>
        <h2 class="align-item">Sprint Planning</h2>
        <p class="p-desc">
          Streamline work-flow, set plan in motion, determine the deadlines, ∓
          estimate project completion timeline.
        </p>
      </div>

      <div class="col">
        <div class="icon">
          <i class="icon-basic_pencil_ruler icon-color"></i>
        </div>
        <h2 class="align-item">Development</h2>
        <p class="p-desc">
          Initiation of product development starts in this phase. This process
          is backed by sprint plan and design.
        </p>
      </div>

      <div class="col">
        <div class="icon">
          <i class="icon-basic_todolist_pencil icon-color"></i>
        </div>
        <h2 class="align-item">Maintenance</h2>
        <p class="p-desc">
          After the completion of the product development, we offer maintenance
          and support services for the clients.
        </p>
      </div>
    </div>

    <section class="body-content">
      <div class="page-content">
        <!--post style 5 start-->
        <div class="post-list-aside">
          <div class="container">
            <div class="row post-single e-position">
              <div class="col-md-6 ss-vertical-align">
                <div class="post-img">
                  <img src="assets/img/post/product.png" alt="" />
                </div>
              </div>
              <div class="col-md-6 pull-right">
                <div class="custom-desc">
                  <h1>Take your business to the next level.</h1>

                  <p>
                    It's time for every business to have software solutions
                    tailored to their specific services. A business can no
                    longer afford to have generic digital products or services
                    that are identical to those of its rivals.
                  </p>

                  <p>
                    This is where we swoop in to make things easy for you. Our
                    team of experienced professionals are well equipped to
                    collaborate closely with clients and successfully build
                    high-performing product solutions in a variety of shapes and
                    sizes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--post style 5 end-->
      </div>
    </section>
  </section>
</template>

<script>
import CommonBanner from "./common/CommonBanner.vue";

export default {
  name: "Product",
  mounted() {
    window.scrollTo(0, 0);
  },
  components: { CommonBanner },
};
</script>

<style scoped>
.services-h span {
  font-family: "rubiklight" !important;
}
</style>
