<template>
  <section class="mt-5">
    <div class="row portfolio-container">
      <div class="col-sm-12 col-md-12 col-lg-6">
        <img src="/images/znapshot/znapshot-logo.png" alt="" class="logo-img">
        <h1 class="main-heading">
          turning your mobile experience into pleasure
        </h1>
       
        <h2 class="sub-heading">
          technologies
        </h2>
        <div class="tech-img">
         <div class="tech-grid">
            <img src="/images/znapshot/flutter.png" alt="">
          
            <img src="/images/znapshot/java spring.png" alt="">
          
            <img src="/images/znapshot/vue.png" alt="">
          
         
            <img src="/images/znapshot/nuxt.png" alt="">
          
          
            <img src="/images/znapshot/google cloud.png" alt="">
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6">
        <div class="flex">
            <img src="/images/znapshot/iphone.png" alt="">
        </div>
      </div>
      <div class="second-grid">
        <div>
          <div class="inrto-img">
            <img src="/images/znapshot/designstripe.png" alt="">
          </div>
        </div>
        <div>
          <h1 class="main-heading">
            introduction
          </h1>
          <h2 class="sub-heading">
            Working in conjunction with Znapshot
          </h2>
          <p class="znap-p">
            Wallpapers play a silent but strong role in our daily life. 
            It reinforces and reiterates the image and its hidden message. 
            For decades now, art was only to be found in museum halls and 
            lounges of the privileged. Znapshot is here to decimate the 
            old ways and bring about a long-needed innovation in the 
            wallpaper industry. It introduces art pieces that replace 
            the dull wallpapers. Pieces that are meaningful and have 
            a deeper outlook than just your ordinary images. It further 
            allows you to choose and constantly run your favourite art 
            pieces, collections, and libraries on rotation.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">our integration</h1>
      </div>
      <div class="col-sm-12 col-md-8 col-lg-8">
        <div class="integration-class">
          <p class="znap-p">
            We have developed the Znapshot app for both Android and iOS, 
            to make it accessible to our customers across both platforms. 
            With Znapshot you don’t have to set a wallpaper to have a 
            preview since the Znapshot app allows you to preview the 
            wallpaper before even setting it.
          </p>
        </div>
      </div>
      
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="integration-img">
          <img src="/images/znapshot/group 1.png" alt="">
        </div>

      </div>
      <div class="second-grid">
        <div>
          <div class="img">
            <img src="/images/znapshot/group 2.png" alt="">
          </div>
        </div>
        <div>
          <p class="znap-p">
            The inbuilt wallpaper preview simulator allows you 
            not only to preview how an art piece will look on 
            your current device but also how it will be 
            previewed on all the other devices that you 
            have signed in using your profile.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">outcome</h1>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="integration-class">
          <p class="znap-p outcome-p">
            We have put out an app that smoothly runs and displays 
            all your favourite wallpapers and art pieces. 
            No need to worry about setting or changing your 
            wallpaper every other week. Znapshot automatically 
            updates the art pieces of artists on the app. 
            The app has an inbuilt 15 minutes rotation 
            setting if chosen, to run your pieces on rotation. 
            All your favourite artists can be found on the app 
            to choose from. You could create multiple collections 
            of your pieces and also include artworks in your library. 
            It allows you to run and control all your devices by 
            logging them in using a single profile. With Znapshot 
            we have created a solution for all art lovers.
          </p>
          <img src="/images/znapshot/hand.png" alt="" class="outcome-img">
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12" v-show="!isMobile">
        <div  class="grid-1">
      <div v-for="(slide, index) in slides" :key="index" >
        <img :src="slide" alt="Slide">
    </div>
  </div>
</div>
  <div class="col-sm-12 col-md-12 col-lg-12" v-show="isMobile">
        <VueSlickCarousel  v-bind="settings" class="testimonials-container">
            <div class="slide" v-for="(slide, index) in slides" :key="index">
              <img :src="slide" alt="" class="slide-image">
            </div>
        </VueSlickCarousel>
      </div>
      <div class="col-sm-12 col-md-2 col-lg-2"></div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="center-img">
          <img src="/images/znapshot/group3.png" alt="">
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6"></div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">client testimonials </h1>
      </div>
      <div class="col-sm-12 col-md-8 col-lg-8 mt-5">
        <div class="testimonial">
          <div class="comment-flex">
            <div class="img">
              <img src="/images/znapshot/ceo.png" alt="">
            </div>
            <div>
              <p>Anton Brew</p>
              <span>Product Owner - Znapshot</span>
            </div>
          </div>
          <p class="testimonial-p">
            “Working with Septem Systems has been a wonderful experience. 
            I thoroughly appreciate the thoughtful, fully engaged, 
            and above and beyond work/awareness that Septem Systems 
            brings to the table. Their work has been exceptional, 
            timely, and collaborative. I look to have a very long-lasting 
            business relationship and highly recommend them for app 
            development and much, much more.”
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4 mt-5">
        <div class="g2-img">
          <img src="/images/znapshot/frame.png" alt="">
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: 'ZnapshotCaseStudy',
  components: {
    VueSlickCarousel,
  },
  data(){
    return{
      slides: [
        "/images/znapshot/iphone 14 pro-4.png",
        "/images/znapshot/iphone 14 pro.png",
        "/images/znapshot/iphone 13.png",
        "/images/znapshot/iphone 14 pro-1.png",
        "/images/znapshot/iphone 14 pro-3.png"
      ],
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 0,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
      },
    };
  },    
  computed: {
    isMobile(){
      return window.matchMedia("(max-width: 480px)").matches;
    },  
  },

}
</script>

<style scoped>
.testimonial{
  position: relative;
  background-color: #151515;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  height: auto;
}
.testimonial .comment-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.testimonial .testimonial-p{
  font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 600;
  line-height: 1.5;
}
.slider {
  position: relative;
  overflow: hidden;
  background: transparent;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  width: 100%;
  height: auto;

  
}

.slide-image {
  width: 80%;
  height: auto;
  margin: 10% 10%;
}


.second-grid .img {
  width: 100%;
  height: auto;
}
.center-img{
  width: 100%;
  width: auto;
  margin: 10% 0;
}
.center-img img{
  width: 100%;
  height: 100%;
}

.second-grid .img img {
  width: 100%;
  height: 100%;
}

.integration-class .comment-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.main-heading span {
  color: rgb(46, 179, 127);
}

.comment-flex .img {
  width: 50px;
  height: auto;
  border-radius: 50%;
  margin-left: 0px;
  padding: 0px 10px 0px 0px;
}

.comment-flex .img img {

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  border: 1px solid #00b398;
}

.comment-flex p {
 color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin: 0px !important;
  
}

.comment-flex span {
  
   color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;

}
.znap-p{
  text-align: left;
  font-size: 1.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.g2-img {
  margin-top: -50%;
  width: 100%;
  height: auto;
}

.g2-img img {
  width: 100%;
  height: 100%;
}

.full-img {
  width: 100%;
  height: auto;
  margin: 20px 0px;
}

.full-img img {
  height: 100%;
  width: 100%;
}

.integration-class {
  position: relative;
  background-color: #151515;
  padding: 50px;
  border-radius: 5rem;
  width: 100%;
  height: auto;
}
.integration-class .outcome-img{
  position: absolute;
  width: 30%;
  height: auto;
  bottom: 0;
  right: 0;
}
.integration-class .outcome-p {
  padding-right: 30%;
}

.integration-img {
  width: 100%;
  height: auto;
}

.integration-img img {
  width: 100%;
  height: 100%;
}

.second-grid .sub-heading span {
  color: rgb(46, 179, 127);
}

.second-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: auto;
  gap: 40px;
  padding: 10% 0;
  align-items: center;
}

.logo-img {
  padding: 20% 0px 10% 0px;
  width: 130px;
  height: 100%;
}

.second-grid .inrto-img {
  width: 100%;
  height: auto;
}

.second-grid .inrto-img img {
  width: 100%;
  height: 100%;
}


.second-grid .main-heading {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.5px;
  padding-bottom: 5px;
  line-height: 45px;
  text-transform: capitalize;
}

.main-heading {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.5px;
  padding-bottom: 5px;
  line-height: 45px;
  text-transform: capitalize;
}

.second-grid.sub-heading {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.sub-heading {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.tech-img {
  width: 70%;
  height: auto;
}
.tech-img .col-sm-2 img{
  width:100%;
  height: 100%;
}

.tech-img .tech-grid {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.5fr 1.5fr 1.5fr;
  grid-template-rows: auto;
  gap: 2rem;
}
.tech-grid img{
  width: 100%;
  height: 100%;
}
.grid-1 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
  margin-top: 15%;
}

.grid-1 img {
  width: 100%;
  height: 100%;
}
.grid-1 div:nth-child(2) {
  margin-top: -50%;
  width: 100%;
  height: 100%;
}
.grid-1 div:nth-child(3) {
  margin-top: -70%;
  width: 100%;
  height: 100%;
}
.grid-1 div:nth-child(4) {
  margin-top: -50%;
  width: 100%;
  height: 100%;
}



.flex {
  width: 100%;
  height: auto;
  padding-top: 15%;
}

.flex img {
  width: 100%;
  height: 100%;
}
@media(max-width: 768px) {

  .integration-class .outcome-img{
    display: none;
  }

  .integration-class .outcome-p {
    padding: 10px;
  }
  .second-grid.section-2 div:first-child{
    order: 0;
  }

  .second-grid {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 10px 15px;
  }

  .second-grid div:first-child {
    order: 1;
  }
  .inrto-img {
    width: 100%;
    height: auto;
    padding: 5px 0px;
  }

  .second-grid.main-heading {
    font-size: 30px;
    font-weight: 700;
  }

  .second-grid.sub-heading {
    font-size: 24px;
    font-weight: 700;
  }

  .logo-img {
    padding-top: 70px;
    width: 100px;
    height: 100%;
  }

}

@media(max-width: 480px) {
  .integration-class{
    padding: 10px 20px;
    border-radius: 20px;
  }
  .znap-p {
    font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 500;
  line-height: 1.7;
  text-align: justify;
  }
  .testimonial-p{
    text-align: justify;
    font-weight: 400;
  }
  .integration-img, .inrto-img {
    margin: 50px 0 45px 0;
  }
  
  .integration-class .znap-p {
    font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 500;
  line-height: 1.7;
  text-align: justify;

  }
  .integration-class .outcome-p {
    padding: 0;
  }

  .second-grid.main-heading {
    font-size: 20;
  }
  
  .integration-class .znap-p {
    font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 500;
  line-height: 1.7;
  text-align: justify;

  }

  .second-grid.sub-heading {
    font-size: 16px;
  }
  .g2-img{
    margin: 40px auto 20px;
  }
  .tech-img{
    width: 60%;
  }


}</style>
