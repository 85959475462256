<template>
  <div id="app">
    <div class="wrapper">
      <Header />
      <router-view />
      <Footer />
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/layout_components/Header";
import Footer from "@/components/layout_components/Footer";
export default {
  name: "app",
  components: {
    Header,
    Footer,
  },
  computed: {},
};
</script>
<style>
.wrapper {
  overflow-x: hidden;
}
/*#app {*/
/*  font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*}*/

/*#nav {*/
/*  padding: 30px;*/
/*}*/

/*#nav a {*/
/*  font-weight: bold;*/
/*  color: #2c3e50;*/
/*}*/

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
