var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"row portfolio-container"},[_vm._m(0),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_c('h1',{staticClass:"main-heading"},[_vm._v(" controll your all smart devices from anywhere, anytime ")]),_c('div',{staticClass:"main-flex"},_vm._l((_vm.mainHeading),function(item,index){return _c('div',{key:index},[_c('h4',[_vm._v(_vm._s(item.title))]),_c('span',[_vm._v(_vm._s(item.description))])])}),0)]),_c('div',{staticClass:"col-sm-12 col-md-2 col-lg-2"}),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"col-sm-12 col-md-4 col-lg-4"}),_vm._m(4),_c('div',{staticClass:"col-sm-12 col-md-4 col-lg-4"}),_vm._m(5),_c('div',{staticClass:"col-sm-12 col-md-3 col-lg-3"}),_vm._m(6),_c('div',{staticClass:"col-sm-12 col-md-3 col-lg-3"}),_vm._m(7),_vm._m(8),_vm._m(9),_c('div',{staticClass:"col-sm-12 col-md-3 col-lg-3"}),_vm._m(10),_c('div',{staticClass:"col-sm-12 col-md-3 col-lg-3"}),_vm._m(11),_vm._m(12),_vm._m(13),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('h2',{staticClass:"sub-heading"},[_vm._v("design process")]),_c('div',{staticClass:"feature-flex"},_vm._l((_vm.designs),function(design,index){return _c('div',{key:index,staticClass:"data-flex"},[_c('img',{attrs:{"src":design.url,"alt":""}}),_c('span',[_vm._v(_vm._s(design.description))])])}),0)]),_vm._m(14),_vm._m(15),_c('div',{staticClass:"col-sm-12 col-md-3 col-lg-3"}),_vm._m(16),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('div',{staticClass:"skeleton-grid"},_vm._l((_vm.skeletons),function(skeleton,index){return _c('div',{key:index,staticClass:"data-flex"},[_c('span',[_vm._v(_vm._s(skeleton.description))]),_c('div',{staticClass:"main-img"},[_c('img',{staticStyle:{"height":"100% !important","margin-top":"1.5em"},attrs:{"src":skeleton.url,"alt":""}})])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":"/images/g-tensor/logo.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-4 col-lg-4"},[_c('div',{staticClass:"main-img"},[_c('img',{attrs:{"src":"/images/g-tensor/main-img.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row px-5"},[_c('div',{staticClass:"col-md-12"},[_c('h2',{staticClass:"sub-heading"},[_vm._v("overview")]),_c('p',{staticClass:"paragraph"},[_vm._v(" In an era of advancing technology and interconnected devices, the GTensor App takes center stage as a comprehensive solution for home automation. By integrating various smart devices and appliances, the app empowers users to control their living environment with the touch of a button, ensuring comfort, energy conservation, and security. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('h2',{staticClass:"sub-heading"},[_vm._v("onboarding")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-4 col-lg-4"},[_c('p',{staticClass:"paragraph"},[_vm._v(" An easy & featured based onboarding screen for user before creating an account ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 mb-5"},[_c('div',{staticClass:"main-img onboard"},[_c('img',{attrs:{"src":"/images/g-tensor/onboard-img.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_c('h2',{staticClass:"sub-heading"},[_vm._v("signing in, forget password & reset")]),_c('p',{staticClass:"paragraph"},[_vm._v(" User can create account by entering their name, email and password, or by social logins user can reset their by entering their email address, through email they can reset. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12",staticStyle:{"margin":"2em 0"}},[_c('div',{staticClass:"main-img"},[_c('img',{attrs:{"src":"/images/g-tensor/password-img.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-grid"},[_c('div',{staticClass:"main-img order"},[_c('img',{attrs:{"src":"/images/g-tensor/home-screen.png","alt":""}})]),_c('div',{staticClass:"center"},[_c('h2',{staticClass:"sub-heading",staticStyle:{"text-align":"left"}},[_vm._v("home screen")]),_c('p',{staticClass:"paragraph",staticStyle:{"text-align":"left"}},[_vm._v(" The GTensor App offers a sophisticated and user-centric approach to managing smart devices within the home. Through its elegant design and seamless functionality, users are presented with a comprehensive overview of their connected devices and living spaces right from the home screen. This strategic layout not only ensures efficient access but also enhances the overall user experience. ")]),_c('p',{staticClass:"paragraph",staticStyle:{"text-align":"left","font-weight":"bold","margin-top":"3em"}},[_c('ul',[_c('li',[_vm._v(" Holistic View ")]),_c('li',[_vm._v(" Streamlined Device Management ")]),_c('li',[_vm._v(" Effortless Interaction ")]),_c('li',[_vm._v(" Intelligent Room and"),_c('br'),_vm._v(" Device Grouping ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-grid"},[_c('div',{staticClass:"center"},[_c('h2',{staticClass:"sub-heading",staticStyle:{"text-align":"left"}},[_vm._v(" statistics & menu ")]),_c('p',{staticClass:"paragraph",staticStyle:{"text-align":"left"}},[_vm._v(" The GTensor App offers users a comprehensive insight into their household's financial and energy-related aspects through its detailed monthly expense and power consumption statistics. This feature empowers users to make informed decisions about their resource usage and budget allocation, enhancing their control over their home environment. ")]),_c('p',{staticClass:"paragraph",staticStyle:{"text-align":"left","font-weight":"bold","margin-top":"3em"}},[_c('ul',[_c('li',[_vm._v(" In-Depth Analysis ")]),_c('li',[_vm._v(" Power Consumption Insights ")]),_c('li',[_vm._v(" Device Management ")]),_c('li',[_vm._v(" Account and Settings ")]),_c('li',[_vm._v(" Support and Help ")])])])]),_c('div',[_c('div',{staticClass:"main-img"},[_c('img',{attrs:{"src":"/images/g-tensor/statics-img.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_c('h2',{staticClass:"sub-heading"},[_vm._v(" add a device & device configuration ")]),_c('p',{staticClass:"paragraph"},[_vm._v(" User can create account by entering their name, email and password, or by social logins user can reset their by entering their email address, through email they can reset. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12",staticStyle:{"margin-top":"4em"}},[_c('div',{staticClass:"main-img"},[_c('img',{attrs:{"src":"/images/g-tensor/add-device-img.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-grid",staticStyle:{"margin":"2em 0 !important"}},[_c('div',{staticClass:"main-img order"},[_c('img',{attrs:{"src":"/images/g-tensor/device-screens-img.png","alt":""}})]),_c('div',{staticClass:"center"},[_c('h2',{staticClass:"sub-heading",staticStyle:{"text-align":"left"}},[_vm._v("device screens")]),_c('p',{staticClass:"paragraph",staticStyle:{"text-align":"left"}},[_vm._v(" The GTensor App introduces a seamless and interactive approach to managing room devices, offering users unparalleled control over their smart home environment. Additionally, the app's innovative Economy Mode enhances power-saving capabilities, fostering a more efficient and sustainable living experience. ")]),_c('p',{staticClass:"paragraph",staticStyle:{"text-align":"left","font-weight":"bold","margin-top":"3em"}},[_c('ul',[_c('li',[_vm._v(" Interactive Device Management ")]),_c('li',[_vm._v(" Effortless Device Navigation ")]),_c('li',[_vm._v(" Real-Time Feedback ")]),_c('li',[_vm._v(" Economy Mode ")]),_c('li',[_vm._v(" Adaptive Device Settings ")]),_c('li',[_vm._v(" User-Friendly Toggle ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-grid"},[_c('div',{staticClass:"center"},[_c('h2',{staticClass:"sub-heading",staticStyle:{"text-align":"left"}},[_vm._v(" scheduling devices ")]),_c('p',{staticClass:"paragraph",staticStyle:{"text-align":"left"}},[_vm._v(" The GTensor App streamlines the process of scheduling individual devices, allowing users to seamlessly automate their smart home ecosystem. This functionality empowers users to create customized routines that enhance convenience, energy efficiency, and overall control. ")]),_c('p',{staticClass:"paragraph",staticStyle:{"text-align":"left","font-weight":"bold","margin-top":"3em"}},[_c('ul',[_c('li',[_vm._v(" Simple and Intuitive Scheduling ")]),_c('li',[_vm._v(" Customised Routines ")]),_c('li',[_vm._v(" Efficiency and Energy Savings ")]),_c('li',[_vm._v(" Seamless Integration ")]),_c('li',[_vm._v(" Easy Adjustment ")])])])]),_c('div',{staticClass:"main-img order"},[_c('img',{attrs:{"src":"/images/g-tensor/schedule-img.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 custom-border"},[_c('h2',{staticClass:"sub-heading"},[_vm._v("problem statement")]),_c('p',{staticClass:"paragraph"},[_vm._v(" As the number of smart home devices continues to grow, users face the challenge of managing an increasingly complex network of devices and applications. Users are burdened with the task of navigating multiple apps, each with its own interface and functionalities, resulting in a fragmented and inefficient user experience. ")]),_c('span',{staticClass:"b-left"}),_c('span',{staticClass:"b-right"}),_c('span',{staticClass:"b-b-left"}),_c('span',{staticClass:"b-b-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_c('h2',{staticClass:"sub-heading text-left"},[_vm._v(" business & goals ")]),_c('p',{staticClass:"paragraph",staticStyle:{"text-align":"left"}},[_vm._v(" The primary business goal is to create a user-friendly smart home app that consolidates device monitoring and control, attracting a large user base, promoting user retention, and driving high customer satisfaction. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-3 col-lg-3"},[_c('div',{staticClass:"main-img custom-setting"},[_c('img',{attrs:{"src":"/images/g-tensor/business-img.png","alt":""}})])])
}]

export { render, staticRenderFns }