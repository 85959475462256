<template>
    <div>
        <section>
            <div class="row portfolio-container">
                <div class="logo">
                    <img src="/images/bayked/logo.svg" alt="">
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <h1 class="main-heading">
                        your pizza, your way, delivered with delight.
                    </h1>
                    <div class="main-flex">
                        <div v-for="(item, index) in mainHeading" :key="index">
                            <h4>{{ item.title }}</h4>
                            <span>{{ item.description }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <div class="main-img">
                        <img src="/images/bayked/main-img.png" alt="">
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <h2 class="sub-heading">overview</h2>
                    <p class="paragraph">
                        The Bayked mobile app is a cutting-edge pizza delivery
                        platform that partners with local and international
                        pizzerias, offering customers exclusive daily deals
                        from all partner restaurants. The app's mission is to
                        redefine the pizza delivery experience by providing
                        users with hot, fresh, and timely pizzas straight to
                        their doorsteps. With a focus on convenience and a love
                        for pizza, the Bayked mobile app aims to be the ultimate
                        destination for pizza enthusiasts, granting them access
                        to the best pizzerias in town through a user-friendly
                        and intuitive mobile application.
                    </p>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <h2 class="sub-heading">wireframes</h2>

                </div>
            </div>
        </section>
        <div class="main-img">
            <img src="/images/bayked/full-img.png" alt="" v-if="!isMobile">
            <img src="/images/bayked/full-img-mobile.png" alt="" v-if="isMobile">
        </div>
        <div class="row portfolio-container">
            <h2 class="sub-heading">UI design</h2>
            <div class="col-sm-12 col-md-2 col-lg-2"></div>
            <div class="col-sm-12 col-md-8 col-lg-8">
                <div class="main-img ui-img">
                    <img src="/images/bayked/ui-design.png" alt="" v-if="!isMobile">
                    <img src="/images/bayked/ui-mobile.png" alt="" v-if="isMobile">
                </div>
            </div>
            <div class="col-sm-12 col-md-2 col-lg-2"></div>
            <div class="col-sm-12 col-md-12 col-lg-12">
                <h2 class="sub-heading">home screen</h2>
                <div class="main-img">
                    <img src="/images/bayked/home-screen.png" alt="" v-if="!isMobile">
                    <img src="/images/bayked/home-screen-mobile.png" alt="" v-if="isMobile">
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
            <h2 class="sub-heading">all screens</h2>
        </div>
        <div class="main-img">
            <img src="/images/bayked/all-screen.png" alt="" v-if="!isMobile">
            <img src="/images/bayked/all-screen-mobile.png" alt="" v-if="isMobile">
        </div>
        <div class="row portfolio-container">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <h2 class="sub-heading">web design</h2>
            <div class="main-img">
                <img src="/images/bayked/web-design.png" alt="" v-if="!isMobile">
                <img src="/images/bayked/web-design-mobile.png" alt="" v-if="isMobile">
            </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12">
                <h2 class="sub-heading">design timeline</h2>
            <div class="main-img">
                <img src="/images/bayked/timeline.png" alt="" v-if="!isMobile">
                <img src="/images/bayked/timeline-mobile.png" alt="" v-if="isMobile">
            </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12">
                <h2 class="sub-heading">user journey map</h2>
            </div>
        </div>
        <div class="main-img">
                <img src="/images/bayked/user-journey.png" alt="" v-if="!isMobile">
                <img src="/images/bayked/user-journey-mobile.png" alt="" v-if="isMobile">
            </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            mainHeading: [
                {
                    title: 'platform',
                    description: 'Mobile Application'
                },
                {
                    title: 'deliverables',
                    description: 'Research, UI/UX, Design System, Development'
                },
            ]
        }
    },

    computed: {
        isMobile() {
            return window.matchMedia("('max-width: 480px')").matches
        }
    }

}
</script>

<style  scoped>
section {
    background: linear-gradient(180deg, #000000 -303.13%, rgba(53, 228, 214, 0.4) -105.45%, #020103 96.38%);

}

.logo {
    margin-top: 3em;
    padding-left: 1em;
    margin-bottom: 2em;
}

.main-img {
    width: 100%;
}

.main-img img {
    width: 100%;
    height: auto;
}

.ui-img {
    width: 60%;
    margin: 2em auto;
}

.main-img img {
    width: 100%;
    height: auto;
}

.main-heading {
    font-size: 3.5em;
    font-weight: 800;
    letter-spacing: 0.3px;
    text-transform: capitalize;
    margin-bottom: 1.5em;
}

.main-flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.main-flex h4 {
    font-size: 1.75em;
    font-weight: 600;
    text-transform: capitalize;
}

.main-flex span {
    color: #fff !important;
    font-size: 1em;
    font-weight: 400;

}

.sub-heading {
    font-size: 2.5em;
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
    margin: 1.5em 0 1em 0;
}

.paragraph {
    font-size: 1.25em;
    font-weight: 400;
    letter-spacing: 0.3px;
    text-align: center;
    color: #fff;
}

@media (min-width:1440px) {
    .paragraph {
        font-size: 1.5em;
    }

    .sub-heading {
        font-size: 3em;
    }

    .main-heading {
        font-size: 4em;
        margin-bottom: 2em;
    }

    .main-flex h4 {
        font-size: 2em;
        font-weight: 600;
        text-transform: capitalize;
    }

    .main-flex span {
        color: #fff !important;
        font-size: 1.5em;
        font-weight: 400;

    }
}
@media (max-width:768px) {
    .paragraph {
        font-size: .75em;
        text-align: center;
        line-height: 1.5em;
    }
    .ui-img {
    width: 80%;
    margin: 1em auto;
}

    .sub-heading {
        font-size: 2em;
    }

    .main-heading {
        font-size: 3.5em;
        margin-bottom: 1em;
    }
    .main-flex{
        margin-bottom: 5em;
    }

    .main-flex h4 {
        font-size: 1.5em;
        font-weight: 500;
        text-transform: capitalize;
    }

    .main-flex span {
        color: #fff !important;
        font-size: 1em;
        font-weight: 400;

    }
}
@media (max-width:480px) {
    .ui-img {
    width: 100%;
    margin: 1em auto;
}
    .paragraph {
        font-size: 1em;
        line-height: 1.5em;
        text-align: justify;
    }

    .sub-heading {
        font-size: 1.5em;
    }

    .main-heading {
        font-size: 2.15em;
        line-height: 1.25em;
        letter-spacing: normal;
        font-weight: 900;
        margin-bottom: .5em;
    }

    .main-flex h4 {
        font-size: 1em;
        font-weight: 600;
        line-height: 0.5em;
        margin-bottom: 0;
        text-transform: capitalize;
    }
    .main-flex{
        margin-bottom: 2em;
    }
    .main-flex span {
        color: #fff !important;
        font-size: .65em;
        font-weight: 400;

    }
}
</style>