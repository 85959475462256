<template>
  <div class="testimonials">
    <div class="border-short-bottom text-center testimonial-heading-mobile">
      <h3>Testimonials</h3>
    </div>
    <div class="border-short-bottom text-center testimonial-heading-web">
      <h3>What Our Clients Say About Us</h3>
    </div>
    <VueSlickCarousel v-bind=" settings " class="testimonials-container">
      <div class="client-aboutus-card"
        v-for="(                testimonial, index                ) in                 testimonials                "
        :key=" index ">
        <div class="client-aboutus-card-inner">
          <div class="client-card-avatar">
            <img :src=" require(`../../public/assets/img/testimonial/clients/${ testimonial.clientAvatar }`)
              " alt="" />
          </div>
          <h3 class="client-aboutus-name">
            {{ testimonial.clientName }}
          </h3>
          <p class="client-aboutus-role">
            {{ testimonial.clientDesignation }}
          </p>
          <!-- <span class="client-svg">
            <img
              :src="
                require(`../../public/assets/img/testimonial/${testimonial.stars}`)
              "
              alt=""
            />
          </span> -->
          <p class="client-paragraph">
            {{ testimonial.clientComment }}
          </p>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: "Testimonials",
  data() {
    return {
      testimonials: [
        {
          clientAvatar: "bigscoot.png",
          stars: "stars.svg",
          clientName: "Scott Stapely",
          clientDesignation: "Co-Founder & CEO Bigscoots",
          clientComment:
            "We are very impressed with Team Septem. They have been incredibly responsive even during off-hours, worked to resolve complex issues diligently. They built exactly what we were looking for.",
        },

        {
          clientAvatar: "conduktor.png",
          stars: "stars.svg",
          clientName: "Stephane Deroiaux",
          clientDesignation: "Co-Founder & CTO, Conduktor",
          clientComment:
            "They helped us build a tremendously high-quality customer portal from scratch. Thanks to their experience and knowledge, they have dealt with every aspect of the project and answered all our needs in a timely manner.",
        },
        {
          clientAvatar: "kol-ai.png",
          stars: "stars.svg",
          clientName: "Kyle Stephens",
          clientDesignation: "CEO & Co-Founder, KolAi Denim",
          clientComment:
            "Excellent experience with Septem Systems all around. Communication was easy and timely, work was done promptly and well with minimal bugs, and rapid bug fixes when they did arise. The team has a wide skill set.",
        },
        {
          clientAvatar: "blingrock.png",
          stars: "stars.svg",
          clientName: "Constantin Tyhssen",
          clientDesignation: "CEO, BlingRock",
          clientComment:
            "While the complex nature of our project was intimidating for many companies, Septem Systems took up the challenge and gave life to our ideas. We always felt at the centre of the attention and felt like a team member rather than a client.",
        },
        {
          clientAvatar: "anton.png",
          stars: "stars.svg",
          clientName: "Anton Brew",
          clientDesignation: "Product Owner , Znapshot",
          clientComment:
            "Working with Septem Systems has been a wonderful experience. I thoroughly appreciate the thoughtful, fully engaged, and above and beyond work/awareness that Septem Systems brings to the table.",
        },
        {
          clientAvatar: "majid-mirza.png",
          stars: "stars.svg",
          clientName: "Majid Mirza",
          clientDesignation: "Co-Founder/Ceo ESGTree",
          clientComment:
            "I think the “difference that makes a difference” with Septem is that they don’t just work from the mind but the heart as well. This is key in an ever-changing world where humanizing client interactions is key for success.",
        },
      ],
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 0,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0,
            },
          },
        ],
      },
    };
  },
  computed: {
    sidebarOpen() {
      return this.$store.state.sidebar;
    },
  },
  components: {
    VueSlickCarousel,
  },
};
</script>
<style scoped>
.slick-slide:nth-child(odd) {
  display: flex;
  justify-content: flex-end;
  padding-right: 80px;
}

.slick-slide:nth-child(even) {
  display: flex;
  justify-content: flex-start;
  padding-left: 80px;
}
</style>
