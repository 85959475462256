<template>
  <section>
    <div class="row portfolio-container">
      <div class="col-sm-12 col-md-12 col-lg-6">
        <img src="/images/conduktor/conduktor-logo.png" alt="" class="logo-img">
        <h1 class="main-heading">
          Development of End-to-End Subscription Management on Conduktor
        </h1>
        <p class="conduktor-p">
          With More than 8,000 organizations and 60,000 
          developers working alongside Conduktor, they help 
          companies operate their data using Apache Kafka via 
          an Apache Kafka Desktop Client, allowing their users 
          with conclusive ease. As their technology partner, 
          Septem Systems enabled Conduktor’s users to manage 
          their subscriptions swiftly.
        </p>
        <h2 class="sub-heading">
          technologies
        </h2>
        <div class="tech-img">
          <div class="grid">
            <img src="/images/conduktor/vue.png" alt="">
            <img src="/images/conduktor/nuxt.png" alt="">
            <img src="/images/conduktor/micro-naut.png" alt="">
            <img src="/images/conduktor/chargebee.png" alt="">
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6">
        <div class="flex">
          <img src="/images/conduktor/main.png" alt="">
        </div>
      </div>
      <div class="second-grid">
        <div>
          <div class="inrto-img">
            <img src="/images/conduktor/security.png" alt="">
          </div>
        </div>
        <div>
          <h1 class="main-heading">
            introduction
          </h1>
          <h2 class="sub-heading">
            Working in conjunction with Conduktor
          </h2>
          <p class="conduktor-p">
            Businesses are increasingly relying on real-time 
            data analysis to acquire faster insights and 
            respond immediately to stay competitive. 
            Real-time insights enable businesses and 
            organizations to make predictions based 
            on the most up-to-date data available. 
            Apache Kafka because of its distributed
             structure and effective data management, 
             can operate at supersonic speed. Massive 
             clusters can monitor and react to millions 
             of changes to a dataset per second. 
             Conduktor helps businesses in using Apache 
             Kafka to manage their data by providing 
             an 'Apache Kafka Desktop Client,' software 
             that allows users to carry out Kafka 
             development and administrative activities 
             with the utmost convenience and efficiency. 
             They contacted Septem Systems with some 
             subscription management queries and we jumped on board right away.
          </p>

        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">our integration</h1>
      </div>
      <div class="col-sm-12 col-md-9 col-lg-9">
        <div class="integration-class">
          <p class="conduktor-p">
            A super-smooth subscription management web 
            portal was required for Conduktor’s subscription 
            management along with an integration that could 
            enable them to process payments efficiently. 
            Septem Systems' squad created a robust 
            subscription management web portal for Conduktor’s users and admins. 
            We also came up with the solution of integrating Chargebee, 
            a subscription management system that can help handle all 
            the aspects of the subscription life cycle including recurring billing, 
            invoicing, and trial management for Conduktor.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-3">
        <div class="integration-img">
          <img src="/images/conduktor/group.png" alt="">
        </div>

      </div>
      <div class="third-grid">
          <div class="img">
            <img src="/images/conduktor/group 1.png" alt="">
          </div>
        <div>
          <p class="conduktor-p">
            Doing so, we created an intuitive web portal 
            that enabled Conduktor to process payments, 
            and its users to take control of their subscription 
            management completely untroubled.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">outcome</h1>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="integration-class">
          <p class="conduktor-p outcome-p">
            Data is priceless, and it will undoubtedly outlast the systems themselves. 
            With incalculable businesses being conducted throughout the world, 
            the importance of data simply cannot be overstated as it has 
            proven to be one of the most significant components in an 
            organization's success. Did you know? The requirement to 
            manage unstructured data is cited by 95 percent of firms 
            as a challenge. By collaborating with Conduktor, Septem 
            Systems has contributed to the success of many businesses 
            by enabling them to make better business decisions using 
            structured data, therefore, making a difference 
            in the world of business. “Torture the data long enough, 
            and it will confess to anything.” - Ronald Coase
          </p>
          <img src="/images/conduktor/hands and ipad.png" alt="" class="outcome-img">
        </div>
      </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="pad-img">
            <img src="/images/conduktor/conduktor-1.png" alt="">
          </div>
        </div>
        <div class="col-sm-12 col-md-1 col-lg-1"></div>
        <div class="col-sm-12 col-md-5 col-lg-5">
        <div class="img-flex">
          <img src="/images/conduktor/conduktor-2.png" alt="">
          <img src="/images/conduktor/conduktor-3.png" alt="">
        </div>
        </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">client testimonials </h1>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 mt-5">
        <div class="testimonial">
          <div class="comment-flex">
            <div class="img">
              <img src="/images/conduktor/ceo.png" alt="">
            </div>
            <div>
              <p>Stephane Deroiaux</p>
              <span>CEO & Founder</span>
            </div>
          </div>
          <p class="testimonial-p">
            “They helped us tremendously in building a high-quality 
            customer portal from scratch. They displayed their 
            experience and professionalism by dealing with 
            every aspect of the project, clear communication, 
            and satisfying all our needs in a timely manner.”
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-2 col-lg-2 mt-5"></div>
      <div class="col-sm-12 col-md-4 col-lg-4 mt-5">
        <div class="img-last">
            <img src="/images/conduktor/group 2.png" alt="">
          </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: 'ConduktorCaseStudy',

}
</script>

<style scoped>
section{
  margin-top: -5%;
}
.img-flex{
  padding: 10% 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.pad-img{
  padding: 10% 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.img-flex img{
  margin-top: 5%;
  width: 100%;
  height: 100%;
}
.pad-img img{
  width: 100%;
  height: 100%;
}
.img-last{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}
.img-last img{
  width: 100%;
  height: 100%;
}

.second-grid .img {
  width: 100%;
  height: auto;
}

.second-grid .img img {
  width: 100%;
  height: 100%;
}
.third-grid .img {
  width: 100%;
  height: auto;
}

.third-grid .img img {
  width: 100%;
  height: 100%;
}

.testimonial{
  position: relative;
  background-color: #151515;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  height: auto;
}
.testimonial .comment-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.testimonial .testimonial-p{
  font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 600;
  line-height: 1.5;
}


.comment-flex .img {
  width: 50px;
  height: auto;
  border-radius: 50%;
  margin-left: 0px;
  padding: 0px 10px 0px 0px;
}

.comment-flex .img img {

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  border: 1px solid #00b398;
}

.comment-flex p {
 color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin: 0px !important;
  
}

.comment-flex span {
  
   color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;

}

.g2-img {
  width: 100%;
  height: auto;
}

.g2-img img {
  width: 100%;
  height: 100%;
}

.full-img {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  padding-bottom: 5%
}

.full-img img {
  height: 100%;
  width: 100%;
}

.integration-class {
  position: relative;
  background-color: #151515;
  padding: 40px;
  border-radius: 5rem;
  width: 100%;
  height: auto;
}
.integration-class .outcome-img{
  border-radius: 50% 0 50% 0;
  position: absolute;
  width: 38%;
  bottom: 0;
  right: 0;
}
.conduktor-p {
  text-align: left;
  font-size: 1.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.conduktor-p .outcome {
  text-align: left;
  font-size: 1.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.integration-class .conduktor-p {
  text-align: left;
  font-size: 1.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}
  .outcome-p {
  padding-right:38%;
}
.integration-img {
  width: 100%;
  height: auto;
}

.integration-img img {
  width: 100%;
  height: 100%;
}

.second-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  column-gap: 10%;
  padding: 10% 0;
  align-items: center;
}

  .third-grid {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  width: 100%;
  column-gap: 10%;
  padding: 10% 0;
  align-items: center;
}

.logo-img {
  padding: 20% 0px 10% 0px;
  width: 130px;
  height: 100%;
}

.inrto-img {
  width: 100%;
  height: auto;
}

.inrto-img img {
  width: 100%;
  height: 100%;
}


.second-grid .main-heading {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.5px;
  padding-bottom: 5px;
  line-height: 0.5;
  text-transform: capitalize;
}

.main-heading {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.5px;
  padding-bottom: 5px;
  line-height: 45px;
  text-transform: capitalize;
}

.second-grid.sub-heading {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.sub-heading {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.tech-img {
   width: 50%;
   height: auto;
}

.tech-img .grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  grid-template-rows: auto;
  gap: 2rem;
}

.grid img {
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 25%;
}

.flex img {
  width: 100%;
  height: 100%;
}

@media(max-width: 768px) {
  .integration-class .outcome-img{
    display: none;
  }
  .second-grid.section-2 div:first-child{
    order: 0;
  }

  .second-grid {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 10px 15px;
  }
  .third-grid {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 10px 15px;
  }

  .second-grid div:first-child {
    order: 1;
  }
  .third-grid div:first-child{
    order: 1;
  }
  .inrto-img {
    width: 100%;
    height: auto;
    padding: 5px 0px;
  }

  .second-grid.main-heading {
    font-size: 30px;
    font-weight: 700;
  }

  .second-grid.sub-heading {
    font-size: 24px;
    font-weight: 700;
  }
  .logo-img {
    padding-top: 70px;
    width: 100px;
    height: 100%;
  }

}

@media(max-width: 480px) {
  
 .flex img{
  margin: 0 0 30px 0;
 }
 .inrto-img, .img-last {
    margin: 40px 0 15px 0;
  }
  .third-grid img{
    margin: 40px 0 25px 0;
  }
  .integration-img {
    margin-top: 40px;
  }

  .integration-class{
    padding: 10px 20px;
    border-radius: 20px;
  }
  .conduktor-p {
    font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 500;
  line-height: 1.7;
  text-align: justify;
  }
  .testimonial-p{
    text-align: justify;
    font-weight: 400;
  }
  
  .integration-class .conduktor-p {
    font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 500;
  line-height: 1.7;
  text-align: justify;

  }
  .outcome-p {
    padding-right: 0;
  }
  .second-grid.main-heading {
    font-size: 20;
  }

  .second-grid.sub-heading {
    font-size: 16px;
  }
}</style>
