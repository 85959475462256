import  firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCbfnyQQhLfUS8KtIiZG2OFKcwOfEXS7S0",
  authDomain: "septemsystems-general.firebaseapp.com",
  projectId: "septemsystems-general",
  storageBucket: "septemsystems-general.appspot.com",
  messagingSenderId: "960616995533",
  appId: "1:960616995533:web:ae6648e80386ea7e21e784",
};

// Initialize Firebase

export default firebase.initializeApp(firebaseConfig);
