import Vue from "vue";
import Vuex from "vuex";
import banner from "./banner.module";
import user from "./user.module";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: { banner, user },
  state: {
    sidebar: false,
    quote: false,
    developer: null
  },
  mutations: {
    SET_SIDEBAR(state, payload) {
      state.sidebar = payload;
    },
    SET_QUOTE(state, payload) {
      state.quote = payload;
    },
    RESET_QUOTE(state, payload) {
      state.quote = payload;
    },
    SET_DEVELOPER(state, payload){
      state.developer = payload
    }
    
  },
  actions: {
    async setSidebar({ commit }, payload) {
      commit("SET_SIDEBAR", payload);
    },
    setQuote({ commit }, payload) {
      commit("SET_QUOTE", payload);
    },
    setDeveloper({commit}, payload){
      commit("SET_DEVELOPER", payload)
    }
    
  },
  getters: {
    getQuote(state) {
      return state.quote;
    },
    getDeveloper(state){
      return state.developer
    }
    
  },
});
