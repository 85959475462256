<template>
    <div class="container">
        <img src="/images/smart-interview.png" alt="Smart Case Study">
    </div>
</template>

<script>
    export default {
        name :"SmartCaseStudy",
    }
</script>

<style scoped>
.container {
    margin-left: 0 !important;
    margin-top: -20px;
    width: 100vw;
    height: auto;
    padding: 0;
}
.container img {
    width: 100%;
    height: 100%;
}
@media (max-width: 420px) {
    .container {
        margin-top: 25px !important;
    }
}
</style>