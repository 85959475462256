<template>
  <div class="page-content services-mobile">
    <div class="row service-main-container">
      <div class="heading-title-alt border-short-bottom text-center">
        <h3 class="text-uppercase">What we do?</h3>
      </div>
      <div
        class="col-md-3 col-lg-3 col-xs-12 col-sm-12 c-padding"
        v-for="service in services"
        :key="service.id"
      >
        <router-link :to="service.path">
          <div class="service-card-container">
            <img
              :src="
                require(`../../public/assets/img/services-icons/${service.avatar}`)
              "
              alt=""
            />
            <span class="services-headings">{{ service.heading }}</span>
            <p>{{ service.description }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MobileServices",
  props: {
    services: {
      type: Array,
    },
  },
};
</script>
