<template>
  <div class="services-detail-component" v-if="propObject && propObject">
    <div class="col-lg-6">
      <h1>{{ propObject.heading }}</h1>
    </div>
    <div class="col-lg-9">
      <p>
        {{ propObject.description }}
      </p>
    </div>
    <div class="col-lg-12">
      <router-link :to="`${propObject.path}`">
        <button>Learn More</button>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "ServicesDescription",
  props: {
    propObject: {
      type: Object,
    },
  },
};
</script>
