<template>
  <header class="l-header l-header_overlay">
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <div
      class="l-navbar l-navbar_expand l-navbar_t-dark-trans js-navbar-sticky"
    >
      <div class="container-fluid">
        <nav
          class="menuzord js-primary-navigation"
          role="navigation"
          aria-label="Primary Navigation"
        >
          <!--logo start-->
          <router-link to="/" class="logo-brand">
            <img
              class="retina"
              src="assets/img/logo-dark.png"
              alt="Massive"
              width="28px"
              height="45px"
            />
          </router-link>

          <div id="main" class="main-btn">
            <button class="openbtn" @click="toggleNav">
              <svg
                width="39"
                height="31"
                viewBox="0 0 39 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M33.5338 13.0071H11.3372"
                  stroke="#00B398"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M33.5338 7.97095H4.99524"
                  stroke="#00B398"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M33.5338 18.0435H4.99524"
                  stroke="#00B398"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M33.5338 23.0796H11.3372"
                  stroke="#00B398"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>

          <!--logo end-->

          <!--mega menu start-->
          <ul class="menuzord-menu menuzord-right c-nav_s-standard">
            <li :class="{ active: path === '/' }">
              <router-link to="/">Home</router-link>
            </li>
            <li :class="{ active: path.includes('service') }">
              <router-link to="/?id=services"> Services </router-link>
            </li>
            <li :class="{ active: path.includes('artificial-intelligence') }">
              <router-link to="/artificial-intelligence"
                >Artificial Intelligence</router-link
              >
            </li>
            <li :class="{ active: path.includes('portfolio') }">
              <router-link to="/portfolio">Portfolio</router-link>
            </li>
            <li :class="{ active: path.includes('careers') }">
              <router-link to="/careers">Careers</router-link>
            </li>
            <li :class="{ active: path.includes('subsidiaries') }">
              <router-link to="/subsidiaries">subsidiaries</router-link>
            </li>
            <li :class="{ active: path.includes('about') }">
              <router-link to="/about">About Us</router-link>
            </li>
            <li :class="{ active: path.includes('contact') }">
              <router-link to="/contact">Contact</router-link>
            </li>

            <!-- <button class="btn custom-btn" @click.prevent="$router.push('/our-team')">hire talent</button> -->
            <li class="" v-if="getLoggedState">
              <a @click.prevent="logout()">Logout</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div id="mySidebar" class="sidebar">
      <a href="javascript:void(0)" class="closebtn" @click="toggleNav">×</a>
      <a @click.prevent="goToRoute('/')">Home</a>
      <a @click.prevent="goToRoute('/?id=services')">Services</a>
      <a @click.prevent="goToRoute('/artificial-intelligence')"
        >Artificial Intelligence</a
      >
      <a @click.prevent="goToRoute('/portfolio')">Portfolio</a>
      <a @click.prevent="goToRoute('/careers')">Careers</a>
      <a @click.prevent="goToRoute('/subsidiaries')">Subsidiaries</a>
      <a @click.prevent="goToRoute('/about')">About Us</a>
      <a @click.prevent="goToRoute('/contact')">Contact</a>
      <button class="custom-btn" @click.prevent="goToRoute('/our-team')">
        hire talent
      </button>

      <!-- <a @click.prevent="logout()">Logout</a>-->
    </div>
  </header>
</template>

<script>
import firebase from "../../firebaseConfig";

export default {
  name: "Header",
  computed: {
    getLoggedState() {
      return this.$store.state.isLogged;
    },
    path() {
      return this.$route.fullPath;
    },
    sidebarOpened() {
      return this.$store.state.sidebar;
    },
  },
  methods: {
    toggleNav() {
      if (this.sidebarOpened) {
        this.$store.dispatch("setSidebar", false);
        document.getElementById("mySidebar").style.width = "0";
        document.getElementById("main").style.marginLeft = "0";
      } else {
        this.$store.dispatch("setSidebar", true);
        document.getElementById("mySidebar").style.width = "100%";
        document.getElementById("main").style.marginLeft = "250px";
      }
    },
    logout() {
      firebase.auth().signOut();
      this.$cookies.remove("user_uid");

      this.$router.push("/login").catch(() => {});
    },
    goToRoute(path) {
      this.toggleNav();
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
body {
  font-family: "Lato", sans-serif;
}

.custom-btn {
  display: none;
  background-color: #00b398;
  padding: 10px;
  width: auto;
  margin-right: 0 !important;
  text-transform: capitalize;
  border-radius: 8px;
  font-size: 16px;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  color: #fff;
}

.menuzord-menu {
  padding: 0 !important;
}

.menuzord-menu > li:nth-child(8) {
  padding-right: 0 !important;
}

.menuzord-menu > li:nth-child(8) > a {
  padding-right: 0 !important;
}

.menuzord-menu > li:nth-child(6) > a {
  padding-right: 10px !important;
}
.menuzord-menu > li:nth-child(6) {
  padding-right: 10px !important;
}
.menuzord-menu > li:nth-child(7) {
  padding-right: 0px !important;
}

.main-btn {
  display: none;
  padding: 0 0 0 0 !important;
}

@media only screen and (max-width: 768px) {
  .main-btn {
    display: block;
    float: right;
  }

  .menuzord-menu {
    display: none;
  }
}

.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidebar a {
  padding: 30px 8px 8px 8px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 25px;
  cursor: pointer;
  background-color: #111;
  color: #00b398;
  padding: 5px 0px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }

  .sidebar a {
    font-size: 18px;
  }
}
</style>
