<template>
  <section>
    <section class="page-title background-title dark banner-contact">
      <div class="container">
        <div class="row">
          <div class="col-md-12 services-h">
            <h3>Business Intelligence</h3>
            <span>
              Data will talk to you<br />if you are willing to listen to it. -
              Jim Bergeson</span
            >
          </div>
        </div>
      </div>
    </section>
    <!--        <div class="row">-->
    <!--            <div class="heading-title text-center">-->
    <!--                 <h3 class="text-uppercase">Data is the new oil  </h3>-->
    <!--            </div>-->
    <!--        </div>-->
    <div class="col-container">
      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-lightbulb icon-color"></i>
        </div>
        <h2 class="align-item">Data Warehouse Design</h2>
        <p class="p-desc">
          Our BI Engineers are highly skilled in data integration to support
          analytical reporting and data analysis.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-browser2 icon-color"></i>
        </div>
        <h2 class="align-item">ETL & ELT</h2>
        <p class="p-desc">
          One of the most fun part in the whole process. Yes, our team knows the
          difference, pros & cons of both.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-laptop2 icon-color"></i>
        </div>
        <h2 class="align-item">Reporting Dashboards</h2>
        <p class="p-desc">
          Dynamic Reporting Dashboards are the key to take informed business
          desicions in realtime.
        </p>
      </div>
    </div>
    <div class="col-container">
      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-basic_gear icon-color"></i>
        </div>
        <h2 class="align-item">Google Analytics</h2>
        <p class="p-desc">
          An important web analytics tools, Some people only use it, here at
          Septem we Torture it to confess anything.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-basic_pencil_ruler icon-color"></i>
        </div>
        <h2 class="align-item">Machine Learning</h2>
        <p class="p-desc">
          Only reporting is not enough. We train models using machine learning
          to make autonomous Systems.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-basic_todolist_pencil icon-color"></i>
        </div>
        <h2 class="align-item">Artificial Intelligence</h2>
        <p class="p-desc">
          We build AI Powered systems which helps in highlighting the importance
          of each data point at granular level.
        </p>
      </div>
    </div>

    <section class="body-content">
      <div class="page-content">
        <!--post style 5 start-->
        <div class="post-list-aside">
          <div class="container">
            <div class="row post-single e-position">
              <div class="col-md-6 ss-vertical-align">
                <div class="post-img">
                  <img src="assets/img/post/X.png" alt="" />
                </div>
              </div>
              <div class="col-md-6 pull-right">
                <div class="custom-desc">
                  <h1>Where there is data smoke, there is business fire.</h1>

                  <p>
                    Transform your raw data into real knowledge with our
                    Business Intelligence Solutions. We can help turn your data
                    into actionable insights so you can make bolder, better,
                    profitable and actionable business decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--post style 5 end-->
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: "BusinessIntelligence",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.services-h span {
  font-family: "rubiklight" !important;
}
</style>
