<template>
  <div class="ourClients">
    <div class="heading-title-alt border-short-bottom text-center">
      <h3>Our Clients</h3>
    </div>
    <div class="row aboutUs-slider-row">
      <div
        class="col-xs-6 col-sm-4 col-md-2 col-lg-2 aboutUs-slider"
        v-for="(image, index) in images"
        :key="index"
      >
        <img
          class="aboutUs-slider-logos"
          :src="require(`../../public/assets/img/clients/slider/${image}`)"
          width="100%"
          alt="sliderImage"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OurClients",
  data() {
    return {
      images: [
        "znapshot.png",
        "lums.png",
        "bigscoot.png",
        "bling.png",
        "kolai.png",
        "auddio.png",
        // "playground.png",
        "uni.png",
        "esgtree.png",
        "premiro.png",
        "conducktor.png",
        "media.png",
        // "lightadison.png",
        "finance.png",
        // "magikk.svg",
      ],
    };
  },
};
</script>
