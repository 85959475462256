var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mt-5"},[_c('div',{staticClass:"row portfolio-container"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('div',{staticClass:"grid-1"},_vm._l((_vm.slides),function(slide,index){return _c('div',{key:index},[_c('img',{attrs:{"src":slide,"alt":"Slide"}})])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('VueSlickCarousel',_vm._b({staticClass:"testimonials-container"},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.slides),function(slide,index){return _c('div',{key:index,staticClass:"slide"},[_c('img',{staticClass:"slide-image",attrs:{"src":slide,"alt":""}})])}),0)],1),_c('div',{staticClass:"col-sm-12 col-md-2 col-lg-2"}),_vm._m(9),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"}),_vm._m(10),_vm._m(11),_vm._m(12)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-6"},[_c('img',{staticClass:"logo-img",attrs:{"src":"/images/znapshot/znapshot-logo.png","alt":""}}),_c('h1',{staticClass:"main-heading"},[_vm._v(" turning your mobile experience into pleasure ")]),_c('h2',{staticClass:"sub-heading"},[_vm._v(" technologies ")]),_c('div',{staticClass:"tech-img"},[_c('div',{staticClass:"tech-grid"},[_c('img',{attrs:{"src":"/images/znapshot/flutter.png","alt":""}}),_c('img',{attrs:{"src":"/images/znapshot/java spring.png","alt":""}}),_c('img',{attrs:{"src":"/images/znapshot/vue.png","alt":""}}),_c('img',{attrs:{"src":"/images/znapshot/nuxt.png","alt":""}}),_c('img',{attrs:{"src":"/images/znapshot/google cloud.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-6"},[_c('div',{staticClass:"flex"},[_c('img',{attrs:{"src":"/images/znapshot/iphone.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-grid"},[_c('div',[_c('div',{staticClass:"inrto-img"},[_c('img',{attrs:{"src":"/images/znapshot/designstripe.png","alt":""}})])]),_c('div',[_c('h1',{staticClass:"main-heading"},[_vm._v(" introduction ")]),_c('h2',{staticClass:"sub-heading"},[_vm._v(" Working in conjunction with Znapshot ")]),_c('p',{staticClass:"znap-p"},[_vm._v(" Wallpapers play a silent but strong role in our daily life. It reinforces and reiterates the image and its hidden message. For decades now, art was only to be found in museum halls and lounges of the privileged. Znapshot is here to decimate the old ways and bring about a long-needed innovation in the wallpaper industry. It introduces art pieces that replace the dull wallpapers. Pieces that are meaningful and have a deeper outlook than just your ordinary images. It further allows you to choose and constantly run your favourite art pieces, collections, and libraries on rotation. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('h1',{staticClass:"main-heading"},[_vm._v("our integration")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-8 col-lg-8"},[_c('div',{staticClass:"integration-class"},[_c('p',{staticClass:"znap-p"},[_vm._v(" We have developed the Znapshot app for both Android and iOS, to make it accessible to our customers across both platforms. With Znapshot you don’t have to set a wallpaper to have a preview since the Znapshot app allows you to preview the wallpaper before even setting it. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-4 col-lg-4"},[_c('div',{staticClass:"integration-img"},[_c('img',{attrs:{"src":"/images/znapshot/group 1.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-grid"},[_c('div',[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":"/images/znapshot/group 2.png","alt":""}})])]),_c('div',[_c('p',{staticClass:"znap-p"},[_vm._v(" The inbuilt wallpaper preview simulator allows you not only to preview how an art piece will look on your current device but also how it will be previewed on all the other devices that you have signed in using your profile. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('h1',{staticClass:"main-heading"},[_vm._v("outcome")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('div',{staticClass:"integration-class"},[_c('p',{staticClass:"znap-p outcome-p"},[_vm._v(" We have put out an app that smoothly runs and displays all your favourite wallpapers and art pieces. No need to worry about setting or changing your wallpaper every other week. Znapshot automatically updates the art pieces of artists on the app. The app has an inbuilt 15 minutes rotation setting if chosen, to run your pieces on rotation. All your favourite artists can be found on the app to choose from. You could create multiple collections of your pieces and also include artworks in your library. It allows you to run and control all your devices by logging them in using a single profile. With Znapshot we have created a solution for all art lovers. ")]),_c('img',{staticClass:"outcome-img",attrs:{"src":"/images/znapshot/hand.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-4 col-lg-4"},[_c('div',{staticClass:"center-img"},[_c('img',{attrs:{"src":"/images/znapshot/group3.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('h1',{staticClass:"main-heading"},[_vm._v("client testimonials ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-8 col-lg-8 mt-5"},[_c('div',{staticClass:"testimonial"},[_c('div',{staticClass:"comment-flex"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":"/images/znapshot/ceo.png","alt":""}})]),_c('div',[_c('p',[_vm._v("Anton Brew")]),_c('span',[_vm._v("Product Owner - Znapshot")])])]),_c('p',{staticClass:"testimonial-p"},[_vm._v(" “Working with Septem Systems has been a wonderful experience. I thoroughly appreciate the thoughtful, fully engaged, and above and beyond work/awareness that Septem Systems brings to the table. Their work has been exceptional, timely, and collaborative. I look to have a very long-lasting business relationship and highly recommend them for app development and much, much more.” ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-4 col-lg-4 mt-5"},[_c('div',{staticClass:"g2-img"},[_c('img',{attrs:{"src":"/images/znapshot/frame.png","alt":""}})])])
}]

export { render, staticRenderFns }