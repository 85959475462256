<template>
  <section>
    <CommonBanner
      styleHeading="Cloud"
      heading="Development"
      paragraph="Cloud is about how you do computing, not where you do computing. - Paul Maritz"
    />
    <!--        <div class="row">-->
    <!--            <div class="heading-title text-center">-->
    <!--                 <h3 class="text-uppercase">Cloud Development  </h3>-->
    <!--            </div>-->
    <!--        </div>-->

    <div class="col-container">
      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-lightbulb icon-color"></i>
        </div>
        <h2 class="align-item">AWS</h2>
        <p class="p-desc">
          We have hands on experience on most of the web services provided by
          Amazon. One of the best cloud so far.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-browser2 icon-color"></i>
        </div>
        <h2 class="align-item">Google Cloud</h2>
        <p class="p-desc">
          One of our most favourite cloud platform. Our team is well versed in
          most of the services provided by GCP.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-laptop2 icon-color"></i>
        </div>
        <h2 class="align-item">Digital Ocean</h2>
        <p class="p-desc">
          Our architects love to use infrastructure proviced by Digital Ocean to
          develop flexible and scalable cloud solutions.
        </p>
      </div>
    </div>

    <div class="col-container">
      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-basic_gear icon-color"></i>
        </div>
        <h2 class="align-item">Firebase</h2>
        <p class="p-desc">
          One of our most favourite cloud service by Google for web and mobile
          application devlopment with very usefull components.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-basic_pencil_ruler icon-color"></i>
        </div>
        <h2 class="align-item">Azure</h2>
        <p class="p-desc">
          Our certified consulltants are always ready to take a challenge to
          engineer solutions from scratch using Azure.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-basic_todolist_pencil icon-color"></i>
        </div>
        <h2 class="align-item">DevOps</h2>
        <p class="p-desc">
          Team Septem always keep production ready state of a product for smooth
          & reliable shipping of a product.
        </p>
      </div>
    </div>

    <section class="body-content">
      <div class="page-content">
        <!--post style 5 start-->
        <div class="post-list-aside">
          <div class="container">
            <div class="row post-single e-position">
              <div class="col-md-6 ss-vertical-align">
                <div class="post-img">
                  <img src="assets/img/post/AXrtboXar d – 1.png" alt="" />
                </div>
              </div>
              <div class="col-md-6 pull-right">
                <div class="custom-desc">
                  <h1>It’s time to transform your business with cloud.</h1>

                  <p>
                    We’ve been there. So, trust us when we tell you we know what
                    it takes to take your business to the next level. Shifting
                    to cloud solutions improves the mobility of your business,
                    and reduces cycle times, allowing you to make changes to
                    your software or apps faster and safer.
                  </p>

                  <p>
                    We deliver innovative cloud-based solutions that drive
                    businesses forward by enabling them to take advantage of
                    flexibility, scalability and security offered by cloud
                    computing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--post style 5 end-->
      </div>
    </section>
  </section>
</template>

<script>
import CommonBanner from "./common/CommonBanner.vue";

export default {
  name: "Cloud",
  mounted() {
    window.scrollTo(0, 0);
  },
  components: { CommonBanner },
};
</script>

<style scoped>

.services-h span {
  font-family: "rubiklight" !important;
}
</style>
