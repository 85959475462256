var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('CommonBanner',{attrs:{"styleHeading":"Website","heading":"Development","paragraph":" The engineer has been, and is,  a maker of history. - James Kip Finch"}}),_c('section',{staticClass:"body-content"},[_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"post-list-aside"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row post-single e-position"},[_vm._m(0),_c('div',{staticClass:"col-md-5 pull-right"},[_vm._m(1),_c('div',{staticClass:"m-bot-30"},[_c('router-link',{staticClass:"btn btn-small btn-rounded btn-custom-solid",attrs:{"to":"/backend"}},[_vm._v(" Read More ")])],1)])])])]),_c('div',{staticClass:"post-list-aside sekind-row p-top-80"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row post-single e-position"},[_c('div',{staticClass:"col-md-5"},[_vm._m(2),_c('div',{staticClass:"m-bot-30"},[_c('router-link',{staticClass:"btn btn-small btn-rounded btn-custom-solid",attrs:{"to":"/frontend"}},[_vm._v(" Read More ")])],1)]),_vm._m(3)])])]),_c('div',{staticClass:"post-list-aside sekind-row-m p-top-80"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row post-single e-position"},[_vm._m(4),_c('div',{staticClass:"col-md-5"},[_vm._m(5),_c('div',{staticClass:"m-bot-30"},[_c('router-link',{staticClass:"btn btn-small btn-rounded btn-custom-solid",attrs:{"to":"/frontend"}},[_vm._v(" Read More ")])],1)])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-7 ss-vertical-align"},[_c('div',{staticClass:"post-img"},[_c('img',{attrs:{"src":"assets/img/post/X DCXX.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-desc"},[_c('h1',[_vm._v("Back-End Engineering")]),_c('p',[_vm._v(" Just as you shouldn’t judge a book by its cover, you shouldn’t judge an app by its design and front store only! Looks are important, but it’s the brain that makes your product irresistibly attractive. Do you want an intelligent kid who also wins all the beauty pageants? That’s the type of product we code at Septem Systems. ")]),_c('p',[_vm._v(" We offer a set of services that span the development of custom back-end layers, optimization of existing backend systems, and migration to the cloud, empowering their extensibility and performance. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-desc"},[_c('h1',[_vm._v("Front-End Engineering")]),_c('p',[_vm._v(" Looks great, loads fast, and works without a teeny-tiny hiccup – here’s how your final product will look like. ")]),_c('p',[_vm._v(" We create interfaces users can’t help, but love. Our front-end ninjas ensure that each product is made with great care to make it look equally stunning on large desktop screens and mobile devices. Smooth, fast, and intuitive navigation comes as a guaranteed part of our front-end programming services. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-7 ss-vertical-align-1"},[_c('div',{staticClass:"post-img"},[_c('img',{attrs:{"src":"assets/img/post/AXrtboar d – 1.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-7 ss-vertical-align-1"},[_c('div',{staticClass:"post-img"},[_c('img',{attrs:{"src":"assets/img/post/AXrtboar d – 1.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-desc"},[_c('h1',[_vm._v("Web Front-End Engineering")]),_c('p',[_vm._v(" Looks great, loads fast, and works without a teeny-tiny hiccup – here’s how your final product will look like. ")]),_c('p',[_vm._v(" We create interfaces users can’t help, but love. Our front-end ninjas ensure that each product is made with great care to make it look equally stunning on large desktop screens and mobile devices. Smooth, fast, and intuitive navigation comes as a guaranteed part of our front-end programming services. ")])])
}]

export { render, staticRenderFns }