<template>
        <section>
            <div class="row portfolio-container">
                <div class="col-md-12 col-lg-5">
                    <div class="logo">
                        <img src="/images/bigscoot-ui/logo.svg" alt="">
                    </div>
                    <h1 class="super-heading">
                        Seamless Hosting, Stronger Online Presence
                    </h1>
                    <div class="main-flex">
                        <div class="flex-data"
                        v-for="(data, index) in mainData" :key="index">
                            <h4>{{data.title  }}</h4>
                            <p>{{ data.description }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-2"></div>
                <div class="col-md-12 col-lg-5">
                    <div class="main-img mt">
                        <img src="/images/bigscoot-ui/main-img.png" alt="">
                    </div>
                </div>
            
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <h2 class="sub-heading" style="text-align: center;">
                project description
            </h2>
            <p class="paragraph">
                BigScoots domain hosting solutions redefine excellence in the 
                realm of web applications. BigScoots' industry-leading 
                infrastructure fuels a seamless integration process that 
                amplifies your online presence. We're dedicated to empowering 
                businesses and individuals with unmatched hosting services, 
                characterised by reliability, security, and round-the-clock support. 
                Embark on a digital journey like never before with hosting solutions 
                that truly deliver, courtesy of BigScoots.
            </p>
                </div>
    
                <h2 class=" col-lg-12 sub-heading">
                    dashboard
                </h2>
                <div class="col-md-12 col-lg-12">
                    <div class="main-img">
                        <img src="/images/bigscoot-ui/home.png" alt="">
                    </div>
                </div>
        
            <div class="grid">
                <div class="grid-flex" v-for="(item, index) in data" :key="index">
                        <h2 class="sub-heading">
                            {{ item.title }}
                        </h2>
                        <div class="main-img">
                            <img :src="item.url" alt="">
                        </div>
                </div>
            </div>
        
    </div>
        </section>
</template>

<script>
    export default {
        data(){
            return {
                mainData: [
                    {
                        title: 'Platform',
                        description: 'Web App'
                    },
                    {
                        title: 'Deliverables',
                        description: 'Development'
                    },
                ],
                data: [
                    {
                        title: 'site details',
                        url: '/images/bigscoot-ui/site.png'
                    },
                    {
                        title: 'migrations',
                        url: '/images/bigscoot-ui/migration.png'
                    },
                    {
                        title: 'backups',
                        url: '/images/bigscoot-ui/backup.png'
                    },
                    {
                        title: 'collaborators',
                        url: '/images/bigscoot-ui/collaborator.png'
                    },
                    {
                        title: 'support tickets',
                        url: '/images/bigscoot-ui/support.png'
                    },
                    {
                        title: 'email',
                        url: '/images/bigscoot-ui/email.png'
                    },
                    {
                        title: 'ticket archive',
                        url: '/images/bigscoot-ui/ticket.png'
                    },
                    {
                        title: 'notifications',
                        url: '/images/bigscoot-ui/notification.png'
                    },
                ]
            }
        }
        
    }
</script>

<style scoped>
    section{
        background: linear-gradient(179.44deg, #000000 -442.84%, rgba(91, 124, 253, 0.2) 50%, #020103 95.93%);
        margin-top: 3em;
    }
    .portfolio-container{
        margin-bottom: -8em !important;
    }
    .logo{
        display: flex;
        width: 15%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em;
    }
    .logo .main-heading{
        font-size: 2em;
        text-transform: capitalize;
        color: #fff;
        font-weight: 800;
    }
    .super-heading{
        font-size: 3em;
        font-weight: 900;
        margin-bottom: 1em;
    }
    .main-flex{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .main-flex .flex-data{
        display: flex;
        flex-direction: column;
        margin-bottom: 2em;
    }
    .flex-data h4{
        font-size: 1.5em;
        font-weight: 500;
        line-height: 1em;
    }
    .flex-data p{
        font-size: 1em;
        font-weight: 500;
        color: #fff;
    }
    .main-img {
    width: 100%;
}
.main-img img {
    width: 100%;
    height: auto;
}
.sub-heading {
    font-size: 2.5em;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
    margin: 1.5em 0 1em 0;
}

.paragraph {
    font-size: 1.25em;
    font-weight: 400;
    letter-spacing: 0.3px;
    text-align: center;
    color: #fff;
    padding: 0 5em;
}
.grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
    padding: 0 1em;
}
@media (min-width: 1440px) {
    .logo .main-heading{
        font-size: 3em;
        text-transform: capitalize;
        color: #fff;
        font-weight: 800;
    }
    .super-heading{
        font-size: 5em;
        font-weight: 900;
        margin-bottom: 1.5em;
    }
    .main-flex{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .main-flex .flex-data{
        display: flex;
        flex-direction: column;
        margin-bottom: 2em;
    }
    .flex-data h4{
        font-size: 2.5em;
        font-weight: 500;
        line-height: 1em;
    }
    .flex-data p{
        font-size: 1.5em;
        font-weight: 500;
        color: #fff;
    }
    .paragraph {
        font-size: 1.5em;
        padding: 0 15em;
    }

    .sub-heading {
        font-size: 3.5em;
        font-weight: 800;
    }    
}
@media (max-width:768px) {
    .portfolio-container{
        margin-bottom: -4em !important;
    }

    .paragraph {
        font-size: .75em;
        text-align: center;
        line-height: 1.5em;
        padding: 0 2em;
    }

    .sub-heading {
        font-size: 2em;
    }
    .super-heading {
        font-size: 3em;
        margin-bottom: 1em;
    }
    .main-flex{
        margin-bottom: 5em;
    }

    .main-flex h4 {
        font-size: 1.5em;
        font-weight: 500;
        text-transform: capitalize;
    }

    .main-flex span {
        color: #fff !important;
        font-size: 1em;
        font-weight: 400;

    }
    .data-flex img{
        width: 5em;
        height: auto;
    }
.grid {
    grid-template-columns: 1fr;
    margin-bottom: 2em;
}
}
@media (max-width:480px) {
    .portfolio-container{
        margin-bottom: 0 !important;
    }

.logo {
    width: 40%;
    margin-top: 2em;
}
    .paragraph {
        font-size: 1em;
        line-height: 1.5em;
        text-align: justify;
    }

    .sub-heading {
        font-size: 1.25em;
        font-weight: 900;
    }
    .main-flex .main-heading{
        line-height: 1em;
        font-size: 1.5em;
    }

    .super-heading {
        font-size: 2em;
        line-height: 1.1em;
        letter-spacing: normal;
        font-weight: 900;
        margin-bottom: .5em;
    }

    .main-flex h4 {
        font-size: 1.5em;
        font-weight: 600;
        line-height: 0.75em;
        margin-bottom: 0;
        text-transform: capitalize;
    }
    .main-flex{
        margin-bottom: 2em;
    }
    .main-flex p {
        color: #fff !important;
        font-size: 1em;
        font-weight: 400;

    }
    .data-flex img{
        width: 3em;
        height: auto;
        margin-bottom: 1em;
    }
    .data-flex span{
        font-size: .5em;
    }
    .skeleton-grid{
        display: none;
    }
    .row .custom-grid {
        grid-template-columns: 1fr;
    }
    .row .custom-grid .order{
        order: 1;
    }
}
</style>