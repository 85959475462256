<template>
  <section class="page-title background-title dark banner-contact">
    <div class="row common-banner">
      <div
        class="col-md-12"
        :class="path === 'Portfolio' ? 'col-lg-7' : 'col-lg-6'"
      >
        <h3 class="common-banner-heading">
          <span>{{ styleHeading }} </span> {{ heading }}
        </h3>
        <span class="common-banner-paragraph">{{ paragraph }} </span>
        <div class="career-open-btn" v-if="path === 'Careers'">
          <a target="_blank" href="https://careers.septemsystems.com/">
            Open Positions <i class="fa fa-arrow-right"></i
          ></a>
        </div>
      </div>
      <div
        class="col-md-12 common-banner-right-svg"
        :class="path === 'Portfolio' ? 'col-lg-5' : 'col-lg-6'"
      >
        <span>
          <svg
            width="135"
            height="107"
            viewBox="0 0 135 107"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_215_6560)">
              <path
                d="M23.7437 79.925L48.4529 20.7519L95.7696 64.0316L23.7437 79.925Z"
                stroke="url(#paint0_linear_215_6560)"
                stroke-width="4"
              />
            </g>
            <path
              d="M22.814 80.1301L47.5232 20.957L94.8399 64.2367L22.814 80.1301Z"
              stroke="url(#paint1_linear_215_6560)"
              stroke-width="4"
            />
            <g filter="url(#filter1_f_215_6560)">
              <path
                d="M36.5002 84.7797L61.8938 22.2678L112.121 67.3216L36.5002 84.7797Z"
                stroke="url(#paint2_linear_215_6560)"
                stroke-width="2"
              />
            </g>
            <path
              d="M35.4182 85.0292L60.8117 22.5174L111.039 67.5711L35.4182 85.0292Z"
              stroke="url(#paint3_linear_215_6560)"
              stroke-width="2"
            />
            <defs>
              <filter
                id="filter0_f_215_6560"
                x="3.41406"
                y="0.355957"
                width="113.547"
                height="99.3521"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="8.5"
                  result="effect1_foregroundBlur_215_6560"
                />
              </filter>
              <filter
                id="filter1_f_215_6560"
                x="14.8477"
                y="0.57373"
                width="119.375"
                height="105.613"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="10"
                  result="effect1_foregroundBlur_215_6560"
                />
              </filter>
              <linearGradient
                id="paint0_linear_215_6560"
                x1="47.781"
                y1="17.339"
                x2="100.163"
                y2="74.0036"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00B398" />
                <stop offset="1" stop-color="#017CB6" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_215_6560"
                x1="46.8514"
                y1="17.544"
                x2="99.2333"
                y2="74.2087"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00B398" />
                <stop offset="1" stop-color="#017CB6" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_215_6560"
                x1="57.9534"
                y1="29.3699"
                x2="98.0525"
                y2="62.0316"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00B197" />
                <stop offset="1" stop-color="#00B197" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_215_6560"
                x1="56.8714"
                y1="29.6194"
                x2="96.9705"
                y2="62.2811"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00B197" />
                <stop offset="1" stop-color="#00B197" />
              </linearGradient>
            </defs>
          </svg>
        </span>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "CommonBanner",
  props: ["heading", "styleHeading", "paragraph"],
  computed: {
    path() {
      return this.$route.name;
    },
  },
};
</script>
<style></style>
