<template>
  <!--body content start-->
      <div class="background-color">
          <div class="col-sm-12 carreer-heading">
            <div
              class="heading-title-alt border-short-bottom text-center"
              style="margin-bottom:60px;"
            >
              <h3 class="text-uppercase" style="padding-top:30px;">
                All Jobs
              </h3>
              <div class="col-sm-2">
                <div class="contact-btn">
                  <router-link :to="{ name: 'create-job' }">
                    <button
                      class=" btn btn-medium btn-rounded btn-custom-solid "
                    >
                      Add new job
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div
            class="row"
            v-for="(jobs, index) in change"
            :key="'job_' + index"
            style="padding-top: 10px;"
          >
            <div class="career-list">
              <div class="col-md-6 "  v-for="job in jobs"
            :key="job.id">
                <div
                  class="featured-item career-box"
                  style="padding:0px 30px; width:100%"
                >
                  <div class="text-heading">
                    <h1 class="font-size theme-color">
                      {{
                        job.title.length > 40
                          ? job.title.slice(0, 40) + "..."
                          : job.title
                      }}
                    </h1>
                  </div>
                  <div class="title">
                    <p><b>Positions:</b> {{ job.position }}</p>
                    <p><b>Commitment:</b> {{ job.commitment }}</p>
                    <p><b>Location:</b> {{ job.location }}</p>
                    <p class="text-justify overlap-text">
                      <b>Description:</b>
                      {{
                        job.description.length > 100
                          ? job.description.slice(0, 100) + "..."
                          : job.description
                      }}
                    </p>
                  </div>

                  <a
                    href="#"
                    class="show-detail"
                    type="button"
                    data-toggle="modal"
                    :data-target="'#' + job.id"
                    >See more</a
                  >
                  <router-link :to="'/admin/edit-job/' + job.id"
                    ><button class="btn btn-success" style="float: right;">
                    <i class="fa fa-edit" ></i>
                    </button></router-link
                    >
<!--                  <button class="btn btn-danger"  @click="deleteJob(job.id)"-->
<!--                                            style="float: right;">-->
<!--                    <i class="fa fa-trash" />-->
<!--                  </button>-->
                   <!-- <a

                      href="#"
                      class="show-detail"
                      type="button"
                     @click="deleteJob(job.id)"
                                            style="float: right;"
                      >Delete</a
                    > -->
                  <!-- Trigger the modal with a button -->
                  <!-- <button type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">Open Modal</button> -->

                  <!-- Modal -->
                  <div :id="job.id" class="modal fade" role="dialog st">
                    <div
                      class="modal-dialog"
                      style=" width:95%;max-width:900px;margin-top:80px"
                    >
                      <!-- Modal content-->
                      <div
                        class="modal-content"
                        style="background-color:black; border: 2px solid #00b398;"
                      >
                        <div class="modal-header">
                          <h4 class="modal-title">
                            {{ job.title }}
                          </h4>
                        </div>
                        <!-- <div class="modal-body">
        <p>Some text in the modal.</p>
      </div> -->
                        <div class="modal-body" style=" width:100%;float:left;">
                          <div class="modal-float">
                            <h3 style="padding-left: 22px; font-weight: 700;">
                              Description
                            </h3>
                            <ul
                              class="left-text"
                              style="list-style     padding: 0 0 0 30px;"
                            >
                              <li style="padding-bottom: 10px;">
                                {{ job.description }}
                              </li>
                            </ul>
                          </div>

                          <div class="modal-float">
                            <h3 style="padding-left: 22px; font-weight: 700;">
                              REQUIRE
                            </h3>
                            <ul
                              class="left-text"
                              style="list-style     padding: 0 0 0 30px;"
                            >
                              <li style="padding-bottom: 10px;">
                                {{ job.requirement }}
                              </li>
                            </ul>
                          </div>
                          <div class="modal-float">
                            <h3 style="padding-left: 22px; font-weight: 700;">
                              RESPONSIBILITIIES
                            </h3>
                            <ul
                              class="left-text"
                              style="list-style     padding: 0 0 0 30px;"
                            >
                              <li style="padding-bottom: 10px;">
                                {{ job.responsibilities }}
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-medium btn-rounded btn-custom-solid"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

</template>

<script>
import firebase from "../../firebaseConfig";

const db = firebase.firestore();
export default {
  name: "JobListing",

  mounted() {
    this.getJobs();

    // console.log('%cJobListing.vue line:331 this.change', 'color: #007acc;', this.change);
  },
  data() {
    return {
      jobs: [],
      snapshot: null
    };
  },
computed:{
 change(){
   let arr=this.jobs;
   let secondarr=[]

  for (let i=0; i<arr.length; i+=2)
    secondarr.push(arr.slice(i,i+2));
  console.log('%cJobListing.vue line:349 secondarr', 'color: #007acc;', secondarr);
   return secondarr

}
},
  methods: {
    async getJobs() {
      this.snapshot = await db.collection("jobs").onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            this.jobs.push({ id: change.doc.id, ...change.doc.data() });
          }
          if (change.type === "modified") {
            console.log("Modified job: ", change.doc.data());
          }
          if (change.type === "removed") {
            console.log("Removed job: ", change.doc.data());
            this.jobs.map((el, index) => {
              if(el.id===change.doc.id){
                this.jobs.splice(index, 1)
              }
            })
          }
        });
      });
    },
    async deleteJob(id){
      try {
        await db
          .collection("jobs")
          .doc(id)
          .update({ is_disabled: true });
        this.$toast.success("Job is deleted successfully");
      } catch (e) {
        console.log("error", e);
        this.$toast.console.error(err);
      }
    }

  },
  beforeDestroy() {
    if (this.snapshot) {
      this.snapshot();
    }
  }

};
</script>
<style scoped>
.career-box {
  display: inline-block;
  margin-bottom: 20px;
  border: 1px solid #00b398;
  background: #000;
  border-radius: 7px;
  box-shadow: #00b398 0px 0px 0px;
}

.career-box:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 2px solid #00b398;
  box-shadow: #00b398 0px 3px 10px;
}
.overlap-text {
  word-break: break-all;
}
</style>
