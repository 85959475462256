<template>
  <section class="page-title  dark ">
    <div class="container">
    <router-view></router-view>
    </div>
  </section>
</template>

<script>
import CreateJob from "./CreateJob.vue";
import JobListing from "./JobListing.vue";


export default {
  name: "Dashboard",
  components: {
    CreateJob,
    JobListing
  },
  created(){
    if(!this.$cookies.get('user_uid')){
      this.$toast.error('You need to login first');
      this.$router.push('/login');
    }
  },
  mounted() {

  },
};
</script>
