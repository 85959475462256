<template>
  <div>
    <div class="banner-container" v-if="path === 'home'">
      <div class="row banner-body-container">
        <div
          class="col-xs-12 col-sm-10 col-md-10 col-lg-6 banner-left-container"
        >
          <h3 class="banner-heading">
            A <span>FullStack</span> Software Development Partner
          </h3>
          <p class="banner-paragraph">INVENT . INSPIRE . INNOVATE</p>
          <div class="banner-home-radio-btn-container">
            <span>Let’s talk about your project</span>
            <label class="switch">
              <input type="checkbox" checked />
              <div
                class="slider round"
                @click="() => this.$router.push('/contact')"
              ></div>
            </label>
          </div>
          <div class="banner-company-name-container">
            <img src="../../public/assets/img/banner/Septem.svg" alt="" />
          </div>
        </div>
        <div
          class="col-lg-6 banner-right-container banner-right-home-container"
        >
          <div class="banner-technology">
            <div class="banner-technology-background">
              <svg
                width="214"
                height="215"
                viewBox="0 0 214 215"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_b_1_177)">
                  <rect
                    x="0.477905"
                    width="213.283"
                    height="215"
                    rx="22"
                    fill="white"
                    fill-opacity="0.36"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_b_1_177"
                    x="-15.5221"
                    y="-16"
                    width="245.283"
                    height="247"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
                    <feComposite
                      in2="SourceAlpha"
                      operator="in"
                      result="effect1_backgroundBlur_1_177"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_backgroundBlur_1_177"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
            <div class="banner-card-spaces">
              <div class="banner-card">
                <span
                  ><svg
                    width="44"
                    height="45"
                    viewBox="0 0 44 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="22" cy="22.6807" r="22" fill="#007CB7" />
                    <path
                      d="M17.5001 28.9149C17.2617 28.9151 17.0314 28.8285 16.8523 28.6711L10.8523 23.4211C10.7468 23.3287 10.6622 23.2149 10.6043 23.0871C10.5463 22.9594 10.5164 22.8208 10.5164 22.6805C10.5164 22.5402 10.5463 22.4016 10.6043 22.2739C10.6622 22.1461 10.7468 22.0323 10.8523 21.9399L16.8523 16.6899C17.0488 16.5179 17.3055 16.4311 17.566 16.4485C17.8266 16.4658 18.0695 16.586 18.2414 16.7825C18.4134 16.9789 18.5002 17.2357 18.4828 17.4962C18.4655 17.7567 18.3453 17.9997 18.1489 18.1716L12.995 22.6805L18.1484 27.1894C18.2992 27.3213 18.4062 27.496 18.4551 27.6903C18.504 27.8846 18.4924 28.0892 18.422 28.2767C18.3515 28.4643 18.2255 28.6258 18.0608 28.7399C17.8961 28.8539 17.7005 28.915 17.5001 28.9149V28.9149Z"
                      fill="white"
                    />
                    <path
                      d="M26.5 28.9149C26.2996 28.9151 26.1039 28.8541 25.9392 28.7401C25.7744 28.6261 25.6483 28.4645 25.5777 28.2769C25.5072 28.0894 25.4956 27.8848 25.5445 27.6904C25.5934 27.4961 25.7004 27.3213 25.8512 27.1894L31.0052 22.6805L25.8517 18.1716C25.6552 17.9997 25.5351 17.7567 25.5177 17.4962C25.5004 17.2357 25.5872 16.9789 25.7591 16.7825C25.9311 16.586 26.174 16.4658 26.4345 16.4485C26.695 16.4311 26.9518 16.5179 27.1483 16.6899L33.1483 21.9399C33.2538 22.0323 33.3384 22.1461 33.3963 22.2739C33.4543 22.4016 33.4842 22.5402 33.4842 22.6805C33.4842 22.8208 33.4543 22.9594 33.3963 23.0871C33.3384 23.2149 33.2538 23.3287 33.1483 23.4211L27.1483 28.6711C26.9691 28.8286 26.7386 28.9153 26.5 28.9149V28.9149Z"
                      fill="white"
                    />
                    <path
                      d="M19.75 31.1651C19.5967 31.1651 19.4456 31.1293 19.3086 31.0605C19.1716 30.9917 19.0525 30.8919 18.9609 30.769C18.8693 30.646 18.8077 30.5034 18.781 30.3525C18.7542 30.2015 18.7631 30.0464 18.8069 29.8995L23.3069 14.8995C23.3408 14.7722 23.3999 14.653 23.4807 14.549C23.5615 14.445 23.6624 14.3582 23.7773 14.2939C23.8922 14.2295 24.0189 14.1889 24.1498 14.1744C24.2808 14.1599 24.4133 14.1719 24.5395 14.2095C24.6657 14.2472 24.7831 14.3098 24.8847 14.3936C24.9863 14.4775 25.07 14.5809 25.1309 14.6977C25.1919 14.8144 25.2287 14.9423 25.2393 15.0736C25.2499 15.2049 25.2341 15.337 25.1927 15.462L20.6927 30.462C20.6322 30.6651 20.5077 30.8431 20.3379 30.9698C20.1681 31.0965 19.9619 31.165 19.75 31.1651V31.1651Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <div class="technology-container">
                  <p>Web Development</p>
                  <div class="chip-container">
                    <div class="banner-chip">Frontend</div>
                    <div class="banner-chip">Backend</div>
                  </div>
                </div>
              </div>
              <div class="banner-card-2">
                <span
                  ><svg
                    width="50"
                    height="50"
                    viewBox="0 0 45 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="22.5898" cy="22.3052" r="22" fill="#00B398" />
                    <path
                      d="M26.707 28.2502H16.2904V16.7502H26.707V17.7085H28.7904V13.8752C28.7904 12.821 27.8529 11.9585 26.707 11.9585L16.2904 11.9681C15.1445 11.9681 14.207 12.821 14.207 13.8752V31.1252C14.207 32.1793 15.1445 33.0418 16.2904 33.0418H26.707C27.8529 33.0418 28.7904 32.1793 28.7904 31.1252V27.2918H26.707V28.2502ZM30.8737 22.5002C30.8737 22.3756 30.8529 22.251 30.832 22.1264L31.4987 21.6664C31.707 21.5227 31.7695 21.2447 31.6341 21.0339L31.0404 20.1139C30.9761 20.0155 30.8753 19.9418 30.7566 19.9066C30.6378 19.8713 30.5092 19.8769 30.3945 19.9222L29.6445 20.2097C29.4362 20.066 29.207 19.9318 28.9674 19.836L28.8633 19.0981C28.8471 18.9832 28.7867 18.8775 28.6931 18.8002C28.5994 18.7228 28.4787 18.6789 28.3529 18.6764L27.1862 18.6572C26.9154 18.6572 26.6966 18.8297 26.6654 19.0789L26.5612 19.836C26.3112 19.9318 26.0924 20.0564 25.8841 20.2097L25.1341 19.9222C24.8945 19.8264 24.6133 19.9127 24.4883 20.1139L23.8945 21.0339C23.7591 21.2447 23.8112 21.5131 24.0299 21.6664L24.6966 22.1264C24.6445 22.251 24.6237 22.3756 24.6237 22.5002C24.6237 22.6247 24.6445 22.7397 24.6654 22.8547L23.9987 23.3243C23.7904 23.4681 23.7279 23.7364 23.8633 23.9472L24.4466 24.8768C24.582 25.0877 24.8529 25.1739 25.1029 25.0781L25.8633 24.781C26.0716 24.9343 26.3008 25.0685 26.5612 25.1643L26.6654 25.9022C26.6966 26.1418 26.9154 26.3239 27.1862 26.3239H28.3529C28.6133 26.3239 28.832 26.1418 28.8737 25.9022L28.9779 25.1643C29.2279 25.0685 29.457 24.9343 29.6758 24.781L30.4362 25.0781C30.6758 25.1739 30.957 25.0877 31.0924 24.8768L31.6758 23.9472C31.8112 23.7364 31.7487 23.4681 31.5404 23.3243L30.8737 22.8547C30.8529 22.7397 30.8737 22.6247 30.8737 22.5002V22.5002ZM27.7487 23.9377C26.8841 23.9377 26.1862 23.2956 26.1862 22.5002C26.1862 21.7047 26.8841 21.0627 27.7487 21.0627C28.6133 21.0627 29.3112 21.7047 29.3112 22.5002C29.3112 23.2956 28.6133 23.9377 27.7487 23.9377Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <div class="technology-container">
                  <p>App Development</p>
                  <div class="chip-container">
                    <div class="banner-chip">Android</div>
                    <div class="banner-chip">IOS</div>
                    <div class="banner-chip">Hybrid</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="banner-technology-mobile">
            <div class="banner-technology-background">
              <svg
                width="214"
                height="215"
                viewBox="0 0 214 215"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_b_1_177)">
                  <rect
                    x="0.477905"
                    width="213.283"
                    height="215"
                    rx="22"
                    fill="white"
                    fill-opacity="0.36"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_b_1_177"
                    x="-15.5221"
                    y="-16"
                    width="245.283"
                    height="247"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
                    <feComposite
                      in2="SourceAlpha"
                      operator="in"
                      result="effect1_backgroundBlur_1_177"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_backgroundBlur_1_177"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
            <div class="banner-card-spaces">
              <div class="banner-card">
                <span
                  ><svg
                    width="44"
                    height="45"
                    viewBox="0 0 44 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="22" cy="22.6807" r="22" fill="#007CB7" />
                    <path
                      d="M17.5001 28.9149C17.2617 28.9151 17.0314 28.8285 16.8523 28.6711L10.8523 23.4211C10.7468 23.3287 10.6622 23.2149 10.6043 23.0871C10.5463 22.9594 10.5164 22.8208 10.5164 22.6805C10.5164 22.5402 10.5463 22.4016 10.6043 22.2739C10.6622 22.1461 10.7468 22.0323 10.8523 21.9399L16.8523 16.6899C17.0488 16.5179 17.3055 16.4311 17.566 16.4485C17.8266 16.4658 18.0695 16.586 18.2414 16.7825C18.4134 16.9789 18.5002 17.2357 18.4828 17.4962C18.4655 17.7567 18.3453 17.9997 18.1489 18.1716L12.995 22.6805L18.1484 27.1894C18.2992 27.3213 18.4062 27.496 18.4551 27.6903C18.504 27.8846 18.4924 28.0892 18.422 28.2767C18.3515 28.4643 18.2255 28.6258 18.0608 28.7399C17.8961 28.8539 17.7005 28.915 17.5001 28.9149V28.9149Z"
                      fill="white"
                    />
                    <path
                      d="M26.5 28.9149C26.2996 28.9151 26.1039 28.8541 25.9392 28.7401C25.7744 28.6261 25.6483 28.4645 25.5777 28.2769C25.5072 28.0894 25.4956 27.8848 25.5445 27.6904C25.5934 27.4961 25.7004 27.3213 25.8512 27.1894L31.0052 22.6805L25.8517 18.1716C25.6552 17.9997 25.5351 17.7567 25.5177 17.4962C25.5004 17.2357 25.5872 16.9789 25.7591 16.7825C25.9311 16.586 26.174 16.4658 26.4345 16.4485C26.695 16.4311 26.9518 16.5179 27.1483 16.6899L33.1483 21.9399C33.2538 22.0323 33.3384 22.1461 33.3963 22.2739C33.4543 22.4016 33.4842 22.5402 33.4842 22.6805C33.4842 22.8208 33.4543 22.9594 33.3963 23.0871C33.3384 23.2149 33.2538 23.3287 33.1483 23.4211L27.1483 28.6711C26.9691 28.8286 26.7386 28.9153 26.5 28.9149V28.9149Z"
                      fill="white"
                    />
                    <path
                      d="M19.75 31.1651C19.5967 31.1651 19.4456 31.1293 19.3086 31.0605C19.1716 30.9917 19.0525 30.8919 18.9609 30.769C18.8693 30.646 18.8077 30.5034 18.781 30.3525C18.7542 30.2015 18.7631 30.0464 18.8069 29.8995L23.3069 14.8995C23.3408 14.7722 23.3999 14.653 23.4807 14.549C23.5615 14.445 23.6624 14.3582 23.7773 14.2939C23.8922 14.2295 24.0189 14.1889 24.1498 14.1744C24.2808 14.1599 24.4133 14.1719 24.5395 14.2095C24.6657 14.2472 24.7831 14.3098 24.8847 14.3936C24.9863 14.4775 25.07 14.5809 25.1309 14.6977C25.1919 14.8144 25.2287 14.9423 25.2393 15.0736C25.2499 15.2049 25.2341 15.337 25.1927 15.462L20.6927 30.462C20.6322 30.6651 20.5077 30.8431 20.3379 30.9698C20.1681 31.0965 19.9619 31.165 19.75 31.1651V31.1651Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <div class="technology-container">
                  <p>Web Development</p>
                  <div class="chip-container">
                    <div class="banner-chip">Frontend</div>
                    <div class="banner-chip">Backend</div>
                  </div>
                </div>
              </div>
              <div class="banner-card-2">
                <span
                  ><svg
                    width="45"
                    height="45"
                    viewBox="0 0 45 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="22.8584" cy="22.5337" r="22" fill="#00B398" />
                    <g clip-path="url(#clip0_1_185)">
                      <path
                        d="M31.8584 13.5337H13.8584C13.03 13.5337 12.3584 14.2053 12.3584 15.0337V27.0337C12.3584 27.8621 13.03 28.5337 13.8584 28.5337H31.8584C32.6868 28.5337 33.3584 27.8621 33.3584 27.0337V15.0337C33.3584 14.2053 32.6868 13.5337 31.8584 13.5337Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M25.1084 31.5337L24.7334 28.5337H20.9834L20.6084 31.5337H25.1084Z"
                        fill="white"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M28.1084 31.5337H17.6084"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.3584 24.7837V27.0337C12.3595 27.4312 12.5179 27.8121 12.799 28.0931C13.08 28.3742 13.4609 28.5326 13.8584 28.5337H31.8584C32.2559 28.5326 32.6368 28.3742 32.9178 28.0931C33.1989 27.8121 33.3573 27.4312 33.3584 27.0337V24.7837H12.3584ZM22.8584 27.7837C22.7101 27.7837 22.5651 27.7397 22.4417 27.6573C22.3184 27.5749 22.2223 27.4577 22.1655 27.3207C22.1087 27.1837 22.0939 27.0329 22.1228 26.8874C22.1517 26.7419 22.2232 26.6083 22.3281 26.5034C22.433 26.3985 22.5666 26.327 22.7121 26.2981C22.8576 26.2692 23.0084 26.284 23.1454 26.3408C23.2825 26.3975 23.3996 26.4937 23.482 26.617C23.5644 26.7404 23.6084 26.8854 23.6084 27.0337C23.6084 27.2326 23.5294 27.4234 23.3887 27.564C23.2481 27.7047 23.0573 27.7837 22.8584 27.7837Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_185">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(10.8584 10.5337)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <div class="technology-container">
                  <p>Mobile Develepmet</p>
                  <div class="chip-container">
                    <div class="banner-chip">Android</div>
                    <div class="banner-chip">IOS</div>
                    <div class="banner-chip">Hybrid</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="banner-home-svg">
            <svg
              width="135"
              height="107"
              viewBox="0 0 135 107"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_f_215_6560)">
                <path
                  d="M23.7437 79.925L48.4529 20.7519L95.7696 64.0316L23.7437 79.925Z"
                  stroke="url(#paint0_linear_215_6560)"
                  stroke-width="4"
                />
              </g>
              <path
                d="M22.814 80.1301L47.5232 20.957L94.8399 64.2367L22.814 80.1301Z"
                stroke="url(#paint1_linear_215_6560)"
                stroke-width="4"
              />
              <g filter="url(#filter1_f_215_6560)">
                <path
                  d="M36.5002 84.7797L61.8938 22.2678L112.121 67.3216L36.5002 84.7797Z"
                  stroke="url(#paint2_linear_215_6560)"
                  stroke-width="2"
                />
              </g>
              <path
                d="M35.4182 85.0292L60.8117 22.5174L111.039 67.5711L35.4182 85.0292Z"
                stroke="url(#paint3_linear_215_6560)"
                stroke-width="2"
              />
              <defs>
                <filter
                  id="filter0_f_215_6560"
                  x="3.41406"
                  y="0.355957"
                  width="113.547"
                  height="99.3521"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="8.5"
                    result="effect1_foregroundBlur_215_6560"
                  />
                </filter>
                <filter
                  id="filter1_f_215_6560"
                  x="14.8477"
                  y="0.57373"
                  width="119.375"
                  height="105.613"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="10"
                    result="effect1_foregroundBlur_215_6560"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_215_6560"
                  x1="47.781"
                  y1="17.339"
                  x2="100.163"
                  y2="74.0036"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#00B398" />
                  <stop offset="1" stop-color="#017CB6" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_215_6560"
                  x1="46.8514"
                  y1="17.544"
                  x2="99.2333"
                  y2="74.2087"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#00B398" />
                  <stop offset="1" stop-color="#017CB6" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_215_6560"
                  x1="57.9534"
                  y1="29.3699"
                  x2="98.0525"
                  y2="62.0316"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#00B197" />
                  <stop offset="1" stop-color="#00B197" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_215_6560"
                  x1="56.8714"
                  y1="29.6194"
                  x2="96.9705"
                  y2="62.2811"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#00B197" />
                  <stop offset="1" stop-color="#00B197" />
                </linearGradient>
              </defs>
            </svg>
          </span>
        </div>
      </div>
    </div>
    <div class="portfolio-banner-container" v-if="path === 'Portfolio'">
      <CommonBanner
        styleHeading="We love to"
        heading="show our work"
        paragraph=" Discover how our high-performing teams collaborate with business executives, entrepreneurs, and technology experts to pursue software-driven innovation. "
      />
    </div>
  </div>
</template>
<script>
import CommonBanner from "./common/CommonBanner.vue";

export default {
  name: "Banner",
  computed: {
    path() {
      return this.$route.name;
    },
  },
  components: { CommonBanner },
};
</script>
<style lang="css" scoped></style>
