<template>
  <section class="mt-5">
    <div class="row portfolio-container gy-5">
      <div class="col-sm-12 col-md-12 col-lg-6">
        <img src="/images/blingrock/bling-logo.png" alt="" class="logo-img">
        <h1 class="main-heading">
         A peek into an artist's world
        </h1>
        <p class="bling-p">
          BlingRock is a platform that has managed to successfully 
          integrate a discovery and analytics tool to help us 
          discover new music, artists, playlists, and albums 
          along with complete analytics and forecasting of an 
          artist’s subscribers, followers, viewers, and revenues 
          from different platforms.
        </p>
        <h2 class="sub-heading">
          technologies
        </h2>
        <div class="tech-img">
          <div class="grid">
            <img src="/images/blingrock/vue.png" alt="">
            <img src="/images/blingrock/my-sql.png" alt="">
            <img src="/images/blingrock/firebase.png" alt="">
            <img src="/images/blingrock/google cloud.png" alt="">
            <img src="/images/blingrock/nestjs.png" alt="">
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6">
        <div class="flex">
          <img src="/images/blingrock/main.png" alt="">
              </div>
      </div>
      <div class="second-grid">
        <div>
          <div class="inrto-img">
            <img src="/images/blingrock/2.png" alt="">
          </div>
          
        </div>
        <div>
          <h1 class="main-heading">
            introduction
          </h1>
          <h2 class="sub-heading">
            Working in conjunction with BlingRock
          </h2>
          <p class="bling-p">
            The struggle of having to hover around statistics 
            from multiple platforms is a struggle known to all artists. 
            With BlingRock sliding in the game, this headache will be a 
            thing of the past. BlingRock has been developed by fusion 
            of two features, Discover, and Analytics. The Discover 
            tool lets you signup with your Spotify account and 
            allows you to discover music, artists, albums and playlists. 
            You get to listen to a 30-second piece of the song, save a track, 
            add to playlist or play in Spotify. The Analytics feature 
            integrates an artists’ subscribers, followers, viewers 
            and revenue from all the social platforms that an artist uses. 
            This information is exhibited in aggregate as well as 
            broken down in charts and graphs.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">our integration</h1>
      </div>
      <div class="col-sm-12 col-md-8 col-lg-8">
        <div class="integration-class">
          <p class="bling-p">
            We have developed BlingRock by integrating two completely 
            segregated platforms, namely, Spotify and Soundcharts. 
            Our team used the divide and conquer strategy and put 
            together this masterpiece by integrating one module at a time.
            We used our integration with Spotify to enable our users 
            to access and discover music. While discovering music 
            and artists is a major component of our BlingRock, 
            we put great effort into our integration with Soundcharts.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="integration-img">
          <img src="/images/bigscoot/group1.png" alt="">
        </div>

      </div>
      <div class="second-grid">
        <div>
          <div class="img">
            <img src="/images/blingrock/3steps.png" alt="">
          </div>
        </div>
        <div>
          <p class="bling-p">
            Our Analytics tool will put out a complete categorization 
            of all the applicable statistics. These statistics 
            have been collected from all social platforms and 
            using a very specialized algorithm, our platform 
            forecasts the growth of an artist using historic data, 
            in terms of revenue as well as subscribers, followers, and viewers
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">outcome</h1>
      </div>
      <div class="outcome">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="integration-class">
          <p class=" bling-p outcome-p">
            BlingRock can be used by both the general public 
            and artists alike. For an artist, it can be used 
            as a tool to monitor and gauge the performance of 
            their pieces of work as well as their aggregate 
            performance as a whole. They can use this tool to 
            measure which platform they need to engage more, 
            which platform generates the greatest revenue, 
            and the timing of the new releases to generate 
            the maximum outreach. This will prove to be an 
            effective analytics tool while acting as a 
            stepping stone of success for artists. 
            For the general public, this platform 
            can be used to quickly discover trending 
            artists, music, discover a song just by 
            putting in a string of lyrics, connect with 
            their Spotify to save playlists, play songs, 
            and preview the analytics of their favourite artists.
          </p>
          <img src="/images/blingrock/playing music-bro 1.png" alt="" class="outcome-img">
        </div>
      </div>
    </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="img-flex">
          <img src="/images/blingrock/xiaomi pad 5-2.png" alt="">
          <img src="/images/blingrock/xiaomi pad 5-3.png" alt="">
        </div>
        </div>
        <div class="col-sm-12 col-md-8 col-lg-8">
          <div class="pad-img">
            <img src="/images/blingrock/xiaomi pad 5-1.png" alt="">
          </div>
        </div>
      
        <div class="col-sm-12 col-md-4 col-lg-4">
          <p class="text-primary text-bold p-bold">Power to the Artists!</p>
          <h2 class="sub-heading">
            We ceated 
            <span class="text-primary text-bold">blingrock</span>
            to<br> support smaller & medium sized artists
          </h2>
        </div>
        <div class="col-sm-12 col-md-2 col-lg-2"></div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="lst-img">
            <img src="/images/blingrock/lst.png" alt="">
          </div>
        </div>
     
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">client testimonials </h1>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 mt-5">
        <div class="testimonial">
          <div class="comment-flex">
            <div class="img">
              <img src="/images/blingrock/ceo.png" alt="">
            </div>
            <div>
              <p>Constatin T.</p>
              <span>CEO Blingrock</span>
            </div>
          </div>
          <p class="testimonial-p">
            “While the complex nature of our project was 
            intimidating for many companies, Septem 
            Systems took up the challenge and gave 
            life to our ideas. We always felt at the 
            centre of the attention and felt like a 
            team member rather than a client. 
            The development lifecycle was completed with the 
            minutest attention to detail, and with ample time 
            left for any changes. We had a very pleasant 
            experience working with Septem Systems.”
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-2 col-lg-2 mt-5"></div>
      <div class="col-sm-12 col-md-4 col-lg-4 mt-5">
          <div class="img-last">
            <img src="/images/blingrock/boombox-bro 1.png" alt="">
          </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: 'BlinkRockCaseStudy',

}
</script>

<style scoped>
.testimonial{
  position: relative;
  background-color: #151515;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  height: auto;
}
.testimonial .comment-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.testimonial .testimonial-p{
  font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 600;
  line-height: 1.5;
}
.p-bold{
  font-weight: 700;
  font-size: 18px;
}
.bling-p{
  text-align: left;
  font-size: 1.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.lst-img{
  width: 100%;
  height: auto;
}
.lst-img img{
  width: 100%;
  height: 100%;
}
.img-flex{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.pad-img{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.img-flex img{
  width: 100%;
  height: 100%;
}
.pad-img img{
  width: 100%;
  height: 100%;
}
.img-last{
  width: 100%;
  height: auto;
}
.img-last img{
  width: 100%;
  height: 100%;
}

.second-grid .img {
  width: 100%;
  height: auto;
}

.second-grid .img img {
  width: 100%;
  height: 100%;
}
.main-heading span {
  color: rgb(46, 179, 127);
}

.comment-flex .img {
  width: 50px;
  height: auto;
  border-radius: 50%;
  margin-left: 0px;
  padding: 0px 10px 0px 0px;
}

.comment-flex .img img {

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  border: 1px solid #00b398;
}

.comment-flex p {
 color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin: 0px !important;
  
}

.comment-flex span {
   color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;

}

.g2-img {
  width: 100%;
  height: auto;
}

.g2-img img {
  width: 100%;
  height: 100%;
}

.full-img {
  width: 100%;
  height: auto;
  margin: 20px 0px;
}

.full-img img {
  height: 100%;
  width: 100%;
}

.integration-class {
  position: relative;
  background-color: #151515;
  padding: 50px;
  border-radius: 5rem;
  width: 100%;
  height: auto;
}
.integration-class .outcome-img{
  position: absolute;
  width: 30%;
  height: auto;
  bottom: 0;
  right: 0;
}
.integration-class .outcome-p {
  padding-right: 30%;
}

.integration-img {
  width: 100%;
  height: auto;
}

.integration-img img {
  width: 100%;
  height: 100%;
}

 .second-grid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  column-gap: 5%;
  padding: 30px 20px;
  align-items: center;
}

.logo-img {
  padding: 20% 0px 10% 0px;
  padding-bottom: 50px;
  width: 130px;
  height: 100%;
}

.inrto-img {
  width: 100%;
  height: auto;
}

.inrto-img img {
  width: 100%;
  height: 100%;
}


.second-grid .main-heading {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.5px;
  padding-bottom: 5px;
  line-height: 45px;
  text-transform: capitalize;
}

.main-heading {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.5px;
  padding-bottom: 5px;
  line-height: 45px;
  text-transform: capitalize;
}

.second-grid.sub-heading {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.sub-heading {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  line-height: 1;
}

.tech-img {
  width: 70%
}

.tech-img .grid {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr;
  grid-template-rows: auto;
  gap: 2rem
}

.grid img {
  width: 100%;
  height: 100%;
}

.flex {
  width: 100%;
  height: auto;
  padding-top: 15%;
}

.flex img {
  width: 100%;
  height: 100%;
}
@media(max-width: 768px) {

  .integration-class .outcome-img {
    display: none;
  }

  .integration-class .outcome-p {
    padding: 10px;
  }
  .second-grid.section-2 div:first-child{
    order: 0;
  }

  .second-grid {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 10px 15px;
  }

  .second-grid div:first-child {
    order: 1;
  }
  .inrto-img {
    width: 100%;
    height: auto;
    padding: 5px 0px;
  }

  .second-grid.main-heading {
    font-size: 30px;
    font-weight: 700;
  }

  .second-grid.sub-heading {
    font-size: 24px;
    font-weight: 700;
  }

  .logo-img {
    padding-top: 70px;
    width: 100px;
    height: 100%;
  }

}

@media(max-width: 480px) {
  .integration-class{
    padding: 10px 20px;
    border-radius: 20px;
  }
  .bling-p {
    font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 500;
  line-height: 1.7;
  text-align: justify;
  }
  .testimonial-p{
    text-align: justify;
    font-weight: 400;
  }
  .lst-img{
  width: 100%;
  height: auto;
}
.lst-img img{
  width: 100%;
  height: 100%;
}
.integration-class .bling-p {
    font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 500;
  line-height: 1.7;
  text-align: justify;

  }
  .integration-class .outcome-p {
    padding: 0;
  }

  .second-grid.main-heading {
    font-size: 20;
  }
  .integration-img, .inrto-img, .lst-img {
    margin: 50px 0 45px 0;
  }
  .second-grid .img{
    margin: 40px 0 0 0;
  }

  .second-grid.sub-heading {
    font-size: 16px;
  }
  .img-flex{
    margin: 0;
  }
}</style>
