<template>
  <section>
    <div class="container">
      <div class="row atmos-padding-top">
        <div class="col-sm-12">
          <div class="col-sm-2">
            <img class="atmos-image" src="assets/simi-logo-1.png">
          </div>
          <div class="col-sm-1"></div>
          <div class="col-sm-9">
            <div class="col-sm-12">
              <p class="Case-study-atmos-p">SUCCESS STORY</p>
            </div>
            <div class="col-sm-12">
              <h1 class="atmos-h-font">Making home automation easier and the android experience better.</h1>
            </div>
            <div class="col-sm-12">
              <p class="Case-study-atmos-p1">SIMI-Connect constitutes SIMI- Launcher and SIMI IoT. SIMI-Launcher is a launcher that has been developed to make a user’s android experience better. It offers various functionalities that make it stand out in the crowd. SIMI-IoT is a home automation app that acts as a center point for all your smart home devices. It allows you to control all your smart devices through a single mobile app.  </p>
            </div>

            <div class="col-sm-12 Atmos-btn">
              <a href="#/contact" class="btn btn-medium btn-custom-solid ">Lets talk about your project <i class="fa fa-arrow-right"></i></a>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row padding-top">
        <div class="col-sm-4">
          <h2 class="center">INDUSTRY</h2>
          <p class="p-center">Automation </p>
        </div>

        <div class="col-sm-4">
          <h2 class="center">HEADQUARTERS</h2>
          <p class="p-center">Australia</p>
        </div>
        <div class="col-sm-4">
          <h2 class="center">PLATFORMS</h2>
          <p class="p-center">IOS, ANDROID & WEB </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row padding-row">
        <div class="col-sm-1"></div>
        <div class="col-sm-2">
          <img class="co-founder-img-1" src="assets/dmitiri.png">
        </div>
        <div class="col-sm-8">
          <div class="atmos-border-box">
            <div class="col-sm-12">
              <p class="co-founder-paragrafh"> “We have worked with other companies for the development of various solutions but have never been as satisfied as left after working with Septem Systems. From the very first “Hello!” to the very last interaction, every team member was incredibly welcoming, and always ready for any new ideas. The team worked tirelessly to deliver the product. We look forward to working with Septem Systems again”</p>
            </div>
            <div class="col-sm-12">
              <h3 class="co-founder-name">DIMITRI VLAHOS</h3>
              <p class="co-founder-inc">Product Owner</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row esg-case-study-position">
        <div class="col-sm-6">
          <h4 class="introduction-heading">Introduction</h4>
          <h4 class="introduction-heading">Working in Conjunction With SIMI-Connect</h4>
          <p class="introduction-paragrafh">We all are familiar with the android operating system and the freedom it offers to its users but how many of you are actually satisfied by the launchers that your phone manufacturers flash on your mobile phones. While developing the SIMI-Launcher, providing the users with the full extent of the autonomy that the android OS has to offer has been our utmost priority. SIMI-IoT has been developed with the same standards of consumer ease in mind. With the advancement in technology, home automation is finding its way into our lives but an issue that’s coming to light is that controlling multiple devices is becoming a hassle as every device requires a separate app.</p>
        </div>
        <div class="col-sm-6 introduction-img-center">
          <img class="introduction-img" src="assets/Simi 1.png">
        </div>
      </div>
      <div class="row integration-padding Atmos-sekind-row">
        <div class="col-sm-6 introduction-img-center-1">
          <img class="integration-img" src="assets/Simi 2.png">
        </div>
        <div class="col-sm-6 introduction-img-center-2">
          <h4 class="atmos-introduction-heading">Our Integration</h4>
          <p class="introduction-paragrafh">We have developed SIMI-Launcher to cater to the needs of our client whose business model is running targeted ads. While offering top of the line features and utmost accessibility, SIMI-Launcher has been designed to run ads in a subtle manner along offering the best experience to its users. SIMI-IoT on the other hand offers to its users the comfort of using all their smart home automation devices including; cameras, bulbs, locks, thermostats, and speakers, using our SIMI-IOT app. Our app features easy connectivity, interactive UI/UX design and the greatest ease of use. To make this app even better, SIMI-IoT allows you to connect to our server that allows you remote access to our devices no matter where you are.
          </p>
        </div>
      </div>
      <div class="row Atmos-sekind-row-m">
        <div class="col-sm-6">
          <h4 class="atmos-introduction-heading">Our Integration</h4>
          <p class="introduction-paragrafh">We have developed SIMI-Launcher to cater to the needs of our client whose business model is running targeted ads. While offering top of the line features and utmost accessibility, SIMI-Launcher has been designed to run ads in a subtle manner along offering the best experience to its users. SIMI-IoT on the other hand offers to its users the comfort of using all their smart home automation devices including; cameras, bulbs, locks, thermostats, and speakers, using our SIMI-IOT app. Our app features easy connectivity, interactive UI/UX design and the greatest ease of use. To make this app even better, SIMI-IoT allows you to connect to our server that allows you remote access to our devices no matter where you are.
          </p>
        </div>

        <div class="col-sm-6">
          <img class="integration-img" src="assets/Simi 2.png">
        </div>
      </div>
      <div class="row outcome-padding">
        <div class="col-sm-6">
          <h4 class="atmos-introduction-heading">The Outcome</h4>
          <p class="introduction-paragrafh">Be ready to use your device to the fullest. With SIMI-Launcher’s amazing features and autonomy coupled with an elegant way to interact with your customer base through running targetted ads, we have redefined the android experience. It allows users to create multiple profiles on a single device and access only specified apps in each profile. No matter who’s using the device, our launcher will have the perfect suite of apps for their need. By integrating remote access and creating a central point of control for all your smart home devices, SIMI-IoT is the first app of its kind that allows such convenience. Now you will no longer have to have multiple apps on your phone. No matter what company or brand, SIMI-IOT integrates all the different protocols to provide you with the perfect solution.
          </p>
        </div>
        <div class="col-sm-6 introduction-img-center">
          <img class="outcome-img" src="assets/Simi 3.png">
        </div>
      </div>
    </div>
    <div class="row native-padding">
      <div class="col-sm-12">
        <h5 class="techno-heading">Technologies</h5>
      </div>
      <div class="col-sm-12">
        <p class="native-para">Iot Android, Flutter, Nest.js, Kotlin, Google Cloud, Auth0 </p>
      </div>
    </div>
    <div class="container">
      <div class="row android-padding">
        <!-- <div class="col-sm-2"></div> -->
        <div class="col-sm-2 align">
          <img class="java-img" src="assets/Android (1).png">
        </div>
        <div class="col-sm-2 align">
          <img class="java-img" src="assets/Flutter.png">
        </div>

        <div class="col-sm-2 align">
          <img class="java-img" src="assets/Nestjs.png">
        </div>

        <div class="col-sm-2 align">
          <img class="java-img" src="assets/Kotlin.png">
        </div>
        <div class="col-sm-2 align">
          <img class="java-img" src="assets/Google Cloud.png">
        </div>
        <div class="col-sm-2 align">
          <img class="java-img" src="assets/Auth0.png">
        </div>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: "SimiConnectCaseStudy.vue"
}
</script>

<style scoped>
.integration-img{
  width: 100%;
  /*padding: 53px 40px 0px 45px;*/
}

.introduction-img{
  width: 100%;
  /*padding: 96px 0 0 70px;*/
}


</style>
