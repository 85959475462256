<template>
  <section>
    <CommonBanner
      styleHeading="UI/UX"
      heading="Designing"
      paragraph="If you think good design is expensive, You should look at
              the cost of bad design. - Dr Ralf Speth"
    />
    <!--        <div class="row">-->
    <!--            <div class="heading-title text-center">-->
    <!--                 <h3 class="text-uppercase">UI/UX </h3>-->
    <!--                 <p class="ui-ux-p">People ignore design that ignores people</p>-->
    <!--            </div>-->
    <!--        </div>-->
    <div class="col-container">
      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-lightbulb icon-color"></i>
        </div>
        <h2 class="align-item">USER RESEARCH</h2>
        <p class="p-desc">
          We always start with information gathering from the stakeholders to
          create a custom design.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-browser2 icon-color"></i>
        </div>
        <h2 class="align-item">DESIGN</h2>
        <p class="p-desc">
          Based on user input, we first create personas, storyboards, user
          journey maps and then convert them into designs.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-laptop2 icon-color"></i>
        </div>
        <h2 class="align-item">WIREFRAMING</h2>
        <p class="p-desc">
          Develop a visual framework of the product to define information
          architecture, navigation & interface design.
        </p>
      </div>
    </div>
    <div class="col-container">
      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-basic_gear icon-color"></i>
        </div>
        <h2 class="align-item">PROTOTYPING</h2>
        <p class="p-desc">
          Structuring the very first version of your product/app/website to
          examine the look, feel and functionality.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-basic_pencil_ruler icon-color"></i>
        </div>
        <h2 class="align-item">TESTING</h2>
        <p class="p-desc">
          Validate the design flow and user experience by testing the prototypes
          through various methods.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-basic_todolist_pencil icon-color"></i>
        </div>
        <h2 class="align-item">IMPLEMENTATION & LAUNCH</h2>
        <p class="p-desc">
          After user testing, the validated prototype is converted into the
          actual product.
        </p>
      </div>
    </div>
    <section class="body-content">
      <div class="page-content">
        <!--post style 5 start-->
        <div class="post-list-aside">
          <div class="container">
            <div class="row post-single e-position">
              <div class="col-md-6 ss-vertical-align">
                <div class="post-img">
                  <img src="assets/img/post/XXX.png" alt="" />
                </div>
              </div>
              <div class="col-md-6 pull-right">
                <div class="custom-desc">
                  <h1>
                    We design digital products and supercharge your brands.
                  </h1>

                  <p>
                    It's all about bringing emotions to logic. We map empathy
                    and cognitive science to derive a product that is not just
                    usable but appealing as well.
                  </p>

                  <p>
                    Driven by a human-centred design approach, your clients
                    become the subject of our thorough investigation. As the
                    business aims to find its market fit perfectly, we aim to
                    understand its customers profoundly to create a stunning
                    User Interface and a seamless User Experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--post style 5 end-->
      </div>
    </section>
  </section>
</template>

<script>
import CommonBanner from "./common/CommonBanner.vue";

export default {
  name: "UIUX",
  mounted() {
    window.scrollTo(0, 0);
  },
  components: { CommonBanner },
};
</script>

<style scoped>
.services-h span {
  font-family: "rubiklight" !important;
}
</style>
