<template>
  <section class="mt-5">
    <div class="row portfolio-container">
      <div class="col-sm-12 col-md-12 col-lg-6">
        <img src="/images/esgtree/esgtree-logo.png" alt="" class="logo-img">
        <h1 class="main-heading">
          creating a <span>green</span> scene
        </h1>
        <p class="esg-p">
          ESGTree, is a cloud-based data collection platform for impact investors,
          venture capital firms, private equity firms, and other
          intermediaries seeking ESG (Environmental, Social,
          and Governance) monitoring services.
          As the technology partner, we created this salient software
          contributing towards the betterment of our world.
        </p>
        <h2 class="sub-heading">
          technologies
        </h2>
        <div class="tech-img">
          <div class="grid">
            <img src="/images/esgtree/vue 1.png" alt="">
            <img src="/images/esgtree/my-sql.png" alt="">
            <img src="/images/esgtree/nuxt.png" alt="">
            <img src="/images/esgtree/wordpress.png" alt="">
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6">
        <div class="flex">
          <img src="/images/esgtree/main.png" alt="">
        </div>
      </div>
      <div class="second-grid">
        <div>
          <div class="inrto-img">
            <img src="/images/esgtree/v2.png" alt="">
          </div>
        </div>
        <div>
          <h1 class="main-heading">
            introduction
          </h1>
          <h2 class="sub-heading">
            Working in conjunction with ESGTree
          </h2>
          <p class="esg-p">
            Many believe that the option between economic growth
            and sustainability is unavoidable. Contrastingly,
            ESG investments are becoming increasingly
            valuable to investors who want to ensure
            that their capital is helping rather than
            hurting the planet's long-term viability.
            While working with organizations for over
            a decade on ESG management and reporting,
            our yet-to-be partners saw employees
            and consultants go through a wave of data
            mismanagement during every reporting
            cycle which went on to be extremely time-consuming,
            expensive, and produced a poor yield of results.
            In 2020, a realization came into effect and
            Septem Systems, the technology partner,
            joined forces together with ESGTree
            and began the initiation of the product.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">our integration</h1>
      </div>
      <div class="col-sm-12 col-md-8 col-lg-8">
        <div class="integration-class">
          <p class="esg-p">
            There has been a growing emphasis on sustainability
            issues in recent years as younger generations have
            become "sustainable natives," positioning this
            subject at the heart of the economy and their
            consumption patterns. Septem Systems used
            their expertise and created the ESGTree platform.
            Complete back and front end engineering were
            brought into play and a robust ESG management
            portal was designed
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="integration-img">
          <img src="/images/bigscoot/group1.png" alt="">
        </div>

      </div>
      <div class="second-grid">
        <div>
          <div class="img">
            <img src="/images/esgtree/group-1.png" alt="">
          </div>
        </div>
        <div>
          <p class="esg-p">
            A 3 step, collect, analyze and report framework was
            introduced into plateform. Up to the makr features like trend analysis,
            data colection, materiality mapping, carbon calculations and
            highlights reporting were introduced ensuring that ESGtree
            provided its services perfectly to its users.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">outcome</h1>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="integration-class">
          <p class="esg-p outcome">
            Studies indicate that up to 70% of a business’s
            revenue could be impacted through ESG issues.
            We readily contributed to enabling ESGTree’s users
            for sizable risk management, cost reduction, enhanced
            market reputation, and appreciable employee management.
            ESGTree, alongside Septem Systems, took part in assisting
            Investment managers to gain a better understanding of
            businesses when ESG is factored into the decision-making process.
            It gives information on how businesses can work and how much
            value they will bring to a portfolio. In the end, Septem
            Systems and ESGTree partnered together due to their enthusiasm
            for an eco-friendly environment and being instrumental in
            eliminating risks associated with climate change.
          </p>
          <img src="/images/esgtree/financial.png" alt="" class="outcome-img">
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="full-img">
          <img src="/images/esgtree/final.png" alt="">
        </div>
      </div>
      <div class="second-grid section-2">
        <div>
          <h2 class="sub-heading">
            we worked wholeheartedly for betterment
            <span>environmental</span>
            management practices and duty of care
          </h2>
        </div>
        <div>
          <div class="img">
            <img src="/images/esgtree/group-204.png" alt="">
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="main-heading">client testimonials </h1>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 mt-5">
        <div class="testimonial">
          <div class="comment-flex">
            <div class="img">
              <img src="/images/esgtree/ceo.png" alt="">
            </div>
            <div>
              <p>Majid Mirza</p>
              <span>Co-Founder/CEO, ESG Tree</span>
            </div>
          </div>
          <p class="testimonial-p">
            “We have been working with Septem on various
            software development initiatives and are always
            impressed by the passion, diligence, and commitment
            to client success. I think the “difference that makes a
            difference” with Septem is that they don’t just work
            from the mind but the heart as well. This is key in
            an ever-changing world where humanizing client
            interactions is key for success.”
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6"></div>
    </div>
  </section>
</template>
<script>

export default {
  name: 'EsgtreeCaseStudy',

}
</script>

<style scoped>
.testimonial{
  position: relative;
  background-color: #151515;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  height: auto;
}
.testimonial .comment-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.testimonial .testimonial-p{
  font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 600;
  line-height: 1.5;
}
.second-grid .img {
  width: 100%;
  height: auto;
}

.second-grid .img img {
  width: 100%;
  height: 100%;
}

.testimonial .comment-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.main-heading span {
  color: rgb(46, 179, 127);
}

.comment-flex .img {
  width: 50px;
  height: auto;
  border-radius: 50%;
  margin-left: 0px;
  padding: 0px 10px 0px 0px;
}

.comment-flex .img img {

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  border: 1px solid #00b398;
}

.comment-flex p {
 color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin: 0px !important;
  
}

.comment-flex span {
  
   color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;

}

.g2-img {
  width: 100%;
  height: auto;
}

.g2-img img {
  width: 100%;
  height: 100%;
}

.full-img {
  width: 100%;
  height: auto;
  margin: 20px 0px;
}

.full-img img {
  height: 100%;
  width: 100%;
}

.integration-class {
  position: relative;
  background-color: #151515;
  padding: 40px;
  border-radius: 5rem;
  width: 100%;
  height: auto;
}
.integration-class .outcome-img{
  border-radius: 50% 0 50% 0;
  position: absolute;
  width: 38%;
  bottom: 0;
  right: 0;
}
.esg-p {
  text-align: left;
  font-size: 1.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.esg-p .outcome {
  text-align: left;
  font-size: 1.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.integration-class .esg-p {
  text-align: left;
  font-size: 1.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}
  .outcome {
  padding-right:38%;
}

.integration-img {
  width: 100%;
  height: auto;
}

.integration-img img {
  width: 100%;
  height: 100%;
}

.second-grid .sub-heading span {
  color: rgb(46, 179, 127);
}

.second-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  column-gap: 20px;
  padding: 10% 0;
  align-items: center;
}

.logo-img {
  margin: 10% 0 2% 0;
  width: 300px;
  height: 100%;
}

.second-grid .inrto-img {
  width: 70%;
  margin: 0 auto;
  height: auto;
}

.inrto-img img {
  width: 100%;
  height: 100%;
}


.second-grid .main-heading {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.5px;
  padding-bottom: 5px;
  line-height: 45px;
  text-transform: capitalize;
}

.main-heading {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.5px;
  padding-bottom: 5px;
  line-height: 45px;
  text-transform: capitalize;
}

.second-grid.sub-heading {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.sub-heading {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.tech-img {
  width: 60%;
}


.tech-img .grid {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr;
  grid-template-rows: auto;
  gap: 2rem;
}

.grid img {
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
}

.flex img {
  width: 100%;
  height: 100%;
}

@media(max-width: 768px) {

  .integration-class .outcome-img {
    display: none;
  }

  .integration-class .outcome-p {
    padding: 10px;
  }
  .second-grid.section-2 div:first-child{
    order: 0;
  }

  .second-grid {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 10px 15px;
  }

  .second-grid div:first-child {
    order: 1;
  }
  .inrto-img {
    width: 100%;
    height: auto;
    padding: 5px 0px;
  }

  .second-grid.main-heading {
    font-size: 30px;
    font-weight: 700;
  }

  .second-grid.sub-heading {
    font-size: 24px;
    font-weight: 700;
  }

  .logo-img {
    padding-top: 70px;
    width: 100px;
    height: 100%;
  }

}

@media(max-width: 480px) {
  .flex img{
    margin: 0;
  }
  .second-grid {
    margin-bottom: 20px;
  }

  .hand-img{
    margin: 70px 0;
    padding: 0;
  }
  .full-img{
    margin: 30px 0 10px 0;
  }
  .integration-img {
    margin: 50px 0 45px 0;
  }
  .second-grid .human{
    width: 70%;
  }
  .integration-class{
    padding: 10px 20px;
    border-radius: 20px;
  }
  .esg-p {
    font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 500;
  line-height: 1.7;
  text-align: justify;
  }
  .testimonial-p{
    text-align: justify;
    font-weight: 400;
  }
  
  .integration-class .esg-p {
    font-size: 14px;
  color: white;
  letter-spacing: 0.3px;
  font-weight: 500;
  line-height: 1.7;
  text-align: justify;

  }
  .full-img{
    margin-top: 50px;
  }

  .second-grid.main-heading {
    font-size: 20;
  }

  .second-grid.sub-heading {
    font-size: 16px;
  }
  .sub-heading {
    font-size: 20px;
  }
  .g2-img{
    margin-bottom: 50px;
  }
  .outcome {
    padding-right: 0;
  }
  .flex img {
    margin: 20px auto;
  }
  .second-grid img{
    margin: 30px 0 10px 0;
  }
}</style>
