<template>
  <section class="page-title dark ">
    <div class="container">
      <div class="container padding">
        <h1 class="mb-5">Login</h1>
        <form @submit.prevent="login()" novalidate="true">
          <div class="row">
            <div class="col-sm-9">
              <div class="border">
                <div class="row">
                  <div class="col-sm-11">
                    <div class="col-sm-12">
                      <input
                        class="border input"
                        type="email"
                        v-model="$v.email.$model"
                        placeholder="Enter email"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p style="color: red;" v-if="$v.email.$error">Email is required.</p>
          </div>
          <div class="row">
            <div class="col-sm-9">
              <div class="border">
                <div class="row">
                  <div class="col-sm-11">
                    <div class="col-sm-12">
                      <input
                        class="border input"
                        type="password"
                        v-model="$v.password.$model"
                        placeholder="Enter password"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p style="color: red;" v-if="$v.password.$error">
              Password is required.
            </p>
            <div class="row">
              <div class="col-sm-9">
                <div class="contact-btn">
                  <button class=" btn btn-medium btn-rounded btn-custom-solid ">
                    Login
                  </button>
                </div>
                <!--<button class="send-button" type="button" onclick="alert('Hello world!')">SEND MESSAGE</button>-->
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import firebase from "../../firebaseConfig";
// import firebaseui from "firebaseui";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  validations: {
    email: { required },
    password: { required },
  },
  mounted() {},
  methods: {
    login() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return;

      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((userCredential) => {
          // Signed in

          this.$toast.success("Logged in successfully");
          this.$router.push("/admin");
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          this.$toast.error(errorMessage);
        });
    
    },
  },
};
</script>
