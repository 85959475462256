<template>
        <section>
            <div class="row portfolio-container">
                <div class="logo">
                    <img src="/images/g-tensor/logo.svg" alt="">
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <h1 class="main-heading">
                        controll your all smart devices from anywhere, anytime
                    </h1>
                    <div class="main-flex">
                        <div v-for="(item, index) in mainHeading" :key="index">
                            <h4>{{ item.title }}</h4>
                            <span>{{ item.description }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-2 col-lg-2">
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="main-img">
                        <img src="/images/g-tensor/main-img.png" alt="">
                    </div>
                </div>
                <div class="row px-5">
                
                <div class="col-md-12">
                    <h2 class="sub-heading">overview</h2>
                    <p class="paragraph">
                        In an era of advancing technology and interconnected devices, 
                        the GTensor App takes center stage as a comprehensive 
                        solution for home automation. By integrating various 
                        smart devices and appliances, the app empowers users to 
                        control their living environment with the touch of a button, 
                        ensuring comfort, energy conservation, and security.
                    </p>
                </div>
            </div>
               
                    <div class="col-sm-12 col-md-12 col-lg-12">
                    <h2 class="sub-heading">onboarding</h2>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4"></div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <p class="paragraph">
                        An easy & featured based onboarding screen for user before creating an account 
                    </p>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4"></div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-5">
                <div class="main-img onboard">
                    <img src="/images/g-tensor/onboard-img.png" alt="">
                </div>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3"></div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <h2 class="sub-heading">signing in, forget password & reset</h2>
                <p class="paragraph">
                    User can create account by entering their name, email and password, 
                    or by social logins user can reset their by entering their email address, through email they can reset.
                </p>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3"></div>
            <div class="col-sm-12 col-md-12 col-lg-12" style="margin:2em 0">
                    <div class="main-img">
                        <img src="/images/g-tensor/password-img.png" alt="">
                    </div>
            </div>
            <div class="custom-grid">
                <div class="main-img order">
                    <img src="/images/g-tensor/home-screen.png" alt="">
                </div>
                <div class="center">
                <h2 class="sub-heading" style="text-align: left;">home screen</h2>
                <p class="paragraph" style="text-align: left;">
                    The GTensor App offers a sophisticated and user-centric 
                    approach to managing smart devices within the home. 
                    Through its elegant design and seamless functionality, 
                    users are presented with a comprehensive overview of their 
                    connected devices and living spaces right from the home screen. 
                    This strategic layout not only ensures efficient access 
                    but also enhances the overall user experience.
                </p>
                <p class="paragraph" style=" text-align: left; font-weight: bold; margin-top: 3em;">
                    <ul>
                        <li>
                            Holistic View
                        </li>
                        <li>
                            Streamlined Device Management
                        </li>
                        <li>
                            Effortless Interaction
                        </li>
                        <li>
                            Intelligent Room and<br> Device Grouping
                        </li>
                    </ul>

                </p>
            </div>
            </div>
                <div class="custom-grid">
                <div class="center">
                <h2 class="sub-heading" style="text-align: left;">
                    statistics & menu
                </h2>
                <p class="paragraph"  style="text-align: left;">
                    The GTensor App offers users a comprehensive insight into their 
                    household's financial and energy-related aspects through its 
                    detailed monthly expense and power consumption statistics. 
                    This feature empowers users to make informed decisions 
                    about their resource usage and budget allocation, 
                    enhancing their control over their home environment.
                </p>
                <p class="paragraph" style="text-align: left; font-weight: bold; margin-top: 3em;">
                    <ul>
                        <li>
                            In-Depth Analysis
                        </li>
                        <li>
                            Power Consumption Insights
                        </li>
                        <li>
                            Device Management
                        </li>
                        <li>
                            Account and Settings
                        </li>
                        <li>
                            Support and Help
                        </li>
                    </ul>

                </p>
            </div>
            <div>
                <div class="main-img">
                    <img src="/images/g-tensor/statics-img.png" alt="">
                </div>
            </div>
        </div>
        
            <div class="col-sm-12 col-md-3 col-lg-3"></div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <h2 class="sub-heading">
                    add a device & device configuration
                </h2>
                <p class="paragraph">
                    User can create account by entering their name, email and password, 
                    or by social logins user can reset their by 
                    entering their email address, through email they can reset.
                </p>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3"></div>
            <div class="col-sm-12 col-md-12 col-lg-12" style="margin-top: 4em;">
                <div class="main-img">
                    <img src="/images/g-tensor/add-device-img.png" alt="">
                </div>
            </div>
           
                <div class="custom-grid" style="margin: 2em 0 !important;">
                <div class="main-img order">
                    <img src="/images/g-tensor/device-screens-img.png" alt="">
                </div>
                <div class="center">
                 <h2 class="sub-heading" style="text-align: left;">device screens</h2>
                    <p class="paragraph" style="text-align: left;">
                    The GTensor App introduces a seamless and interactive approach 
                    to managing room devices, offering users unparalleled 
                    control over their smart home environment. Additionally, 
                    the app's innovative Economy Mode enhances power-saving 
                    capabilities, fostering a more efficient 
                    and sustainable living experience.
                 </p>
                    <p class="paragraph" style="text-align: left; font-weight: bold; margin-top: 3em;">
                    <ul>
                        <li>
                            Interactive Device Management
                        </li>
                        <li>
                            Effortless Device Navigation
                        </li>
                        <li>
                            Real-Time Feedback
                        </li>
                        <li>
                            Economy Mode
                        </li>
                        <li>
                            Adaptive Device Settings
                        </li>
                        <li>
                            User-Friendly Toggle
                        </li>
                    </ul>

                </p>
            </div>
        </div>
       
                <div class="custom-grid">
            <div class="center">
                <h2 class="sub-heading" style="text-align: left;">
                    scheduling devices
                </h2>
                <p class="paragraph"  style="text-align: left;">
                    The GTensor App streamlines the process of scheduling individual 
                    devices, allowing users to seamlessly automate their 
                    smart home ecosystem. This functionality empowers users 
                    to create customized routines that enhance convenience, 
                    energy efficiency, and overall control.
                </p>
                <p class="paragraph" style="text-align: left; font-weight: bold; margin-top: 3em;">
                    <ul>
                        <li>
                            Simple and Intuitive Scheduling
                        </li>
                        <li>
                            Customised Routines
                        </li>
                        <li>
                            Efficiency and Energy Savings
                        </li>
                        <li>
                            Seamless Integration
                        </li>
                        <li>
                            Easy Adjustment
                        </li>
                    </ul>

                </p>
            </div>
                    <div class="main-img order">
                    <img src="/images/g-tensor/schedule-img.png" alt="">
                </div>
            </div>
           
                <div class="col-sm-12 col-md-12 col-lg-12">
                <h2 class="sub-heading">design process</h2>
                <div class="feature-flex">
                    <div class="data-flex" 
                    v-for="(design, index) in designs" :key="index">
                            <img :src="design.url" alt="">
                            <span>{{ design.description }}</span>
                    </div>
                </div>
            </div>
                <div class="col-sm-12 col-md-12 col-lg-12 custom-border">                
                    <h2 class="sub-heading">problem statement</h2>
                <p class="paragraph">
                    As the number of smart home devices continues to grow, 
                    users face the challenge of managing an increasingly 
                    complex network of devices and applications. Users are 
                    burdened with the task of navigating multiple apps, each 
                    with its own interface and functionalities, resulting in a 
                    fragmented and inefficient user experience.
                </p>
                <span class="b-left"></span>
            <span class="b-right"></span>
            <span class="b-b-left"></span>
            <span class="b-b-right"></span>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                <h2 class="sub-heading text-left">
                    business & goals
                </h2>
                <p class="paragraph" style="text-align: left;">
                    The primary business goal is to create a user-friendly 
                    smart home app that consolidates device monitoring and control, 
                    attracting a large user base, promoting user retention, 
                    and driving high customer satisfaction.
                </p>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3"></div>
            <div class="col-sm-12 col-md-3 col-lg-3">
                    <div class="main-img custom-setting">
                        <img src="/images/g-tensor/business-img.png" alt="">
                    </div>
            </div>
            
          
                <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="skeleton-grid">
                    <div class="data-flex"
                    v-for="(skeleton, index) in skeletons" :key="index">
                        <span>{{ skeleton.description }}</span>
                            <div class="main-img">
                                <img :src="skeleton.url" alt=""
                                style="height: 100% !important; margin-top: 1.5em;">
                            </div>
                    </div>
                </div>
            </div>
           
        </div>
        </section>
</template>

<script>
    export default {
        data() {
        return {
            mainHeading: [
                {
                    title: 'platform',
                    description: 'Mobile Application'
                },
                {
                    title: 'deliverables',
                    description: 'Research, UI/UX, Design System'
                },
            ],
            designs: [
                {
                    url: '/images/g-tensor/design-1.svg',
                    description: 'Understand'
                },
                {
                    url: '/images/g-tensor/design-2.svg',
                    description: 'Define'
                },
                {
                    url: '/images/g-tensor/design-3.svg',
                    description: 'Ideate'
                },
                {
                    url: '/images/g-tensor/design-4.svg',
                    description: 'Prototype'
                },
                {
                    url: '/images/g-tensor/design-5.svg',
                    description: 'Test'
                },
                
            ],
            skeletons: [
            {
                    url: '/images/g-tensor/skeleton-1.png',
                    description: 'Skeleton Structure'
                },
                {
                    url: '/images/g-tensor/skeleton-2.png',
                    description: 'Sketching'
                },
            ]
        }
    },
    computed: {
        isMobile() {
            return window.matchMedia("('max-width: 480px')").matches
        }
    }
        
    }
</script>

<style  scoped>
section {
    background: linear-gradient(180deg, #000000 -164.8%, rgba(9, 41, 76, 0.4) 34%, #020103 96.38%);

}

.logo {
    margin-top: 3em;
    padding-left: 1em;
    margin-bottom: 2em;
}
.center{
    display: flex;
    flex-direction: column;
    margin-top: 1em;
}

.feature-flex{
    display: flex;
    width: 85%;
    margin: 0 auto 2.5em;
    justify-content: space-between;
    align-items: center;
}
.feature-flex .data-flex img { width: 100%;}
ul  {
    padding: 0 .75em;
}
.box-text {
    position: absolute;
    top: 0;
    padding: 0 8em;
}
 .data-flex{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.data-flex img{
    margin-bottom: 2em;
}
.data-flex span {
    font-size: 1.5em;
    font-weight: 500;
    color: #fff;
}

.main-img {
    width: 100%;
    background-size: cover;
}

.main-img img {
    width: 100%;
    height: auto;
}

.ui-img {
    width: 60%;
    margin: 2em auto;
}

.main-img img {
    width: 100%;
    height: auto;
}

.main-heading {
    font-size: 3.5em;
    font-weight: 800;
    letter-spacing: 0.3px;
    text-transform: capitalize;
    margin-bottom: 1.5em;
}
.main-flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.main-flex h4 {
    font-size: 1.75em;
    font-weight: 600;
    text-transform: capitalize;
}

.main-flex span {
    color: #fff !important;
    font-size: 1em;
    font-weight: 400;

}

.sub-heading {
    font-size: 2.5em;
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
    margin: 1.5em 0 5px 0;
}

.paragraph {
    margin-bottom: 20px !important;
    font-size: 1.25em;
    font-weight: 400;
    letter-spacing: 0.3px;
    text-align: center;
    color: #fff;
}
.main-img.custom-setting{
    margin-top: 7em;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.skeleton-grid{
    display: grid;
    margin: 1.2em 0;
    grid-template-columns: 1.5fr 1fr;
     grid-auto-rows: 100% 100%;
    gap: 1em;
}
.custom-border{
    position: relative;
    margin: 2em auto;
    padding: 0 2em 2.5em;
    border: 2px solid #648B21;
}
.custom-border .b-left {
    position: absolute;
    width: 2em;
    height: 3em;
    background-color: #fff;
    top: -1.5em;
    left: -1em;
}
.custom-border .b-right {
    position: absolute;
    width: 2em;
    height: 3em;
    background-color: #fff;
    top: -1.5em;
    right: -1em;
}
.custom-border .b-b-right {
    position: absolute;
    width: 2em;
    height: 3em;
    background-color: #fff;
    bottom: -1.5em;
    right: -1em;
}
.custom-border .b-b-left {
    position: absolute;
    width: 2em;
    height: 3em;
    background-color: #fff;
    bottom: -1.5em;
    left: -1em;
}

 .sub-heading.text-left { text-align: left !important;}
 .row .custom-grid
 {
    width: 100%;
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    padding: 1em;
    gap: 5em;
 }
@media (min-width:1440px) {
    .paragraph {
        font-size: 1.5em;
    }
    .box-text {
    position: absolute;
    top: 0;
    padding: 0 21em;
}

    .sub-heading {
        font-size: 3em;
    }

    .main-heading {
        font-size: 4em;
        margin-bottom: 2em;
    }

    .main-flex h4 {
        font-size: 2em;
        font-weight: 600;
        text-transform: capitalize;
    }

    .main-flex span {
        color: #fff !important;
        font-size: 1.5em;
        font-weight: 400;

    }
}
@media (max-width:768px) {
    .data-flex img{
    margin-bottom: 1.5em;
}
.data-flex span {
    font-size: 1em;
    font-weight: 400;
    color: #fff;
}
.feature-flex { width: 92%;}
    .paragraph {
        font-size: 0.75em;
        line-height: 1.5em;
    }
    .ui-img {
    width: 80%;
    margin: 1em auto;
}

    .sub-heading {
        font-size: 2em;
        margin-bottom: .5em;
    }
    .box-text{
    padding: 0em 2em;
    
}

    .main-heading {
        font-size: 3.5em;
        margin-bottom: 1em;
    }
    .main-flex{
        margin-bottom: 5em;
    }

    .main-flex h4 {
        font-size: 1.5em;
        font-weight: 500;
        text-transform: capitalize;
    }
    .main-img.custom-setting {
        width: 60%;
    }

    .main-flex span {
        color: #fff !important;
        font-size: 1em;
        font-weight: 400;

    }
    .data-flex img{
        width: 4em !important;
        height: auto;
    }
    .skeleton-grid .skeleton{
        width: 100% !important;
        height: auto;
    }
.row .custom-grid {
        grid-template-columns: 1fr;
    }
    .row .custom-grid .order{
        order: 1;
    }
}
@media (max-width:480px) {
    .box-text{
    padding: 0em 2em;
}
.feature-flex { width: 100%;}
    .ui-img {
    width: 100%;
    margin: 1em auto;
}
.logo {
    margin-top: 4em;
}
.main-img.custom-setting{ display: none;}
.custom-border{
    margin: 5em 0 4em;
    padding:1em !important;
}
    .paragraph {
        font-size: 1em;
        line-height: 1.5em;
        text-align: justify;
    }

    .sub-heading {
        font-size: 1.25em;
        font-weight: 900;
    }

    .main-heading {
        font-size: 2.15em;
        line-height: 1.25em;
        letter-spacing: normal;
        font-weight: 900;
        margin-bottom: .5em;
    }

    .main-flex h4 {
        font-size: 1em;
        font-weight: 600;
        line-height: 0.5em;
        margin-bottom: 0;
        text-transform: capitalize;
    }
    .main-flex{
        margin-bottom: 2em;
    }
    .main-flex span {
        color: #fff !important;
        font-size: .65em;
        font-weight: 400;

    }
    .data-flex img{
        width: 45px !important;
        height: auto;
        margin-bottom: 1em;
    }
    .main-img .scale {
        height: 300px;
        
    }
    .data-flex span{
        font-size: 10px;
        text-align: center;
    }
    .skeleton-grid{
        display: none;
    }
    .custom-border .b-left {
    position: absolute;
    width: 10px;
    height: 12px;
    background-color: #fff;
    top: -6px;
    left: -5px;
}
.custom-border .b-right {
    position: absolute;
    width: 10px;
    height: 12px;
    background-color: #fff;
    top: -6px;
    right: -5px;
}
.custom-border .b-b-right {
    position: absolute;
    width: 10px;
    height: 12px;
    background-color: #fff;
    bottom: -6px;
    right: -5px;
}
.custom-border .b-b-left {
    position: absolute;
    width: 10px;
    height: 12px;
    background-color: #fff;
    bottom: -6px;
    left: -5px;
}
    .row .custom-grid {
        grid-template-columns: 1fr;
    }
    .row .custom-grid .order{
        order: 1;
    }
}
</style>