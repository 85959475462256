<template>
  <section>
    <!--page title start-->
    <CommonBanner
      styleHeading="Want"
      heading="to Join us ?"
      paragraph="Are you interested in advancing your career with possibilities to learn and lead? Join us at Septem Systems"
    />
    <!--page title end-->

    <!--body content start-->
    <section class="body-content">
      <!-- <div class="container-fluid background">
        <div class="row">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="paragrafh" style="text-align: center">OUR OFFICE</h3>
              </div>
              <div class="col-sm-12">
                <h2 style="text-align: center">
                  Step Inside Our<br />
                  Office
                </h2>
              </div>
            </div>
            <div class="our-office-icon" style="text-align: center">
              <img src="assets/img/services-icons/icon.png" />
              <p>Video Coming Soon...</p>
            </div>
          </div>
          <div class="col-sm-6" style="padding: 0">
            <img
              class="image"
              width="100%"
              src="assets/img/services-icons/office.jpg"
            />
          </div>

          <div class="col-sm-6" style="padding: 0">
            <img
              class="image-m"
              width="100%"
              src="assets/img/services-icons/office.jpg"
            />
          </div>
        </div>
      </div> -->
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-align" style="font: caption">
            <div
              class="heading-title-alt border-short-bottom text-center career-value-heading"
              style="margin-bottom: 30px"
            >
              <h3 class="text-uppercase">Our Values</h3>
            </div>
          </div>

          <div class="col-sm-2"></div>
          <div class="col-sm-8 text-align">
            <p style="text-align: justify">
              Our core values are not just words but a creed we live by every
              day. You can not expect your team to exceed customer expectations
              if you, yourself, do not exceed their expectations of management.
            </p>
          </div>
        </div>

        <div class="row career-position">
          <div class="col-sm-6 c-padding values-verticle-align">
            <img
              class="core-value-img"
              src="assets/img/services-icons/values-img.svg"
            />
          </div>
          <div
            class="col-sm-6 pt-5 value-font p c-padding"
            style="float: right"
          >
            <div class="pt-3">
              <h3 class="core-calue-h">Working to Create a Positive Impact</h3>
              <p class="core-calue-p">
                Impactful work is a success story. Only when we realize that our
                work is creating a positive impact on people’s lives, a sense of
                optimism and satisfaction takes over our entire morale.
                Gratifying impact helps us measure our heaping success.
              </p>
            </div>
          </div>
        </div>

        <div class="row values-second-row career-position-1">
          <div class="col-sm-6 pt-5 value-font p c-padding">
            <div class="pt-3">
              <h3 class="core-calue-h">Doing The Right Thing</h3>
              <p class="core-calue-p">
                No legacy is so rich as to do the right thing. We intend to
                always indulge in carefully acting in the right manner. We
                aspire to be simple, transparent, and empathetic.
              </p>
            </div>
          </div>
          <div class="col-sm-6 pt-5 c-padding values-verticle-align-1">
            <img
              class="core-value-img-1"
              src="assets/img/services-icons/values-img-1.svg"
            />
          </div>
        </div>

        <div class="row p-top-100 values-second-row-m">
          <div class="col-sm-6 pt-5 c-padding">
            <img
              class="core-value-img-1"
              src="assets/img/services-icons/values-img-1.svg"
            />
          </div>
          <div class="col-sm-6 pt-5 value-font p c-padding">
            <div class="pt-3">
              <h3 class="core-calue-h">Doing the right thing</h3>
              <p class="core-calue-p">
                No legacy is so rich as to do the right thing. We intend to
                always indulge in carefully acting in the right manner. We
                aspire to be simple, transparent, and empathetic.
              </p>
            </div>
          </div>
        </div>

        <div class="row career-position-1">
          <div class="col-sm-6 pt-5 c-padding values-verticle-align">
            <img
              class="core-value-img"
              src="assets/img/services-icons/values-img-2.svg"
            />
          </div>
          <div
            class="col-sm-6 pt-5 value-font p c-padding"
            style="float: right"
          >
            <div class="pt-3">
              <h3 class="core-calue-h">
                Moving Forward With A Courageous Mindset
              </h3>
              <p class="core-calue-p">
                We have got what it takes. Being courageous inspires our team to
                think big and champion innovation. It empowers them to step
                beyond the walls and give their best through a "Can Do"
                attitude.
              </p>
            </div>
          </div>
        </div>

        <div class="row values-second-row career-position-1">
          <div class="col-sm-6 pt-5 value-font p c-padding">
            <div class="pt-3">
              <h3 class="core-calue-h">Appreciating Differences</h3>
              <p class="core-calue-p">
                More variety, better society. Diversity expands beyond an
                employee's ethnic background. We believe that a diverse culture
                creates communities that celebrate differences.
              </p>
            </div>
          </div>
          <div class="col-sm-6 pt-5 c-padding values-verticle-align-1">
            <img
              class="core-value-img-1"
              src="assets/img/services-icons/values-img-3.svg"
            />
          </div>
        </div>
        <div class="row p-top-100 values-second-row-m">
          <div class="col-sm-6 pt-5 c-padding">
            <img
              class="core-value-img-1"
              src="assets/img/services-icons/values-img-3.svg"
            />
          </div>
          <div class="col-sm-6 pt-5 value-font p c-padding">
            <div class="pt-3">
              <h3 class="core-calue-h">Appreciating Differences</h3>
              <p class="core-calue-p">
                More variety, better society. Diversity expands beyond an
                employee's ethnic background. We believe that a diverse culture
                creates communities that celebrate differences.
              </p>
            </div>
          </div>
        </div>

        <div class="row career-position-1">
          <div class="col-sm-6 pt-5 c-padding values-verticle-align">
            <img
              class="core-value-img"
              src="assets/img/services-icons/values-img-4.svg"
            />
          </div>
          <div
            class="col-sm-6 pt-5 value-font p c-padding"
            style="float: right"
          >
            <div class="pt-3">
              <h3 class="core-calue-h">You Can Trust Us</h3>
              <p class="core-calue-p">
                We work on our thoughts, to create a better tomorrow. One of the
                focal points that our organization believes in is dynamic
                discoveries. We regularly think about new resources and welfare
                and correlate with our special research analysts to discover and
                bring about a positive change.
              </p>
            </div>
          </div>
        </div>

        <div class="row values-second-row career-position-1">
          <div class="col-sm-6 pt-5 value-font p c-padding">
            <div class="pt-3">
              <h3 class="core-calue-h">
                Look At The World From A Different Perspective
              </h3>
              <p class="core-calue-p">
                It’s our ideas, we let them grow. As a software development
                company, we strive to improve on a routine basis. We endeavour
                to continue providing better solutions for our customers
                worldwide.
              </p>
            </div>
          </div>
          <div class="col-sm-6 pt-5 c-padding values-verticle-align-1">
            <img
              class="core-value-img-1"
              src="assets/img/services-icons/values-img-5.svg"
            />
          </div>
        </div>
        <div class="row p-top-100 values-second-row-m">
          <div class="col-sm-6 pt-5 c-padding">
            <img
              class="core-value-img-1"
              src="assets/img/services-icons/values-img-5.svg"
            />
          </div>
          <div class="col-sm-6 pt-5 value-font p c-padding">
            <div class="pt-3">
              <h3 class="core-calue-h">
                Look At The World From A Different Perspective
              </h3>
              <p class="core-calue-p">
                It’s our ideas, we let them grow. As a software development
                company, we strive to improve on a routine basis. We endeavour
                to continue providing better solutions for our customers
                worldwide.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <CompanyStats />
  </section>
</template>

<script>
import firebase from "./../firebaseConfig";
import CompanyStats from "./common/CompanyStats.vue";
import CommonBanner from "./common/CommonBanner.vue";
const db = firebase.firestore();
export default {
  name: "Careers",
  components: {
    CompanyStats,
    CommonBanner,
  },
  data() {
    return {
      jobs: [],
      file: "",
      title: "",
      isLoading: false,
      snapshot: null,
    };
  },
  computed: {
    change() {
      let arr = this.jobs;
      let secondarr = [];

      for (let i = 0; i < arr.length; i += 2)
        secondarr.push(arr.slice(i, i + 2));
      return secondarr;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getJobs();
  },
  methods: {
    async getJobs() {
      this.snapshot = await db.collection("jobs").onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            if (!JSON.parse(change.doc.data().is_disabled)) {
              this.jobs.push({ id: change.doc.id, ...change.doc.data() });
            }
          }
          if (change.type === "modified") {
            console.log(
              "Modified job in careers: ",
              change.doc.data().is_disabled
            );
            if (change.doc.data().is_disabled) {
              this.jobs.map((el, index) => {
                if (el.id === change.doc.id) {
                  this.jobs.splice(index, 1);
                }
              });
            } else {
              let found = false;
              this.jobs.map((el) => {
                if (el.id === change.doc.id) {
                  found = true;
                  el = change.doc.data();
                }
              });
              if (!found) {
                this.jobs.push({ id: change.doc.id, ...change.doc.data() });
              }
            }
          }
          if (change.type === "removed") {
            console.log("Removed job: ", change.doc.data());
            this.jobs.map((el, index) => {
              if (el.id === change.doc.id) {
                this.jobs.splice(index, 1);
              }
            });
          }
        });
      });
    },
    //  getJobs() {
    //   db.collection("jobs")
    //     .where('is_disabled', '!=', true)
    //     .get().then(res =>
    //     res.docs.forEach(doc => {
    //       this.jobs.push({id: doc.id, ...doc.data()})
    //     })
    //   )
    //  },
    fileChangeHandler(event, jobtitle) {
      this.file = event.target.files[0];
      this.title = jobtitle;
    },
    async submit() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("title", this.title);
      try {
        await this.$axios.post(`upload-resume`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.$toast.success("Resume is sent successfully.");
      } catch (e) {
        this.$toast.error("Something went wrong.");
      }
      this.isLoading = false;
      this.$router.go();
    },
  },
  beforeDestroy() {
    if (this.snapshot) {
      this.snapshot();
    }
  },
};
</script>

<style scoped>
.career-box {
  display: inline-block;
  margin-bottom: 20px;
  border: 1px solid #00b398;
  background: #000;
  border-radius: 7px;
  box-shadow: #00b398 0px 0px 0px;
}

.career-box:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 2px solid #00b398;
  box-shadow: #00b398 0px 3px 10px;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.overlap-text {
  word-break: break-all;
}
/*h3::after{*/
/*  position: absolute;*/
/*  bottom: -2px;*/
/*  left: 50%;*/
/*  width: 60px;*/
/*  height: 4px;*/
/*  margin-left: -31px;*/
/*  content: "";*/
/*  background: #00b398;*/
/*}*/
/*.promo-btn{*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  right: 40px;*/
/*  margin-top:20px;*/
/*}*/
.career-position {
  position: relative;
  margin: 200px 0px !important;
}
.career-position-1 {
  position: relative;
  margin: 200px 0px 216px 0 !important;
}
.values-verticle-align {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.values-verticle-align-1 {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
@media only screen and (max-width: 767px) {
  .values-verticle-align {
    position: unset !important;
    top: unset !important;
    left: unset !important;
    transform: unset !important;
  }
  .career-position {
    margin: 100px 0 0 0 !important;
  }
  .career-position-1 {
    margin: 100px 0 0 0 !important;
  }
}
</style>
