<template>
  <section>
    <CommonBanner
      styleHeading="Digital"
      heading="Marketing"
      paragraph="Content is fire. Social media is gasoline. - Jay Baer"
    />

    <!--        <div class="row">-->
    <!--            <div class="heading-title text-center">-->
    <!--                 <h3 class="text-uppercase">A brand is a voice </h3>-->
    <!--            </div>-->
    <!--        </div>-->

    <div class="col-container">
      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-lightbulb icon-color"></i>
        </div>
        <h2 class="align-item">CREATIVE</h2>
        <p class="p-desc">
          By combining copywriting, design and artwork specialisms, we deliver
          projects and individual campaigns.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-browser2 icon-color"></i>
        </div>
        <h2 class="align-item">DIGITAL</h2>
        <p class="p-desc">
          Our team functions as a multi-functional agency that works on web
          designs, e-commerce, mobile and game apps.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-laptop2 icon-color"></i>
        </div>
        <h2 class="align-item">BRANDING</h2>
        <p class="p-desc">
          We can take a brand identity from initial conception to full creative
          executions and communications.
        </p>
      </div>
    </div>

    <div class="col-container">
      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-basic_magnifier_plus icon-color"></i>
        </div>
        <h2 class="align-item">SEO</h2>
        <p class="p-desc">
          Providing proven SEO solutions that help businesses 
          expand their online presence, increase leads, and grow revenue.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-basic_pencil_ruler icon-color"></i>
        </div>
        <h2 class="align-item">PR & SOCIAL</h2>
        <p class="p-desc">
          Our PR & Social approach is a mix of bold ideas, strategic planning,
          precise execution and tangible results.
        </p>
      </div>

      <div class="col" style="background: black">
        <div class="icon">
          <i class="icon-basic_todolist_pencil icon-color"></i>
        </div>
        <h2 class="align-item">VIDEO & MOTION GRAPHICS</h2>
        <p class="p-desc">
          Expert animator and editors that modify and alter any content for
          maximum attention-grabbing.
        </p>
      </div>
    </div>
    <!--page title end-->

    <!--body content start-->
    <!-- <section class="body-content"> -->
    <!-- <div class="page-content"> -->
    <!-- <div class="container">
                    <div class="row">
                     <div class="heading-title text-center">
                            <h3 class="text-uppercase">A brand is a voice </h3>
                            <!-<span class="text-uppercase">pulvinar vitae neque et porttitor. Praesent sed nisi eleifend.</span>-->
    <!-- </div> -->
    <!-- <div class="feature-box-grid">
                            <div class="col-md-4 c-padding">
                                <div class="featured-item border-box text-center">
                                    <div class="icon">
                                        <i class="icon-lightbulb"></i>
                                    </div>
                                    <div class="title text-uppercase">
                                    <h4>Creative</h4>
                                </div>
                                <div class="desc">
                                    Combining copywriting, design and artworking specialisms, we deliver projects and individual campaigns.
                                </div>
                                </div>
                            </div> -->
    <!-- <div class="col-md-4 c-padding">
                                <div class="featured-item border-box text-center">
                                    <div class="icon">
                                        <i class="icon-browser2"></i>
                                    </div>
                                    <div class="title text-uppercase">
                                    <h4>Digital</h4>
                                </div>
                                <div class="desc">
                                    Our team functions as multi-functional agency that works on web design, e-commerce, mobile and game apps.
                                </div>
                                </div>
                            </div> -->
    <!-- <div class="col-md-4 c-padding">
                                <div class="featured-item border-box text-center">
                                    <div class="icon">
                                        <i class="icon-laptop2"></i>
                                    </div>
                                    <div class="title text-uppercase">
                                    <h4>Branding</h4>
                                </div>
                                <div class="desc">
                                    We can take a brand identity from initial conception right through to full creative executions and communications.
                                </div>
                                </div>
                            </div>
                            <div class="col-md-4 c-padding">
                                <div class="featured-item border-box text-center">
                                    <div class="icon">
                                        <i class="icon-basic_gear"></i>
                                    </div>
                                  <div class="title text-uppercase">
                                    <h4>Strategy</h4>
                                </div>
                                <div class="desc">
                                    Great brand communications build on the foundation of a great brand strategy and we create that foundation.
                                </div>
                                </div>
                            </div>
                            <div class="col-md-4 c-padding">
                                <div class="featured-item border-box text-center">
                                    <div class="icon">
                                        <i class="icon-basic_pencil_ruler"></i>
                                    </div>
                                    <div class="title text-uppercase">
                                    <h4>PR &amp; Social</h4>
                                </div>
                                <div class="desc">
                                    Our PR &amp; Social approach is a mix of bold ideas, strategic planning, precise execution and tangible results.
                                </div>
                                </div>
                            </div>
                           <div class="col-md-4 c-padding">
                                <div class="featured-item border-box text-center">
                                    <div class="icon">
                                        <i class="icon-basic_todolist_pencil"></i>
                                    </div>
                                    <div class="title text-uppercase">
                                    <h4>Video &amp; Motion Graphics</h4>
                                </div>
                                <div class="desc">
                                    Ringilla augue at maximus vestibulum. Nam pulvinar vitae neque et porttitor. Praesent sed nisi eleifend.
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
    <!-- </div>              -->
    <div class="page-content">
      <!--post style 5 start-->
      <div class="post-list-aside">
        <div class="container">
          <div class="row post-single e-position">
            <div class="col-md-6 ss-vertical-align">
              <div class="post-img">
                <img src="assets/img/post/XX.png" alt="" />
              </div>
            </div>
            <div class="col-md-6 pull-right">
              <div class="custom-desc">
                <h1>
                  Septem Systems is a full <br />fire service digital<br />
                  agency!
                </h1>

                <p>
                  We’re a mix of highly creative communications experts who
                  think and work differently in the same way. And that’s how we
                  bring you diverse, high-impact, and tailored ideas that win
                  the attention economy.
                </p>

                <p>
                  Whether it’s strategy, creative, PR & social, digital, video
                  or photography, we share a love of putting on a great show for
                  our clients. It gets us out of bed in the morning and it gets
                  our clients brilliant results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--post style 5 end-->
    </div>
    <!-- </div> -->
  </section>

  <!-- </section> -->
</template>

<script>
import CommonBanner from "./common/CommonBanner.vue";

export default {
  name: "Digital",
  mounted() {
    window.scrollTo(0, 0);
  },
  components: { CommonBanner },
};
</script>

<style scoped>
.services-h span {
  font-family: "rubiklight" !important;
}
</style>
