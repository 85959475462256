<template>
  <section>
    <BannerSection />
    <Services />
    <WhoWeAre />
    <Portfolio title="Featured Projects" :path="path" />
    <CompanyStats />
    <Testimonials />
  </section>
</template>

<script>
import CompanyStats from "./common/CompanyStats.vue";
import Testimonials from "./Testimonials.vue";
import BannerSection from "./BannerSection.vue";
import Services from "./Services.vue";
import WhoWeAre from "./WhoWeAre.vue";
import Portfolio from "./Portfolio.vue";
import { watch } from "vue";
export default {
  name: "home",
  computed: {
    path() {
      return this.$route.name;
    },
    queryId() {
      return this.$route.query.id;
    },
  },
  watch: {
    queryId() {
      this.setMetaTags();
    },
  },
  components: {
    CompanyStats,
    Testimonials,
    BannerSection,
    Services,
    WhoWeAre,
    Portfolio,
  },
  mounted() {
    console.log(this.queryId);
    this.setMetaTags();
  },
  beforeRouteUpdate(to, from, next) {
    this.setMetaTags();
    next();
  },
  methods: {
    setMetaTags() {
      let title = "Septem Systems – Leading Information Technology Company";
      let description =
        "Septem Systems is a Leading Information Technology Company specializing in custom AI-based software and mobile app development to automate workflows and drive innovation.";

      if (this.queryId === "services") {
        title = "Services – Septem Systems";
        description =
          "At Septem we offer top digital solutions: web, apps, cloud, and product development. Boost your online presence with advanced digital marketing strategies.";
      }

      document.title = title;

      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute("content", description);
      } else {
        const metaTag = document.createElement("meta");
        metaTag.setAttribute("name", "description");
        metaTag.setAttribute("content", description);
        document.head.appendChild(metaTag);
      }
    },
  },
};
</script>
<style scoped>
.fontt p {
  margin: 0px;
}
</style>
