<template>
  <section>
    <div class="container padding ">
      <div v-if="isLoading" class="loading">Loading&#8230;</div>
      <h1 class="text-center " style="padding-bottom: 20px;">
        Job Applicataion Form
      </h1>
      <form @submit.prevent="updateJobData()">
        <div class="row">
          <div class="col-sm-12">
            <div class="border">
              <div class="row">
                <div class="col-sm-11">
                  <div class="col-sm-12">
                    <input
                      class="border input"
                      type="text"
                      v-model="singleJob.title"
                      placeholder="Title"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- <p style="color: red;" v-if="singleJob.title">
              Title is required.
            </p> -->
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="border">
              <div class="row">
                <div class="col-sm-11">
                  <div class="col-sm-12">
                    <input
                      class="border input"
                      type="number"
                      v-model="singleJob.position"
                      placeholder="Positions"
                    />
                    <!-- <input  class="border" type="text"  name="fname" value="John"> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- <p style="color: red;" v-if="singleJob.position">
              Position is required.
            </p> -->
          </div>

          <div class="col-sm-6">
            <div class="border">
              <div class="row">
                <div class="col-sm-11">
                  <div class="col-sm-12">
                    <input
                      class="border input"
                      type="text"
                      v-model="singleJob.commitment"
                      placeholder="Commitment"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- <p style="color: red;" v-if="singleJob.commitment">
              Commitment is required.
            </p> -->
          </div>

          <div class="col-sm-6">
            <textarea
              class="border input border-padding"
              type="text"
              v-model="singleJob.location"
              placeholder="Location"
              rows="3"
            >
            </textarea>
            <!-- <p style="color: red;" v-if="singleJob.location">
              Location is required.
            </p> -->
          </div>

          <div class="col-sm-6">
            <textarea
              class="border input border-padding"
              type="text"
              v-model="singleJob.description"
              placeholder="Enter description"
              rows="3"
            >
            </textarea>
            <!-- <p style="color: red;" v-if="singleJob.description ">
              Description must have at least {{singleJob.description}} letters.
            </p>
            <p style="color: red;" v-else-if="singleJob.description.$error && !$v.singleJob.description.required" >
              Description is required.
            </p> -->
          </div>

          <div class="col-sm-6">
            <textarea
              class="border input border-padding"
              type="text"
              v-model="singleJob.requirement"
              placeholder="Enter requirements"
              rows="10"
            >
            </textarea>
            <!-- <p style="color: red;" v-if="singleJob.requirement.$error">
              Requirement is required.
            </p> -->
          </div>
            <div class="col-sm-6">
            <textarea
              class="border input border-padding"
              type="text"
              v-model="singleJob.responsibilities"
              placeholder="Enter responsibilities"
              rows="10"
            >
            </textarea>
            <!-- <p style="color: red;" v-if="singleJob.responsibilities.$error">
              Responsibilities is required.
            </p> -->
          </div>
           <div class="col-sm-6  border-padding">
             <label class="radio-inline">
             
               <h4> <input type="checkbox"  v-model="singleJob.is_disabled" name="optradio" :checked="singleJob.is_disabled"  true-value="true"
                  false-value="false"> Disabled</h4>
              </label>
            
             
          </div>
 
      
          <div class="col-sm-12">
            <button
            type="button"
              @click="$router.go(-1)"
              class="btn btn-medium btn-rounded btn-custom-solid mr-2"
              style="float:left"
            >
              Back
            </button>
            <div class="contact-btn">
              <button class="btn btn-medium btn-rounded btn-custom-solid ">
                Update
              </button>
            </div>
            <!--<button class="send-button" type="button" onclick="alert('Hello world!')">SEND MESSAGE</button>-->
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import firebase from "./../../firebaseConfig";
import { required, minLength } from "vuelidate/lib/validators";
const db = firebase.firestore();
export default {
  name: "CreateJob",
  created() {
    if (!this.$cookies.get("user_uid")) {
      this.$toast.error("You need to login first");
      this.$router.push("/login");
    }
    console.log(
      "%cEditJob.vue line:160 object",
      "color: #007acc;",
      this.$route.params.id
    );
    this.getSingleJob(this.$route.params.id);
  },
  mounted() {},
  data() {
    return {
      singleJob: {},
      id: null,
      isLoading: false,
    };
  },
  validations: {
    title: { required },
    position: { required },
    commitment: { required },
    location: { required },
    description: { required, minLength: minLength(100) },
    requirement: { required },
    responsibilities: { required },
  },
  methods: {
    async getSingleJob(id) {
      try {
        let doc = await db
          .collection("jobs")
          .doc(id)
          .get();
        this.id = doc.id;
        this.singleJob = doc.data();
      } catch (e) {
        console.log("error", e);
        this.$toast.console.error(e);
      }

      //   db.collection('jobs').doc(id).delete()
      //   .then(()=> this.$toast.success("Job is deleted successfully"))
      //   .catch(err =>this.$toast.console.error(err) );
      // }
    },
    async updateJobData() {
      try {
        await db
          .collection("jobs")
          .doc(this.id)
          .update(this.singleJob);
        this.$toast.success("Job is updated successfully");
        this.$router.go("-1");
      } catch (e) {
        console.log("error", e);
        this.$toast.console.error(e);
      }
    },
  },
};
</script>

<style scoped>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
