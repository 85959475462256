import { render, staticRenderFns } from "./SerenibyCaseStudy.vue?vue&type=template&id=2a0f2be0&scoped=true"
import script from "./SerenibyCaseStudy.vue?vue&type=script&lang=js"
export * from "./SerenibyCaseStudy.vue?vue&type=script&lang=js"
import style0 from "./SerenibyCaseStudy.vue?vue&type=style&index=0&id=2a0f2be0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a0f2be0",
  null
  
)

export default component.exports