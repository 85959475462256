<template>
  <div class="aboutUs-container">
    <div class="aboutUS">
      <div class="heading-title-alt border-short-bottom text-center">
        <h3 class="text-uppercase">Who we are?</h3>
      </div>
      <div class="row aboutUS-body">
        <div
          class="col-md-12 col-sm-12 col-lg-6 col-xs-12 aboutUS-left-column-container"
        >
          <div class="aboutUS-left-column">
            <img src="../../public/assets/img/about/support.svg" />
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-6 col-xs-12">
          <div class="aboutUS-right-column">
            <h1>Trusted & Supported By</h1>
            <p>
              We have worked with organizations such as UNICEF(United Nations
              International Children’s Emergency FUND), UNODC(United Nations
              Office on Drugs and Crime), SNG(Sub National Governance), TPI
              LUMS(Technology for People Initiative) and OPM(Oxford Policy
              Management) co-extensively creating an affirmative effect through
              both Business and Social well being optique.
            </p>
            <router-link to="about">
              <button>More About Us</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Clients />
  </div>
</template>
<script>
import Clients from "./Clients.vue";

export default {
  name: "WhoWeAre",
  components: { Clients },
};
</script>
<style scoped>
.marquee-container {
  display: flex;
  align-items: center;
  height: 100px;
  overflow: hidden;
}
.marquee {
  display: flex;
  align-items: center;
  justify-content: center;
  left: 100%;
  /* width: 100%; */
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
}

.marquee2 {
  animation-delay: 15s;
}

@keyframes marquee {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}
</style>
