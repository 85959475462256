<template>
  <div class="getStarted-container" v-if="path !== 'Contact'">
    <div class="row getStarted-body">
      <div class="col-xs-12 col-md-6 getStarted-body-container">
        <h1>Ready to get started?</h1>
        <p>We help global brands design and build superior digital products</p>
      </div>
      <div class="col-xs-12 col-md-6 getStarted-input-container">
        <router-link to="contact" class="col-xs-12 col-md-6 getStarted-btn"
          ><span @click="setQuoteVal">Let's Talk</span></router-link
        >
      </div>
    </div>
    <!-- <div class="below-image">
      <svg
        width="93"
        height="95"
        viewBox="0 0 93 95"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.53">
          <path
            d="M28.6762 60.7865L29.8571 16.1466L72.951 39.3716L28.6762 60.7865Z"
            stroke="#E7E7E7"
            stroke-width="3"
            stroke-linejoin="round"
          />
          <path
            d="M32.5429 78.8705L33.8098 30.9787L80.043 55.8956L32.5429 78.8705Z"
            stroke="#E7E7E7"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </div>
    <div class="below-image-mobile">
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.53">
          <path
            d="M11.9117 19.8025L12.2412 7.34514L24.2672 13.8264L11.9117 19.8025Z"
            stroke="#E7E7E7"
            stroke-width="3"
            stroke-linejoin="round"
          />
          <path
            d="M12.5587 27.1931L12.9743 11.4838L28.1395 19.657L12.5587 27.1931Z"
            stroke="#E7E7E7"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "GetStarted",
  computed: {
    path() {
      return this.$route.name;
    },
  },
  methods: {
    setQuoteVal() {
      this.$store.dispatch("setQuote", true);
    },
  },
};
</script>
<style scoped>
@media (max-width: 480px) {
  .getStarted-body .getStarted-btn span { font-size: 14px !important;}
  p{
    font-weight: 400;
    font-size: 14px !important;
    line-height: 1.5 !important;
  }
  h1{
    font-size: 20px !important;
    padding: 0 0 10px 0;

  }
  
}
</style>
