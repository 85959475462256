<template>
  <section>
    <section class="page-title background-title dark banner-contact">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3 class="text-uppercase">Back-end Engineering</h3>
            <span
              >Styles come and go.<br />Good Architecture is a language, not a
              style. - Massimo Vignelli</span
            >
          </div>
        </div>
      </div>
    </section>
    <!--page title end-->
    <!--body content start-->
    <div class="col-container">
      <div class="col">
        <div class="icon">
          <i class="icon-lightbulb icon-color"></i>
        </div>
        <h2 class="align-item">PHP LARAVEL</h2>
        <p class="p-desc">
          A technology that is affordable and comes with database flexibility,
          and cross-platform compatibility.
        </p>
      </div>
      <div class="col">
        <div class="icon">
          <i class="icon-browser2 icon-color"></i>
        </div>
        <h2 class="align-item">JAVA SPRING</h2>
        <p class="p-desc">
          Comes with secure, scalable & very powerful development tools for
          Enterprise Applications.
        </p>
      </div>

      <div class="col">
        <div class="icon">
          <i class="icon-laptop2 icon-color"></i>
        </div>
        <h2 class="align-item">RUBY ON RAILS</h2>
        <p class="p-desc">
          This framework comes with ready-made plug-ins and modules, thus
          reducing development time by 20-30%
        </p>
      </div>
    </div>
    <div class="col-container">
      <div class="col">
        <div class="icon">
          <i class="icon-basic_gear icon-color"></i>
        </div>
        <h2 class="align-item">NODE.JS</h2>
        <p class="p-desc">
          Efficiency, better synchronization, easy microservice additions, and
          scalability in one package.
        </p>
      </div>

      <div class="col">
        <div class="icon">
          <i class="icon-basic_pencil_ruler icon-color"></i>
        </div>
        <h2 class="align-item">EXPRESS.JS</h2>
        <p class="p-desc">
          Offers quick and simple development and configuration, along with easy
          integration and database connection.
        </p>
      </div>

      <div class="col">
        <div class="icon">
          <i class="icon-basic_todolist_pencil icon-color"></i>
        </div>
        <h2 class="align-item">PHP LUMEN</h2>
        <p class="p-desc">
          A fast micro-framework, leading in performance and speed. It works
          great for microservices and APIs.
        </p>
      </div>
    </div>
    <section class="body-content">
      <div class="page-content">
        <!--post style 5 start-->
        <div class="post-list-aside">
          <div class="container">
            <div class="row post-single e-position">
              <div class="col-md-6 ss-vertical-align">
                <div class="post-img">
                  <img src="assets/img/post/backend-3.png" alt="" />
                </div>
              </div>
              <div class="col-md-6 pull-right">
                <div class="custom-desc">
                  <h1>We stuff your app with the best brains.</h1>

                  <p>
                    Just as you shouldn’t judge a book by its cover, you
                    shouldn’t judge an app by its design and front store only!
                    Looks are important, but it’s the brain that makes your
                    product irresistibly attractive. Do you want an intelligent
                    kid who also wins all the beauty pageants? That’s the type
                    of product we code at Septem Systems.
                  </p>

                  <p>
                    We offer a set of services that span the development of
                    custom back-end layers, optimization of existing backend
                    systems, and migration to the cloud, empowering their
                    extensibility and performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--post style 5 end-->
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: "Backend",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped></style>
