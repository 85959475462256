<template>
  <footer id="footer" class="dark">
    <GetStarted />
    <div class="primary-footer">
      <div class="row footer-body">
        <div
          class="col-xs-12 col-md-3 col-lg-2 footer_padding_logo text-center"
        >
          <router-link to="/" class="m-bot-20 footer-logo">
            <img class="retina" src="assets/img/septemlogo.svg" alt="" />
          </router-link>
          <div class="social-link-row">
            <div v-for="icon in socialIcons">
              <a :href="icon.path" target="_blank">
                <img
                  :src="
                    require(`../../../public/assets/img/footer/${icon.iconPath}`)
                  "
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-3 col-lg-3 search-container">
          <span class="career-text">Careers</span>
          <a
            href="https://careers.smartrecruiters.com/SeptemSystems"
            target="_blank"
            class="career-link"
          >
            Go to our Job Announcements</a
          >
        </div>
        <div class="col-md-6 col-xs-12 col-lg-7 services-info">
          <div class="row">
            <div class="col-xs-6 col-md-4 footer_padding">
              <h5 class="service-headings">Quick Links</h5>
              <ul class="f-list">
                <li v-for="link in quickLinks">
                  <router-link :to="link.route">{{
                    link.routeName
                  }}</router-link>
                </li>
              </ul>
            </div>
            <div class="col-xs-6 col-md-4 footer_padding">
              <h5 class="service-headings">Services</h5>
              <ul class="f-list">
                <li v-for="service in mainServices">
                  <router-link :to="service.route">{{
                    service.routeName
                  }}</router-link>
                </li>
              </ul>
            </div>

            <div class="col-xs-6 col-md-4 footer_padding">
              <h5 class="service-headings">Services</h5>
              <ul class="f-list">
                <li v-for="link in extraServices">
                  <router-link :to="link.route">{{
                    link.routeName
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MobileFooter />
    <div class="secondary-footer">
      <div class="container">
        <div class="row">
          <div class="col-md-12 footer_padding-down">
            <div class="social-link circle text-center">
              <p class="copy-right-text">
                © 2023 All Rights Reserved By Septem Systems
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import GetStarted from "../../views/common/GetStarted.vue";
import MobileFooter from "../../views/common/MobileFooter.vue";
export default {
  name: "Footer",
  data() {
    return {
      socialIcons: [
        {
          path: "https://www.linkedin.com/company/septemsystems/",
          iconPath: `linkedin.svg`,
        },
        {
          path: "https://www.instagram.com/septemsystems/",
          iconPath: `instagram.svg`,
        },
        {
          path: "https://www.facebook.com/septemsystems/",
          iconPath: `facebook.svg`,
        },
        {
          path: "https://www.tiktok.com/@septemsystems?_t=8WC16rTgR73&_r=1",
          iconPath: `tiktok.svg`,
        },
      ],
      quickLinks: [
        {
          route: "/",
          routeName: "Home",
        },
        {
          route: "/about",
          routeName: "About Us",
        },
        {
          route: "/portfolio",
          routeName: "Portfolio",
        },
        {
          route: "/contact",
          routeName: "Contact",
        },
      ],
      mainServices: [
        {
          route: "/website",
          routeName: "Web Development",
        },
        {
          route: "/mobile",
          routeName: "App Development",
        },
        {
          route: "/cloud",
          routeName: "Cloud Development",
        },
        {
          route: "/product",
          routeName: "Product Development",
        },
      ],
      extraServices: [
        {
          route: "/digital",
          routeName: "Digital Marketing",
        },
        {
          route: "/business-intelligence",
          routeName: "Business Intelligence",
        },
        {
          route: "/uiux",
          routeName: "UI UX Design",
        },
        {
          route: "/contact",
          routeName: "Customer Support",
        },
      ],
    };
  },
  components: {
    GetStarted,
    MobileFooter,
  },
};
</script>

<style lang="css" scoped>
#footer.dark .company-name {
  font-size: 15px;
}
</style>
